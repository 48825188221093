import { IThemeInterface } from '../theme';
import { rem } from 'polished';
import { RecursivePartial } from '../../interfaces/RecursivePartial';

export const breakpoints = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
};

const themeDS: RecursivePartial<IThemeInterface> = {
    fonts: {
        fontBase: 'DSAutomobiles',
        fontEmphasized: 'DSTitleMedium',
        fontTertiary: 'Ubuntu',
    },
    fontSizes: {
        textSmall: rem('13px'),
        textMedium: rem('16px'),
        h1: rem('36px'),
        h2: rem('28px'),
        h3: rem('22px'),
        h4: rem('20px'),
        h5: rem('16px'),
        h6: rem('14px'),
    },
    lineHeights: {
        h1: rem('39px'),
        h2: rem('34px'),
        h3: rem('28px'),
        h4: rem('32px'),
        h5: rem('19px'),
    },
    colors: {
        primary: '#9D8C83',
        primaryHover: '#870031',
        primaryDisabled: '#E7B2C6',
        secondary: '#846F66',
        secondaryHover: '#655A54',
        secondaryDisabled: '#FFF',
        lozenge: '#B51E41',
        lozengeArrow: '#651024',

        red1: '#B51E41',
        red2: '#AD0041',
        grey1: '#8E8B8B',
        grey2: '#E6E0DB',
        grey3: '#cdcdcd',
        grey4: '#E9E7E1',
        grey5: '#a3a4a4',
        grey6: '#303131',
        grey7: '#ececee',
        grey8: '#6e6e6e',
        grey9: '#7F7F7F',
        grey10: '#D9D9D9',
        grey11: '#818181',
        grey12: '#3C3947',
        grey13: '#d1d1d1',
        grey14: '#f1f0f0',
        grey16: '#e4e4e4',
        grey19: '#f5f5f5',
        success: '#4CAF50',
        warning: '#F8A825',
        danger: '#D0021B',
        info: '#5AAAFA',
        accordionSecondaryBg: '#f1efed',
        trimPageModelNameBackground: '#f1efed',
        accordionAnswer: '#1d1717',
        black1: '#1A1B1B',
        myAccountImageButtonBackground: '#fcfcfc',
        myAccountImageButtonDescriptionBackground: '#1D1D1B',

        pxCalculatorBlue: '#152A46',
        accessibilityOutlinecolor: '#9D8C83',
    },
    fontWeights: {
        textLight: 300,
        textRegular: 400,
        textMedium: 500,
        textBold: 600,
        textExtraBold: 700,
    },
};
export default themeDS;
