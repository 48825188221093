const routes = {
    ROOT: '/',
    BASKET: '/basket',
    SUMMARY: '/summary',
    DEALER: '/dealers',
    CAR: '/selector',
    SELECTOR: '/trim',
    LOGIN: '/login',
    CHECKOUT_DELIVERY: '/checkout',
    CHECKOUT_MY_DETAILS: '/checkout/my-details',
    CHECKOUT_ORDER_REVIEW: '/checkout/order-review',
    CHECKOUT_PREORDER: '/checkout/pre-order',
    CHECKOUT_PRE_ORDER: '/checkout/pre-order',
    CHECKOUT_CONFIRMATION: '/checkout/confirmation',
    OFFERS_DELIVERY: '/offers/dealers',
    OFFERS_LOGIN: '/offers/login',
    MY_ACCOUNT: '/my-account',
    MY_SAVES: '/my-account/my-saves',
    MY_ORDERS: '/my-account/my-orders',
};

export default routes;
