import { useEffect, useState } from 'react';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { useDispatch, useSelector } from 'react-redux';
import useTranslations from '@hooks/useTranslations';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import { useRoutingInProgress } from '@hooks/useRoutingInProgress';
import { isHomeUrl } from '@utils/url.utils';
import { useRouter } from 'next/router';
import OfferListDuck from 'src/redux/carList/offerlist.duck';
import { IOfferItem } from 'src/interfaces/IOfferItem';
import routes from 'src/constants/routes';
import { ITrimItem } from 'src/redux/trimSelector/trimSelector.duck.interface';
import parser from 'bbcode-to-react';
import { renderToString } from 'react-dom/server';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';
import { useCarStockJourneyCheck } from '@hooks/useCarStockJourneyCheck';
import DisclaimerService from '../../services/disclaimer/disclaimer.service';
import { IS_DEV } from 'src/constants/main';
import { getMinutesBetweenTwoDates } from '@utils/Date.utils';
import { Redux } from 'src/redux/redux.interface';
import TrimSelectorDuck from 'src/redux/trimSelector/trimSelector.duck';

const Cookie = require('js-cookie');

const FinanceLegal = (): null => {
    const dispatch = useDispatch();
    const { t } = useTranslations();
    const isRoutingInProgress = useRoutingInProgress();
    const FINANCE_LEGAL_COOKIE_NAME = 'finance_legal_closed';
    const FINANCE_LEGAL_DATA_COOKIE_NAME = 'finance_legal_data';
    const FINANCE_LEGAL_SHOW_AGAIN_AFTER_MINUTES = 120;

    const [showFinanceLegal, setShowFinanceLegal] = useState(false);

    const router = useRouter();
    const filteredOffers = useSelector((state: Redux) => OfferListDuck.getFilteredOffers(state));
    const filteredTrims = useSelector((state: Redux) => TrimSelectorDuck.getFilteredTrims(state));
    const { carDetails } = useCarDetailsDuck();

    const currentPath = router.asPath;
    const isTrimPage = currentPath.includes(routes.SELECTOR);

    const showFinanceLegalFs = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_SHOW_FINANCE_LEGAL);
    const { isStockJourney } = useCarStockJourneyCheck();

    useEffect(() => {
        if (typeof window != 'undefined') {
            window.addEventListener(
                'beforeunload',
                () => (window.sessionStorage.financeLegalClosedAt = (window as any).financeLegalClosedAt)
            );

            window.addEventListener('load', () => {
                const financeLegalClosedAt = window.sessionStorage.financeLegalClosedAt;

                if (financeLegalClosedAt && financeLegalClosedAt != 'undefined') {
                    if (getMinutesBetweenTwoDates(financeLegalClosedAt) >= FINANCE_LEGAL_SHOW_AGAIN_AFTER_MINUTES) {
                        setShowFinanceLegal(showFinanceLegalFs);
                    } else {
                        (window as any).financeLegalClosedAt = financeLegalClosedAt;
                    }

                    window.sessionStorage.removeItem('isFinanceLegalClosed');
                } else {
                    setShowFinanceLegal(showFinanceLegalFs && !(window as any).financeLegalClosedAt);
                }
            });
        }
    });

    const getDisclaimerTitleAndMessage = async () => {
        let disclaimer = Cookie.get(FINANCE_LEGAL_DATA_COOKIE_NAME)
            ? JSON.parse(Cookie.get(FINANCE_LEGAL_DATA_COOKIE_NAME))
            : false;
        if (!disclaimer) {
            if (isStockJourney) {
                disclaimer = await DisclaimerService.getDisclaimer();
            } else {
                if (isHomeUrl(router.pathname) && filteredOffers.length) {
                    disclaimer = getDisclaimer(filteredOffers);
                } else if (isTrimPage) {
                    disclaimer = getDisclaimer(filteredTrims);
                } else if (carDetails?.disclaimer) {
                    disclaimer = carDetails?.disclaimer;
                }
            }
        }
        const output = {
            title: disclaimer?.slogan?.text ?? '',
            message: disclaimer?.representativeExample?.length
                ? renderToString(parser.toReact(disclaimer?.representativeExample[0]?.text))
                : '',
        };

        Cookie.set(FINANCE_LEGAL_DATA_COOKIE_NAME, disclaimer, { sameSite: 'strict', secure: !IS_DEV });

        return output;
    };

    const getDisclaimer = (filteredOffers: Array<IOfferItem | ITrimItem>) => {
        const offerWithDisclaimer = filteredOffers.find(
            (offer: IOfferItem | ITrimItem) => offer.extraFields.disclaimer
        );
        return offerWithDisclaimer?.extraFields?.disclaimer ?? false;
    };

    useEffect(() => {
        async function fetchData() {
            const disclaimer = await getDisclaimerTitleAndMessage();
            const title = disclaimer.title;
            if (title) {
                dispatch(
                    UIDuck.openModal({
                        modalType: UIDuck.MODAL_TYPES.FINANCE_LEGAL,
                        data: {
                            title: title,
                            message: disclaimer.message,
                            buttonText: t('financeLegal.continue'),
                        },
                    })
                );
            }
        }
        if (showFinanceLegal) fetchData();
    }, [isRoutingInProgress]);

    return null;
};

export default FinanceLegal;
