import {
    isChrome,
    isFirefox,
    isEdge,
    isSafari,
    isAndroid,
    browserVersion,
    isChromium,
    isSamsungBrowser,
    isMobileSafari,
    isIE,
    isYandex,
    isOpera,
    browserName,
} from 'react-device-detect';
import { MULTI_LANGUAGES_ENABLED, MULTI_LANGUAGES } from './../constants/main';

export const decodeFromBase64 = <T>(str: string): T => {
    if (!str) return {} as T;

    try {
        // try base64 string, legacy
        return JSON.parse(atob(str));
    } catch (error: any) {
        // lets try non base64 string
        try {
            return JSON.parse(`{${str}}`);
        } catch (error: any) {
            console.log(`__Unknown string format for decoding and parsing: ${error}`);
            return {} as T;
        }
    }
};

export const urlHasLanguagePrefixRegex = MULTI_LANGUAGES_ENABLED
    ? new RegExp(`^/(${MULTI_LANGUAGES.join('|')})(/|$)`)
    : null;

export const removeLanguageFromPathname = (pathname: string): string => {
    // APP-19216
    if (urlHasLanguagePrefixRegex) {
        return pathname.replace(urlHasLanguagePrefixRegex, '/');
    }
    return pathname;
};

interface IBrowserSupport {
    supported: boolean;
    requiredBrowser: string;
    updateLink: string;
}
export const detectBrowserVersion = (): IBrowserSupport => {
    let res: IBrowserSupport = { supported: true, requiredBrowser: '', updateLink: '' };

    const userAgent = typeof window !== 'undefined' ? window.navigator?.userAgent : '';
    const isSamsung =
        !isChrome &&
        (isSamsungBrowser ||
            browserName.toLocaleLowerCase() === 'samsung' ||
            userAgent.toLocaleLowerCase().includes('samsungbrowser'));
    const isBrave = browserName.toLocaleLowerCase() === 'brave' || userAgent.toLocaleLowerCase().includes('brave');

    if (isChrome) {
        res = {
            supported: Number(browserVersion) >= 81,
            requiredBrowser: 'Google Chrome: Version 81 or higher',
            updateLink: 'https://www.google.com/chrome',
        };
    } else if (isFirefox) {
        res = {
            supported: Number(browserVersion) >= 75,
            requiredBrowser: 'Mozilla Firefox: Version 75 or higher',
            updateLink: 'https://www.mozilla.org/en-US/firefox/new',
        };
    } else if (isEdge) {
        res = {
            supported: Number(browserVersion) >= 81,
            updateLink: 'https://www.microsoft.com/en-us/edge/download',
            requiredBrowser: 'Microsoft Edge: Version 81 or higher',
        };
    } else if (isSafari || isMobileSafari) {
        res = {
            supported: Number(browserVersion) >= 13.1,
            requiredBrowser: 'Apple Safari: Version 13.1 or higher (macOS)',
            updateLink: 'https://support.apple.com/downloads/safari',
        };
    } else if (isSamsung) {
        res = {
            updateLink: 'https://www.samsung.com/us/support/owners/app/samsung-internet',
            requiredBrowser: 'Samsung Browser: Version 11.2 or higher',
            supported: Number(browserVersion) >= 11.2,
        };
    } else if (isIE) {
        res = {
            supported: Number(browserVersion) >= 11,
            requiredBrowser: 'IE: Version 11 or higher',
            updateLink: 'https://www.microsoft.com/en-us/edge/download',
        };
    } else if (isYandex) {
        res = {
            supported: Number(browserVersion) >= 20.4,
            requiredBrowser: 'Yandex: Version 20.4 or higher',
            updateLink: 'https://browser.yandex.com',
        };
    } else if (isOpera) {
        res = {
            supported: Number(browserVersion) >= 67,
            requiredBrowser: 'Opera: Version 67 or higher',
            updateLink: 'https://www.opera.com/browsers/opera',
        };
    } else if (isBrave) {
        res = {
            supported: isChromium ? Number(browserVersion) >= 81 : Number(browserVersion) >= 1.8,
            requiredBrowser: 'Brave: Version 1.8 or higher',
            updateLink: 'https://brave.com/download',
        };
    }

    if (typeof window !== 'undefined') {
        console.log('[BROWSER DETECTION]: ', res, browserName, browserVersion);
    }

    return res;
};
