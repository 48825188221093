import { IObjectKey } from '../interfaces/Default';
import { AUTOBIZ_STATUS_SUCCESS, CONFIG_TRANSLATIONS, AUTOBIZ_LAST_ESTIMATION_STEP } from '../constants/config';

/**
 * Method transforms underscore type string to camelcase type string
 * @param value
 * NOTE: tested
 */
export const transformStringToCamelCase: (value: string) => string = (value: string) => {
    return value
        .toLowerCase()
        .replace(/_([a-z])/, (char: string) => char.toUpperCase())
        .replace(/_/, '');
};

/**
 * Method transforms underscore to camelcase
 * @param data
 * NOTE: tested
 */
export const transformObjectKeyToCamelCase: (data: IObjectKey) => IObjectKey = (data: IObjectKey) => {
    const transformOutput: IObjectKey = {};

    Object.keys(data).forEach((key: string) => (transformOutput[transformStringToCamelCase(key)] = data[key]));

    return transformOutput;
};

/**
 * Method processes translation particularities
 * @param data
 * NOTE: tested
 */
export function transformConfigTranslation(data: IObjectKey): IObjectKey {
    let transformOutput: IObjectKey = {};
    Object.keys(data).forEach((field: string) => {
        transformOutput = {
            ...transformOutput,
            [Object.keys(CONFIG_TRANSLATIONS).includes(field) ? CONFIG_TRANSLATIONS[field] : field]: data[field],
        };
    });

    return transformOutput;
}

/**
 * Method transforms all null properties into empty string
 * @param data
 * NOTE: tested
 */
export function transformWidgetNullProperty(data: IObjectKey): IObjectKey {
    let transformOutput: IObjectKey = {};
    Object.keys(data).forEach((field: string) => {
        transformOutput = { ...transformOutput, [field]: data[field] === null ? '' : data[field] };
    });

    return transformOutput;
}

/**
 * Method uses all transform functions to output ready to use widget data config
 * @param widgetData
 * NOTE: tested
 */
export function processWidgetData(widgetData: IObjectKey): IObjectKey {
    const widgetTranslated = transformConfigTranslation(widgetData);
    const widgetNotNullable = transformWidgetNullProperty(widgetTranslated);

    return widgetNotNullable;
}

/**
 * Method verifies if valuation object exists and return true if status is success(110)
 * NOTE: tested
 */
export function isValuationFinished(mopId?: string | null): boolean {
    const valuationObject: any = (window as any).valuation;

    if (valuationObject) {
        const parsedValuationObject: any = JSON.parse(valuationObject);

        if (mopId === null) parsedValuationObject.mopId = null;

        console.group('valobj');
        console.log(parsedValuationObject);
        console.groupEnd();

        if (
            parsedValuationObject.statusId &&
            (typeof parsedValuationObject.Valuation.estimateValuationApplied !== 'undefined' ||
                parsedValuationObject.Valuation.estimateValuationApplied !== null)
        ) {
            return (
                AUTOBIZ_STATUS_SUCCESS.includes(parsedValuationObject.statusId) &&
                parsedValuationObject.Valuation.estimateValuationApplied >= 0
            );
        }
        // finished failed valuation
        return (
            parsedValuationObject.statusId === null &&
            parsedValuationObject.lastStepVisited === AUTOBIZ_LAST_ESTIMATION_STEP
        );
    }

    return false;
}

export function isValuationFailed(): boolean {
    const valuationObject: any = (window as any).valuation;

    if (valuationObject) {
        const parsedValuationObject: any = JSON.parse(valuationObject);
        return (
            parsedValuationObject.statusId === null &&
            parsedValuationObject.lastStepVisited === AUTOBIZ_LAST_ESTIMATION_STEP
        );
    }

    return true;
}
