import { useState, useEffect } from 'react';

const useCookieConsent = () => {
    const [consentGiven, setConsentGiven] = useState(false);

    useEffect(() => {
        const checkCookieConsent = () => {
            const cookie = document.cookie;
            const hasConsentCookie = cookie.includes('_psac_gdpr_consent_given');
            const consentForAnalytics =
                cookie.includes('_psac_gdpr_consent_purposes=[cat_ana][cat_com]') ||
                cookie.includes('_psac_gdpr_consent_purposes=[cat_com]');

            if (hasConsentCookie && consentForAnalytics) {
                setConsentGiven(true);
            }
        };

        checkCookieConsent();
    }, []);

    return consentGiven;
};

export default useCookieConsent;
