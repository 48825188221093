import { IconTemplate } from './IconTemplate';
import { IconProps } from '.';
import styled from 'styled-components';
import { css } from '../../styles/theme';

export const IconStyled = styled(IconTemplate)<IconProps>`
    display: inline-block;
    height: ${({ height, auto }) => (auto ? 'auto' : `${height}px` || '40px')};

    svg {
        height: ${({ height }) => height || 40}px;
        width: ${({ width }) => width || 40}px;

        path {
            ${({ color }) =>
                color &&
                css`
                    *[fill] {
                        fill: ${color};
                    }
                    *[stroke] {
                        stroke: ${color};
                    }
                `}
        }

        line,
        circle {
            ${({ color }) =>
                color &&
                css`
                    stroke: ${color};
                `}
        }
    }
`;
