import { FC, useEffect, useState } from 'react';
import { useTranslations } from '@hooks/useTranslations';
import { publicAssets } from '@utils/url.utils';
import { BrowserUpdateProps } from './index';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV } from '../../constants/main';
import { detectBrowserVersion } from '@utils/misc.utils';

export const BrowserUpdateTemplate: FC<BrowserUpdateProps> = ({ className }) => {
    const { t } = useTranslations();

    const [redirectLink, setRedirectLink] = useState('');

    useEffect(() => {
        setRedirectLink(detectBrowserVersion().updateLink);
    }, []);

    return (
        <div className={className}>
            {isBrandAC && (
                <>
                    <div className="background" />
                    <div className="content">
                        <h1 className="title">{t('browser.detection.title')}</h1>
                        <p className="description">{t('browser.detection.description')}</p>
                        {redirectLink && (
                            <a className="link" rel="noopener noreferrer" href={redirectLink} target="_blank">
                                {t('browser.detection.button')}
                            </a>
                        )}
                    </div>
                </>
            )}

            {isBrandAP && (
                <div className="wrapper">
                    <div className="content">
                        <img className="image" src={publicAssets('/AP/ap_maintenance_bg.jpg')} alt="" />
                        <div className="description">
                            <h2>{t('browser.detection.title')}</h2>
                            <p>{t('browser.detection.description')}</p>
                            {redirectLink && (
                                <button>
                                    <a href={redirectLink} target="_blank" rel="noreferrer">
                                        <strong>{t('browser.detection.button')}</strong>
                                    </a>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {isBrandDS && (
                <div className="dsMaintenanceWrapper">
                    <div className="dsMaintenanceBgImage" />
                    <div className="dsMaintenanceContent">
                        <h2>{t('browser.detection.title')}</h2>
                        <div className="dsMaintenanceInnerContent">
                            <p>{t('browser.detection.description')}</p>
                            {redirectLink && (
                                <a href={redirectLink} target="_blank" rel="noreferrer">
                                    {t('browser.detection.button')}
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {isBrandOV && (
                <>
                    <div className="background" />
                    <div className="content">
                        <h1 className="title">{t('browser.detection.title')}</h1>
                        <p className="description">{t('browser.detection.description')}</p>
                        {redirectLink && (
                            <a className="link" rel="noopener noreferrer" href={redirectLink} target="_blank">
                                {t('browser.detection.button')}
                            </a>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
