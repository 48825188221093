import { IOptions, IOptionsResponse } from './optionsList.types';

/**
 * Format response and return only required data
 * @param serverResponse
 */
export const formatOptionsResponse: (serverResponse: any) => IOptionsResponse = (serverResponse: any) => {
    const {
        optionCategories: { object: options },
        extra: {
            object: { incompatibleOptionIds, externalId, images },
        },
    } = serverResponse._data;

    const loadedOptions: IOptions = {
        options: options,
        incompatibleOptionIds: incompatibleOptionIds,
        externalId: externalId,
        images,
    };
    return {
        statusCode: serverResponse._statusCode,
        items: loadedOptions,
    };
};
