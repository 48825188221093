import { getBasePath } from '../constants';
import routes from '../constants/routes';
import { IConfigurableCar, IStockCar } from '../interfaces/Car';
import { CarJourneyType } from '../services/carList/carList.types';
import { CASH, FINANCE } from '../services/filters/filters';
import { assetPathToCdnUrl } from './cdn.utils';
import { isProd, LANGUAGE, MULTI_LANGUAGES_ENABLED } from '../constants/main';

const MULTIPLE_PARAMS_DELIMITER = ',';
const RESERVED_CHARACTER_SUBSTITUTE = '+';

export enum URL_PATH_PARAMS {
    PAYMENT_JOURNEY = 'paymentJourney',
    CAR_JOURNEY = 'carJourney',
    ENGINE_GEARBOX_FUEL_SLUG = 'engineGearboxFuelSlug',
    INTERIOR_COLOR_SLUG = 'interiorColourSlug',
    EXTERIOR_COLOR_SLUG = 'exteriorColourSlug',
    NAMEPLATE_BODY_STYLE_SLUG = 'nameplateBodyStyleSlug',
    SPECK_PACK_SLUG = 'speckPackSlug',
    MODEL_ID = 'modelId',
    BODY_STYLE_ID = 'bodyStyleId',
    OPTIONS = 'options',
}

const DEFAULT_URL_OBJECTS_ORDER = [
    URL_PATH_PARAMS.CAR_JOURNEY,
    URL_PATH_PARAMS.NAMEPLATE_BODY_STYLE_SLUG,
    URL_PATH_PARAMS.SPECK_PACK_SLUG,
    URL_PATH_PARAMS.ENGINE_GEARBOX_FUEL_SLUG,
    URL_PATH_PARAMS.EXTERIOR_COLOR_SLUG,
    URL_PATH_PARAMS.INTERIOR_COLOR_SLUG,
    URL_PATH_PARAMS.OPTIONS,
];
const STOCK_URL_OBJECTS_ORDER = [
    URL_PATH_PARAMS.CAR_JOURNEY,
    URL_PATH_PARAMS.NAMEPLATE_BODY_STYLE_SLUG,
    URL_PATH_PARAMS.MODEL_ID,
    URL_PATH_PARAMS.BODY_STYLE_ID,
    URL_PATH_PARAMS.SPECK_PACK_SLUG,
    URL_PATH_PARAMS.ENGINE_GEARBOX_FUEL_SLUG,
    URL_PATH_PARAMS.EXTERIOR_COLOR_SLUG,
    URL_PATH_PARAMS.INTERIOR_COLOR_SLUG,
    URL_PATH_PARAMS.OPTIONS,
];

interface FiltersObject {
    [key: string]: string;
}

export const getRouteFromPathname = (pathname: string) => {
    const matchingRoutes = Object.values(routes).filter((route) => pathname.startsWith(route));

    return matchingRoutes.sort((a, b) => b.length - a.length)[0] ?? '';
};

const isValidParamValue = (value: any, paramName: URL_PATH_PARAMS, delimiter: string, substitute: string): boolean => {
    if (value.includes(delimiter)) {
        return value.split(delimiter).every((val: string) => isValidParamValue(val, paramName, delimiter, substitute));
    }

    const valueFormatted = value.replace(substitute, delimiter);

    switch (paramName) {
        case URL_PATH_PARAMS.PAYMENT_JOURNEY:
            return [CASH, FINANCE].includes(valueFormatted);
        case URL_PATH_PARAMS.CAR_JOURNEY:
            return [CarJourneyType.CONFIGURABLE, CarJourneyType.STOCK].includes(valueFormatted);
        case URL_PATH_PARAMS.INTERIOR_COLOR_SLUG:
        case URL_PATH_PARAMS.EXTERIOR_COLOR_SLUG:
        case URL_PATH_PARAMS.ENGINE_GEARBOX_FUEL_SLUG:
        case URL_PATH_PARAMS.SPECK_PACK_SLUG:
        case URL_PATH_PARAMS.NAMEPLATE_BODY_STYLE_SLUG:
        case URL_PATH_PARAMS.MODEL_ID:
        case URL_PATH_PARAMS.BODY_STYLE_ID:
        case URL_PATH_PARAMS.OPTIONS:
            return !!valueFormatted;
        default:
            return false;
    }
};

const buildSlashFilterObject = (
    url = '',
    delimiter: string,
    substitute: string,
    carJourney: CarJourneyType = CarJourneyType.CONFIGURABLE
): FiltersObject => {
    const urlParts = url.split('/').filter(Boolean);
    const paramsOrder =
        carJourney === CarJourneyType.STOCK || urlParts?.[0] === CarJourneyType.STOCK
            ? STOCK_URL_OBJECTS_ORDER
            : DEFAULT_URL_OBJECTS_ORDER;

    return urlParts.reduce((filterObject, urlPart, urlPartIndex) => {
        return paramsOrder.reduce((agg, paramName, paramIndex) => {
            const decoded = decodeURIComponent(urlPart);
            if (!isValidParamValue(decoded, paramName, delimiter, substitute) || urlPartIndex !== paramIndex) {
                return agg;
            }

            const value = decoded.includes(delimiter)
                ? decoded.split(delimiter).map((val: string) => val.replace(substitute, delimiter))
                : decoded.replace(substitute, delimiter);

            return {
                ...agg,
                [paramName]: value,
            };
        }, filterObject);
    }, {});
};

const buildSlashFilterURL = (params: FiltersObject = {}, carJourney: CarJourneyType = CarJourneyType.CONFIGURABLE) => {
    const paramsOrder = carJourney === CarJourneyType.STOCK ? STOCK_URL_OBJECTS_ORDER : DEFAULT_URL_OBJECTS_ORDER;
    return paramsOrder.reduce((agg, paramName) => {
        if (!params[paramName]) {
            return agg;
        }
        if (params[paramName].includes('#')) {
            return `${agg}${params[paramName]}`;
        }
        if (paramName === URL_PATH_PARAMS.OPTIONS) {
            return `${agg}?${URL_PATH_PARAMS.OPTIONS}=${encodeURIComponent(params[paramName])}`;
        }
        return `${agg}${encodeURIComponent(params[paramName])}/`;
    }, '/');
};

export const buildLanguagePathname = (pathname: string) => {
    if (MULTI_LANGUAGES_ENABLED) {
        return `/${LANGUAGE}${pathname}`;
    }

    return pathname;
};

export const buildParameterizedPathname = (
    pathname: string,
    carJourney: CarJourneyType,
    carFeatures: FiltersObject = {},
    delimiter = MULTIPLE_PARAMS_DELIMITER,
    substitute = RESERVED_CHARACTER_SUBSTITUTE
) => {
    const route = getRouteFromPathname(pathname);

    const routeWithoutEndingSlash = route.replace(/\/+$/, '');

    const query = pathname.indexOf('?') >= 0 ? pathname.substr(pathname.indexOf('?')) : '';

    const initialQueryObject: FiltersObject = buildSlashFilterObject(
        pathname.replace(route, '').replace(query, ''),
        delimiter,
        substitute,
        carJourney
    );

    const resultQueryObject = {
        ...initialQueryObject,
        carJourney,
        ...carFeatures,
    };

    return `${routeWithoutEndingSlash}${buildSlashFilterURL(resultQueryObject, carJourney)}${query}`;
};

export const extractParamsFromPathname = (
    url: string,
    delimiter = MULTIPLE_PARAMS_DELIMITER,
    substitute = RESERVED_CHARACTER_SUBSTITUTE
) => {
    const route = getRouteFromPathname(url);
    const paramsPathnamePart = url.replace(route, '');
    const pathname = paramsPathnamePart.substr(
        0,
        paramsPathnamePart.indexOf('?') >= 0 ? paramsPathnamePart.indexOf('?') : paramsPathnamePart.length
    );

    return buildSlashFilterObject(pathname, delimiter, substitute);
};

export const getValidUrl = (url: string): string => {
    let newUrl = decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, '');
    if (/^(:\/\/)/.test(newUrl)) {
        return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
    }
    return newUrl;
};

export const extractRouteFromUrl = (url: string) => {
    url = url || '/';
    const matchingRoutes = Object.values(routes).filter((route) => url.startsWith(route));
    const bestMatch = matchingRoutes.reduce((res: string, route: string) => {
        if (route.length > res.length) {
            return route;
        }

        return res;
    }, '/');

    if ([routes.ROOT, routes.SELECTOR].includes(bestMatch)) {
        const params = extractParamsFromPathname(url);
        const asPath = buildParameterizedPathname(bestMatch, params.carJourney as CarJourneyType);

        const urlReplacementMask = Object.entries(params).reduce((mask, [key, value]) => {
            if (key === URL_PATH_PARAMS.CAR_JOURNEY) {
                return mask;
            }

            return mask.replace(value, `[${key}]`);
        }, asPath);

        const finalMask = Object.entries(params).reduce((mask, [key]) => {
            if (
                [URL_PATH_PARAMS.PAYMENT_JOURNEY, URL_PATH_PARAMS.CAR_JOURNEY].includes(key as URL_PATH_PARAMS) ||
                !Object.values(URL_PATH_PARAMS).includes(key as URL_PATH_PARAMS)
            ) {
                return mask;
            }

            return `${mask}/[${key}]`.replace('//', '/');
        }, urlReplacementMask);

        return finalMask;
    }

    return bestMatch;
};

export const addBasePath = (path: string) => {
    const basePath = getBasePath();
    if (!basePath) {
        return path;
    }
    if (path === basePath) {
        return path;
    }
    if (path.indexOf(basePath + '/') === 0) {
        return path;
    }
    if (path.indexOf('/') !== 0 || path.indexOf('//') === 0) {
        return path;
    }
    return getBasePath() + path;
};

export const removeBasePath = (path: string) => {
    if (getBasePath()) {
        if (path.substr(0, getBasePath().length) === getBasePath()) {
            return path.substr(getBasePath().length);
        } else {
            return path;
        }
    }
    return path;
};

export interface IpublicAssets {
    (input: string): string;
}

export const publicAssets: IpublicAssets = (path: string) => {
    if (path[0] != '/') {
        throw new Error('Path must start with "/" character.');
    }
    return assetPathToCdnUrl(addBasePath('/public' + path));
};

export const withPublicAssets = (component: any) => {
    return (props?: any) => {
        return component({ ...props, publicAssets });
    };
};

export const getInitalJourneyType = (vehicle: IStockCar | IConfigurableCar) =>
    (vehicle as IStockCar).stock ? CarJourneyType.STOCK : CarJourneyType.CONFIGURABLE;

interface IBrandIdRedirectOpt {
    isLogin?: boolean;
    isGigyaEnabled?: boolean;
}
export const brandIdRedirect = (
    url: string,
    brandIdStateJson: string,
    opt: IBrandIdRedirectOpt = { isLogin: false, isGigyaEnabled: false }
) => {
    window.location.href = buildBrandIdRedirect(url, brandIdStateJson, opt);
};

export const buildBrandIdRedirect = (
    url: string = '',
    brandIdStateJson: string = '',
    opt: IBrandIdRedirectOpt = {}
): string => {
    let redirection = url;

    if (brandIdStateJson) {
        redirection += `&state=${btoa(encodeURIComponent(brandIdStateJson))}`;
    }

    if (opt?.isGigyaEnabled && opt?.isLogin && !isProd) {
        redirection += '&service=LDAPCVSClientChain_FederationGigyaPPROD';
    }

    return redirection;
};

export const isHomeUrl = (pathname: string): boolean => {
    return !!Object.values(CarJourneyType).find((journeyType: CarJourneyType) => `/${journeyType}` === pathname);
};
