import { css, IThemeInterface } from '../theme';
import fonts from './fonts';

const main = css`
    ${fonts}

    body {
        background-color: ${(props: IThemeInterface) => (props.isMyAccount ? '#ffffff' : '#f7f7f7')};

        .buorg {
            font-family: 'Peugeot', 'Arial', 'sans-serif';
        }

        .buorg::before {
            background-color: #142032;
        }

        .buorg-pad {
            font-size: 16px !important;
        }

        #buorgul,
        #buorgpermanent {
            background-color: #007edb;
        }

        #buorgul:hover,
        #buorgpermanent:hover {
            background-color: #0062c5;
        }

        #buorgig {
            border: 1px solid #fff;
            background: transparent;
            color: #fff;
        }

        #buorgig:hover {
            border: 1px solid #878b93;
            background: transparent;
            color: #878b93;
        }
    }

    .sectionTitle {
        color: ${({ theme }) => theme.colors.black};
        display: block;
        font-size: ${({ theme }) => theme.fontSizes.textBig};
        font-weight: ${({ theme }) => theme.fontWeights.textBold};
        margin-bottom: 20px;
    }
`;

export default main;
