import { IAction, INetworkStatus, IResponseError } from '../redux.interface';
import { DefaultRootState } from 'react-redux';
import { IUserAddress, IUser, IUserAuth, IUserIsLoggedIn, IUserAuthResponse } from '../../interfaces/User';
import { PaymentStatuses } from '../../constants/paymentStatuses';
import { IConfigurableCar, IDeal, IFeatureExtended } from '../../interfaces/Car';
import { AxiosPromise } from 'axios';
import { ISpecification } from '../../services/stock/models/stockItems.service.model';
import { BASKET_STEPS } from '../../constants/main';

export interface IDealSummary {
    total: number | null;
    img: string | null;
    grBodyStyle: ISpecification | IFeatureExtended;
    nameplate: ISpecification | IFeatureExtended;
}
export interface IDealExtraFields {
    carteGrise: string;
    dealID: string;
    dealerFees: string;
    mopId: string;
    mopLastStepCode: number;
    partExchangeStatusId: string | number;
    postalCode: string;
    promoCode: null | string;
    redirectUrl: string;
    sales_type: string;
    quotationId?: string;
    status: string;
    finance_file_id?: string;
    agent_discount?: string;
    px: string;
    comments: string;
    customBackUrl?: string;
    agentDealerCode: string;
    agentType: string;
    depositType?: string;
    dealerReservationFee?: number;
    dealerReservationFeeOriginal?: number;
    useExternalCheckout?: boolean;
    form_submissions?: { form_type: string };
    reservationFee?: number;
    providerType?: string;
    geolocation_lat?: number | null;
    geolocation_lng?: number | null;
    hasSelectedPaymentJourney?: boolean;
    selectedFeesValue?: string | boolean;
    selectedPxValue?: string | boolean;
    assistedFinance?: boolean;
}

interface ISibling {
    checkout: string;
    configuration: string;
    dealer: string;
    extraFields: IDealExtraFields;
    financeQuote: string;
    fullProductConfiguration: IConfigurableCar;
    journeyType: string;
    mopId: string;
    partExchange: string;
    productType: string;
}

export interface IMyOrder {
    orderId: number;
    orderNumber: string;
    dateAdded: any; // TODO-JP
    products: any; // TODO-JP
    sibling: ISibling;
}

export enum MySavedDealJourneyType {
    BUY_ONLINE_CASH = 'buy_online_cash',
    BUY_ONLINE_FINANCE = 'buy_online_finance',
}

export enum IDealProductType {
    CONFIGURABLE = 'configuration',
    PARENT = 'parent',
}

export interface IMySavedDeal {
    token: string;
    fullProductConfiguration: any; // TODO-JP
    dealerId: string;
    status: PaymentStatuses;
    financeQuoteId: string;
    journeyType: MySavedDealJourneyType;
    details?: any; // TODO-JP
    endDate?: Date;
    startDate?: Date;
    updated?: Date;
    created?: Date;
    checkoutStep: string;
    partExchangeId: null | string;
    productType: IDealProductType;
    mopId: string;
    children?: any[];
    extraFields: IDealExtraFields;
}

export interface IduckState {
    userAuth: IUserAuth;
    userDetails: IUser | undefined;
    userAddress: IUserAddress | undefined;
    myAccountSummary: {
        status: null | INetworkStatus;
        error: null | IResponseError;
        myOrders: IDealSummary;
        mySaves: IDealSummary;
    };
    myOrders: {
        status: INetworkStatus;
        items: IMyOrder[];
        page: number;
        perPage: number;
        error: null | IResponseError;
    };
    mySaves: {
        status: INetworkStatus;
        items: IMySavedDeal[];
        page: number;
        perPage: number;
        error: null | IResponseError;
    };
    setActiveDeal: {
        status: INetworkStatus;
        id: string;
        error: null | IResponseError;
    };
    deleteDeal: {
        status: INetworkStatus;
        id: string;
        error: null | IResponseError;
    };
    isLoggingOut: boolean;
}

export interface IUserDuck {
    name: string;
    reducer: (state: IduckState, action: IAction) => IduckState;
    actionSetUserLogged: (payload?: IUserIsLoggedIn, accessToken?: string) => IAction;
    actionSetUserLoggedDone: (payload?: IUserAuthResponse) => IAction;
    setUserDetails: (payload: IUser) => IAction;
    setUserAddress: (payload: IUserAddress) => IAction;
    setIsLoggingOut: (payload: boolean) => IAction;
    getOwnState: (state: DefaultRootState) => IduckState;
    isAgentLogged: (state: DefaultRootState) => boolean;
    isLoggedIn: (state: DefaultRootState) => boolean;
    actionFetchMyAccountSummary: () => void;
    actionFetchMyOrders: (page: number, perPage?: number) => void;
    actionFetchMySaves: () => void;
    actionFetchMySave: () => void;
    actionSetActiveDeal: (dealId: string) => AxiosPromise<IDeal<IConfigurableCar>>;
    actionSetActiveDealClear: () => void;
    actionDeleteMySavedDeal: (dealId: string) => void;
}
