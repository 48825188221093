import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { useJourneyType } from '@hooks/useJourneyType';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import { useCarDetailsDuck } from '@hooks/useCarDetailsDuck';

export const useIgnorePXBonus = () => {
    const { isCash } = useJourneyType();
    const { promoCodeInDeal } = useCarDetailsDuck();
    const isPxIgnorePromoFinance = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_PX_IGNORE_PROMO_FINANCE);
    const isPxIgnorePromoCash = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_PX_IGNORE_PROMO_CASH);
    const parsedPromoCode = promoCodeInDeal && JSON.parse(promoCodeInDeal);

    return {
        shouldIgnorePXBonus: isCash
            ? isPxIgnorePromoCash || !!parsedPromoCode?.pxBonusRemoval
            : isPxIgnorePromoFinance || !!parsedPromoCode?.pxBonusRemoval,
        isPxIgnorePromoFinance,
        isPxIgnorePromoCash,
    };
};
