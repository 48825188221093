import getConfig from 'next/config';
import { BRAND } from './main';
const config = getConfig();

const constants: any = {
    NEXT_PUBLIC_APP_PREFIX: config ? config.publicRuntimeConfig.basePath : '',
};

export const getBasePath = () => {
    return constants.NEXT_PUBLIC_APP_PREFIX;
};

export const getBrand = () => {
    return BRAND;
};

/**
 * Warning!!! You should never change app constants.
 * This method is only for unit test. Do not call it in your code.
 *
 * @param name
 * @param value
 */
export const setConstant = (name: string, value: any) => {
    constants[name] = value;
};
