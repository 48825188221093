import FilterDuck from '../redux/filters/filter.duck';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { CASH, FINANCE } from '../services/filters/filters';
import GlobalDuck from 'src/redux/global/global.duck';
import { Redux } from 'src/redux/redux.interface';
import { CarDetailsDuck } from '../redux/carDetails/carDetails.duck';
import { LEASYS_PROVIDER_TYPE } from '../constants/main';

export const useJourneyType = () => {
    const carJourney = useSelector((state: Redux) => GlobalDuck.getOwnState(state).carJourney);
    const currentDeal = useSelector((state: Redux) => CarDetailsDuck.getOwnState(state).currentDeal);
    const paymentJourneyType = useSelector((state) => FilterDuck.getOwnState(state).budgetType);
    const isFinance = useMemo(() => paymentJourneyType === FINANCE, [paymentJourneyType]);
    const isCash = useMemo(() => paymentJourneyType === CASH, [paymentJourneyType]);
    const isRent = useMemo(() => currentDeal?.extraFields?.providerType === LEASYS_PROVIDER_TYPE, [currentDeal]);

    return { paymentJourneyType, isFinance, isCash, isRent, carJourney };
};
