import {
    FINANCE,
    OperatorTypes,
    EMPLOYEE_PRICE_TYPE,
    NAMEPLATE_BODYSTYLE_SLUG,
    MONTHLY_PRICES_GLOBAL,
} from '../../filters/filters';
import {
    PRICES,
    MONTHLY_PRICES,
    AMOUNT,
    TYPE,
    NAMEPLATEBODYSTYLE,
    SPECK_PACK_SLUG,
    ENGINE_GEARBOX_FUEL_SLUG,
    EXTERIOR_COLOUR_SLUG,
    INTERIOR_COLOUR_SLUG,
    ENGINE,
    ID,
    EXTERNAL_ID,
    GLOBAL,
    TBudgetType,
    IS_WITH_OPTIONS,
} from './constants/sdkFields';
import { IAddFilter } from '../../../interfaces/IFilters';
import { IFilter } from '../../carList/carList.types';

/**
 * Method adds monthly range price if the journey is finance
 * @param input
 * NOTE: function tested
 */
export function addMonthlyRangeFilter(input: any): any {
    return input.addFilter(
        `${PRICES}.${MONTHLY_PRICES}.${AMOUNT}.${GLOBAL}`,
        OperatorTypes.BETWEEN,
        {
            from: 0,
            to: 100000,
        },
        [PRICES, MONTHLY_PRICES, AMOUNT]
    );
}

/**
 * Method adds employee type filter
 * @param input
 * NOTE: function tested
 * @param name
 * @param parent
 */
export function addEmployeeTypeFilter(input: any, name?: string, parent?: string): any {
    return input.addFilter(
        name || `${PRICES}.${TYPE}`,
        OperatorTypes.EQUALS,
        EMPLOYEE_PRICE_TYPE,
        [PRICES, TYPE],
        parent
    );
}

/**
 * Method adds nameplateBodystyle filter
 * @param input
 * @param nameplateBodystyle
 * NOTE: function tested
 */
export function addNameplateBodystyleFilter(input: any, nameplateBodystyle: string | null): any {
    if (nameplateBodystyle) {
        input.addFilter(NAMEPLATEBODYSTYLE, OperatorTypes.EQUALS, nameplateBodystyle);
    }

    return input;
}

/**
 * Method adds nameplateBodystyle filter
 * @param input
 * @param nameplateBodystyleSlug
 * NOTE: function tested
 */
export function addNameplateBodystyleSlugFilter(input: any, nameplateBodystyleSlug: string | null): any {
    if (nameplateBodystyleSlug) {
        input.addFilter(NAMEPLATE_BODYSTYLE_SLUG, OperatorTypes.EQUALS, nameplateBodystyleSlug);
    }

    return input;
}

/**
 * Method adds specPackSlug filter
 * @param input
 * @param speckPackSlug
 * NOTE: function tested
 */
export function addSpeckPackSlugFilter(input: any, speckPackSlug: string | null): any {
    if (speckPackSlug) {
        input.addFilter(SPECK_PACK_SLUG, OperatorTypes.EQUALS, speckPackSlug);
    }

    return input;
}

/**
 * Method adds engine filter
 * @param input
 * @param engineGearboxFuelSlug
 * NOTE: function tested
 */
export function addEngineId(input: any, engineId: string | null): any {
    if (engineId) {
        input.addFilter(`${ENGINE}.${ID}`, OperatorTypes.EQUALS, engineId);
    }

    return input;
}

/**
 * Method adds engine filter
 * @param input
 * @param engineGearboxFuelSlug
 * NOTE: function tested
 */
export function addEngineGearboxFuelSlug(input: any, engineGearboxFuelSlug: string | null): any {
    if (engineGearboxFuelSlug) {
        input.addFilter(ENGINE_GEARBOX_FUEL_SLUG, OperatorTypes.EQUALS, engineGearboxFuelSlug);
    }

    return input;
}

/**
 * Method adds engine filter
 * @param input
 * @param exteriorColourSlug
 * NOTE: function tested
 */
export function addExteriorColourSlug(input: any, exteriorColourSlug: string | null): any {
    if (exteriorColourSlug) {
        input.addFilter(EXTERIOR_COLOUR_SLUG, OperatorTypes.EQUALS, exteriorColourSlug);
    }

    return input;
}

/**
 * Method adds externalId filter
 * @param input
 * @param externalId
 * NOTE: function tested
 */
export function addExternalId(input: any, externalId: string | null): any {
    if (externalId) {
        input.addFilter(EXTERNAL_ID, OperatorTypes.EQUALS, externalId);
    }

    return input;
}

/**
 * Method adds engine filter
 * @param input
 * @param interiorColourSlug
 * NOTE: function tested
 */
export function addInteriorColourSlug(input: any, interiorColourSlug: string | null): any {
    if (interiorColourSlug) {
        input.addFilter(INTERIOR_COLOUR_SLUG, OperatorTypes.EQUALS, interiorColourSlug);
    }

    return input;
}

/**
 * Method add filters to input
 * @param filters
 * @param input
 * NOTE: this uses any since input can be different
 */
export function addFilters(filters: IAddFilter[], input: any): any {
    if (filters && filters.length) {
        filters.forEach((filter: IAddFilter) => {
            input.addFilter(filter.name, filter.operation, filter.value, filter.nesting, filter.parent);
        });

        return input;
    }

    return input;
}

/* filter out configurations with undefined monthlyPrice amount */
export function applyHasMonthlyPriceFilter(filters: IFilter[], paymentJourneyType: TBudgetType) {
    if (paymentJourneyType === FINANCE && !filters.find(p => p.name === MONTHLY_PRICES_GLOBAL)) {
        filters.push({
            nesting: [PRICES, MONTHLY_PRICES, AMOUNT],
            name: MONTHLY_PRICES_GLOBAL,
            operation: OperatorTypes.BETWEEN,
            value: { from: 1, to: 99999 },
            parent: null,
        });
    }
}

/**
 * Method adds filter to remove cars that have any `options`
 */
 export function addWithoutOptionsFilter(input: any): any {
    return input.addFilter(
        IS_WITH_OPTIONS,
        OperatorTypes.IS_EMPTY,
        '',
    );
}