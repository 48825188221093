import { IAction, Redux } from '../redux.interface';
import {
    IContentDuck,
    IContentState,
    IFAQ,
    IBannerItem,
    ITranslation,
    IHeaderItem,
    IFooterItem,
    BannerJourneyType,
    ISetting,
} from './content.duck.interface';
import { createActionTypes } from '../createActionTypes';
import { ContentService } from '../../services/content/content.service';
import Cookie from 'js-cookie';
import { SESSION_DOMAIN } from '../../constants/main';
import { CarJourneyType, TBudgetType } from '../../services';
const name = 'Content';

const initialState: IContentState = {
    faq: [],
    banner: [],
    footer: [],
    header: [],
    translations: {},
    settings: [],
};

const actionTypes: any = createActionTypes(
    {
        load_content: 'load_content',
        set_content: 'set_content',
    },
    name
);

class ContentDuckClass implements IContentDuck {
    name = name;

    reducer(state: IContentState = initialState, action: IAction) {
        switch (action.type) {
            case actionTypes.load_content:
                return {
                    ...state,
                    ...action.payload,
                };
            case actionTypes.set_content:
                return {
                    ...state,
                    ...action.payload,
                };
            default:
                return state;
        }
    }

    getOwnState(state: any): IContentState {
        return state[this.name] || initialState;
    }

    loadContent(lang: string): any {
        return (dispatch: any) => {
            ContentService.getContent(lang).then((data) => {
                if (data) {
                    dispatch({
                        type: actionTypes.load_content,
                        payload: data,
                    });
                }
            });
        };
    }

    setContent(content: any, lang?: string, i18n?: any): IAction {
        if (content && content.translations) {
            i18n.addResourceBundle(lang || 'fr', 'common', content.translations, true, true);

            if (lang && i18n.language !== lang) {
                const isDev = SESSION_DOMAIN === 'localhost';
                i18n.changeLanguage(lang);
                Cookie.set(
                    'next-i18next',
                    lang,
                    isDev
                        ? { domain: SESSION_DOMAIN, sameSite: 'strict', secure: false }
                        : { sameSite: 'strict', secure: true }
                );
            }
        }

        return {
            type: actionTypes.set_content,
            payload: content,
        };
    }

    getContent(state: any) {
        return this.getOwnState(state) || initialState;
    }

    getFaq(state: any): IFAQ[] {
        return this.getContent(state).faq;
    }
    getBanner(state: Redux): IBannerItem[] {
        return this.getContent(state).banner;
    }
    getStockBanners(state: Redux): IBannerItem[] {
        return this.getBanner(state).filter(({ journeyType }) => journeyType === BannerJourneyType.STOCK);
    }
    getConfigurableBanners(state: Redux): IBannerItem[] {
        return this.getBanner(state).filter(({ journeyType }) => journeyType === BannerJourneyType.CONFIGURABLE);
    }
    getFooter(state: any): IFooterItem[] {
        return this.getContent(state).footer;
    }
    getHeader(state: any): IHeaderItem[] {
        return this.getContent(state).header;
    }
    getTranslations(state: any): ITranslation {
        return this.getContent(state).translations;
    }
    getSettings(state: any): ISetting[] {
        return this.getContent(state).settings;
    }
    getDynamicBannersSetting(state: any): boolean {
        const dynamicBannersSetting = this.getSettings(state).find((setting) => setting.key === 'dynamic_banners');
        return dynamicBannersSetting ? dynamicBannersSetting.value : false;
    }
    isCheckoutDisable(state: any, carJourney: CarJourneyType, paymentJourney: TBudgetType): boolean {
        const isCheckoutDisable = this.getSettings(state).find(
            (setting) => setting.key === `checkout_disable_${carJourney}_${paymentJourney}`
        );
        return isCheckoutDisable ? isCheckoutDisable.value : false;
    }
}

export const ContentDuck = new ContentDuckClass();
export default ContentDuck;
