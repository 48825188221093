import styled from 'styled-components';
import { breakpoints, css } from '../../styles/theme';
import { Grid } from 'react-styled-flexboxgrid';
import { GridProps } from '.';

export const GridStyled = styled(Grid)<GridProps>`
    padding-left: 1rem;
    padding-right: 1rem;

    ${({ noPadding }) =>
        noPadding &&
        css`
            padding-left: 0rem;
            padding-right: 0rem;
        `}

    ${({ noPaddingMobile }) =>
        noPaddingMobile &&
        css`
            padding-left: 0rem;
            padding-right: 0rem;

            @media (min-width: ${breakpoints.xs}px) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        `}

    ${({ supressMediumBpMaxWidth }) =>
        supressMediumBpMaxWidth &&
        css`
            @media (min-width: ${breakpoints.sm}px) {
                max-width: 100% !important;
            }
        `}



    @media only screen and (min-width: 75em) {
        ${({ unsetMaxWidth, isWide }) =>
            !unsetMaxWidth &&
            css`
                max-width: ${isWide ? '120rem' : '95rem'} !important;
            `}
    }
`;
