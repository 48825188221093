import CoreApi from '../core/coreApi.service';
import { processContentData } from './content.utils';

class ContentServiceClass {
    async getContent(lang: string) {
        const res = await CoreApi.get(`spc-api/cms-content/aggregated-content/${lang}`);
        if (res.status === 200) {
            return processContentData(res.data);
        } else return undefined;
    }
    async getSettings() {
        const res = await CoreApi.get(`spc-api/cms-content/settings`);
        if (res.status === 200) {
            return res.data;
        } else return undefined;
    }
}

export const ContentService = new ContentServiceClass();
export default ContentService;
