import useCustomRouter from '@hooks/useCustomRouter';
import { useEffect } from 'react';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { useDispatch } from 'react-redux';
import queryString from 'querystring';
import useTranslations from '@hooks/useTranslations';
import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../../context/featureSwitchApp';
import { BR_DISABLE_PAGE_REDIRECT_URL, ModalVersion } from '../../constants/main';
import { useRoutingInProgress } from '@hooks/useRoutingInProgress';

enum SHOW_MISSING_VALUES {
    MISSING_PARTS = 'MISSING_PARTS',
    MISSING_CAR = 'MISSING_CAR',
    MISSING_STOCK_CAR = 'MISSING_STOCK_CAR',
    TRUE = 'true',
}

const MissingPartsHandler = (): null => {
    const dispatch = useDispatch();
    const { t } = useTranslations();
    const isRoutingInProgress = useRoutingInProgress();
    const { asPath, replace, query } = useCustomRouter();
    const { showMissing, ...remainingQuery } = query;

    const getModalData = (showMissing: string) => {
        switch (showMissing) {
            case SHOW_MISSING_VALUES.MISSING_PARTS:
            case SHOW_MISSING_VALUES.TRUE:
                return {
                    title: t('missingModal.missingParts.title'),
                    message: t('missingModal.missingParts.message'),
                    additionalMessage: t('missingModal.missingParts.anotherConfiguration'),
                    buttonText: t('missingModal.missingParts.continue'),
                };
            case SHOW_MISSING_VALUES.MISSING_CAR:
                return {
                    title: t('missingModal.missingCar.title'),
                    message: t('missingModal.missingCar.message'),
                    buttonText: t('missingModal.missingCar.continue'),
                };
            case SHOW_MISSING_VALUES.MISSING_STOCK_CAR:
                return {
                    title: t('stockVehicleUnavailable.title'),
                    message: t('stockVehicleUnavailable.label'),
                    buttonText: t('missingModal.missingCar.continue'),
                };
            default:
                return null;
        }
    };

    const handleClose = () => {
        const newQuery = queryString.stringify(remainingQuery);
        replace(asPath.replace(/\?(.*)/, newQuery ? `?${newQuery}` : ''));
    };

    const isBrandRecommendsJourneyAllowed = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_BRAND_RECOMMENDS_ENABLED
    );

    const showMissingValue = Array.isArray(showMissing) ? showMissing[0] : showMissing;

    useEffect(() => {
        if (showMissing) {
            if (isBrandRecommendsJourneyAllowed) {
                return (window.location.href = BR_DISABLE_PAGE_REDIRECT_URL);
            }
            dispatch(
                UIDuck.openModal({
                    modalType: UIDuck.MODAL_TYPES.MISSING_PARTS,
                    data: getModalData(showMissingValue),
                    callbacks: {
                        onClose: handleClose,
                    },
                    modalVersion: ModalVersion.v2,
                    modalProperties: {
                        centerModeOnDesktop: true,
                    },
                })
            );
        }
    }, [showMissing]);

    return null;
};

export default MissingPartsHandler;
