import { INetworkStatus, IResponseError } from '../redux/redux.interface';

export interface IUser {
    firstname: string;
    lastname: string;
    email: string;
    references?: any;
    telephone: string;
    middleInitial?: string;
    title: number;
    reservationContact?: number;
    keepInTouch?: number[];
    businessName?: string;
    businessID?: string;
    businessVAT?: string;

    [index: string]: any;
}

export interface IUserAddress {
    address1: string;
    address2: string;
    city: string;
    postcode: string;
    zone?: {
        name: string;
    };
    nif?: string;

    [index: string]: string | { name: string };
}

export enum EUserLoginType {
    BRAND_ID = 'brandid',
    GUEST = 'guest',
    DEALER = 'agent-dealer',
    ESELLER = 'agent-eseller',
}
export type IUserIsLoggedIn = boolean | undefined;
export type IUserAuthResponse = {
    loginType?: EUserLoginType;
    isLoggedIn: IUserIsLoggedIn;
    detail?: {
        id?: number;
        userId?: string;
        firstName?: string;
        lastName?: string;
        dealerCode?: string;
    };
};
export interface IUserAuth extends IUserAuthResponse {
    status: INetworkStatus;
    error: null | IResponseError;
}
