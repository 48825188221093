import { FEATURES_LIST, IFeatureSwitchContext } from '../context/featureSwitchApp';

export const getFeatureSwitch = (name: FEATURES_LIST, context: IFeatureSwitchContext): any => {
    return context[name];
};

export const isFeatureEnabled = (name: FEATURES_LIST, context: IFeatureSwitchContext, value?: any): boolean => {
    const contextArray = context[name] || [];

    switch (name) {
        case FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS:
        case FEATURES_LIST.FEATURE_SWITCH_BANK_CHECKOUT_JOURNEY_TYPES:
        case FEATURES_LIST.FEATURE_SWITCH_CONTACT_OPTIONS:
            return Array.isArray(contextArray) ? contextArray.includes(value) : false;

        case FEATURES_LIST.FEATURE_SWITCH_FORCE_FINANCE_WIDGET_BUTTON:
        case FEATURES_LIST.FEATURE_SWITCH_FINANCE_WIDGET_TYPE:
            return context[name] == value;

        default:
            return !!context[name];
    }
};

export interface IFeatureSwitchValue<ValueType = boolean> {
    name: string;
    value: ValueType;
}

export interface ISelectFSOption {
    value: string;
    label: string;
}

export interface ISelectFSData {
    options: ISelectFSOption[];
    value: ISelectFSOption[];
}

export enum FeatureSwitchType {
    BOOLEAN_VALUE,
    SELECT,
    MULTIPLE_VALUES,
    TEXT,
}
