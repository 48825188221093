import { css, theme } from '../theme';
import { withPublicAssets, IpublicAssets } from '@utils/url.utils';

const getFonts = ({ publicAssets }: { publicAssets: IpublicAssets }) => css`
    @font-face {
        src: url(${publicAssets('/fonts/AC/CitroenType-Light.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontBase};
        font-weight: ${theme.fontWeights.textLight};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/AC/CitroenType-Regular.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontBase};
        font-weight: ${theme.fontWeights.textRegular};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/AC/CitroenType-Medium.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontBase};
        font-weight: ${theme.fontWeights.textMedium};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/AC/CitroenType-Bold.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontBase};
        font-weight: ${theme.fontWeights.textBold};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/AC/CitroenLight.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontSecondary};
        font-weight: ${theme.fontWeights.textLight};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/AC/CitroenRegular.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontSecondary};
        font-weight: ${theme.fontWeights.textRegular};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/AC/CitroenBold.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontSecondary};
        font-weight: ${theme.fontWeights.textBold};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/AP/Peugeot-Light.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontTertiary};
        font-weight: ${theme.fontWeights.textLight};
        font-style: normal;
    }
`;

export default withPublicAssets(getFonts)();
