import { config, MM_HEADERS } from '../../../../constants/main';

export const SDK_CONFIG = {
    endpoint: {
        default: {
            httpClient: {
                baseUrl: config.BACKEND_URL,
                ...MM_HEADERS,
            },
        },
    },

    core: {
        development: {
            debug: 0,
            benchmark: false,
        },
    },

    inputDefaults: {
        common: {
            market: config.MARKET,
            language: config.LANGUAGE,
            brands: [config.BRAND],
            filters: '',
        },

        specifics: {
            carOptions: {
                sources: ['options'],
            },
            carInfoInput: {
                levelAggregations: [
                    {
                        nesting: 'nameplate.id',
                        parent: null,
                    },
                    {
                        nesting: 'model.id',
                        parent: 'nameplate.id',
                    },
                    {
                        nesting: 'engine.id',
                        parent: 'nameplate.id',
                    },
                    {
                        nesting: 'specPack.id',
                        parent: 'nameplate.id',
                    },
                    {
                        nesting: 'exteriorColour.id',
                        parent: 'nameplate.id',
                    },
                ],
                relevancyAggregations: [
                    {
                        name: 'price',
                        parent: 'nameplate.id',
                        fields: [
                            'nameplate',
                            'externalId',
                            'price',
                            'monthlyPrice',
                            'priceFormat',
                            'currency',
                            'images',
                            'leadTime',
                            'fuel',
                            'fuelCode',
                            'code5000',
                        ],
                        operation: {
                            sort: 'asc',
                            size: 1,
                            from: 0,
                        },
                    },
                ],
            },
            carDetailInput: {
                levelAggregations: [
                    {
                        name: 'detailsAggregated',
                        nesting: 'detailsAggregated',
                        parent: '',
                    },
                ],
                relevancyAggregations: [
                    {
                        name: 'prices.basePrice',
                        parent: 'detailsAggregated',
                        fields: ['*'],
                        operation: {
                            sort: 'asc',
                            size: 1,
                        },
                    },
                ],
            },
            carFilterInput: {
                levelAggregations: [
                    {
                        nesting: 'exteriorColour.id',
                        children: '',
                    },
                    {
                        nesting: 'fuel',
                        children: '',
                    },
                ],
                minMaxAggregations: [
                    {
                        nesting: 'price',
                        children: '',
                    },
                ],
                filters: '',
            },
            featuresList: {
                market: config.MARKET,
                language: config.LANGUAGE,
                brands: [config.BRAND],
                levelAggregations: [
                    {
                        name: 'model',
                        nesting: 'model.id',
                        parent: '',
                    },
                    {
                        name: 'engine',
                        nesting: 'engine.id',
                        parent: null,
                    },
                    {
                        name: 'specPack',
                        nesting: 'specPack.id',
                        parent: null,
                    },
                    {
                        name: 'exteriorColour',
                        nesting: 'exteriorColour.id',
                        parent: null,
                    },
                    {
                        name: 'bodyStyle',
                        nesting: 'bodyStyle.id',
                        parent: null,
                    },
                ],
                relevancyAggregations: [
                    {
                        name: 'model',
                        parent: 'model',
                        fields: ['model'],
                        operation: {
                            size: 1,
                            from: 0,
                        },
                    },
                    {
                        name: 'engine',
                        parent: 'engine',
                        fields: ['engine'],
                        operation: {
                            size: 1,
                            from: 0,
                        },
                    },
                    {
                        name: 'exteriorColour',
                        parent: 'exteriorColour',
                        fields: ['exteriorColour'],
                        operation: {
                            size: 1,
                            from: 0,
                        },
                    },
                    {
                        name: 'specPack',
                        parent: 'specPack',
                        fields: ['specPack'],
                        operation: {
                            size: 1,
                            from: 0,
                        },
                    },
                    {
                        name: 'bodyStyle',
                        parent: 'bodyStyle',
                        fields: ['bodyStyle'],
                        operation: {
                            size: 1,
                            from: 0,
                        },
                    },
                ],
            },
        },
    },
};

export default SDK_CONFIG;
