import { FilterDuck } from '../redux/filters/filter.duck';
import { PaymentJourneyTypes } from 'src/partExchange/interfaces/Default';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { useSelector } from 'react-redux';
import CarDetailsDuck from '../redux/carDetails/carDetails.duck';
import { IEngineSlugs } from '../redux/carDetails/carDetails.duck.interface';
import { FUEL_TYPES } from '../constants/main';
import { useFeatureSwitchEnabled } from './useFeatureSwitchEnabled';
import { findDefaultConfiguration } from '@utils/Offers.utils';
import { useMemo } from 'react';

export const useCarDetailsDuck = () => {
    const allCarDetails = useSelector((state) => CarDetailsDuck.getOwnState(state).allCarDetails);
    const carDetails = useSelector((state) => CarDetailsDuck.getOwnState(state).currentCarDetails);
    const fuel = useSelector((state) => CarDetailsDuck.getOwnState(state).currentCarDetails?.fuel);
    const engineSlugs: IEngineSlugs = useSelector((state) => CarDetailsDuck.getOwnState(state).engineSlugs);
    const enginePrices = useSelector((state) => CarDetailsDuck.getMotorization(state));
    const engineFuels = useSelector((state) => CarDetailsDuck.getOwnState(state).engineFuels);
    const leadTime = useSelector((state) => CarDetailsDuck.getOwnState(state).currentCarDetails?.leadTime);
    const currentDeal = useSelector((state) => CarDetailsDuck.getOwnState(state).currentDeal);
    const offers = useSelector((state) => CarDetailsDuck.getOwnState(state).currentCarDetails?.offers);
    const engineGearboxes = useSelector((state) => CarDetailsDuck.getOwnState(state).engineGearboxes);
    const interiorImages = useSelector((state) => CarDetailsDuck.getOwnState(state).interiorImages);
    const interiorColorSlugs = useSelector((state) => new Map(CarDetailsDuck.getOwnState(state).interiorColorSlugs));
    const exteriorColorSlugs = useSelector((state) => new Map(CarDetailsDuck.getOwnState(state).exteriorColorSlugs));
    const dealDealerLayerEnabled = useSelector((state) => CarDetailsDuck.getCurrentDealDealerLayerEnabled(state));
    const promoCodeInDeal = useSelector(
        (state) => CarDetailsDuck.getOwnState(state)?.currentDeal?.extraFields
    )?.promoCode;

    const getMotorization = useSelector((state) => CarDetailsDuck.getMotorization(state));
    const getExteriorColor = useSelector((state) => CarDetailsDuck.getExteriorColor(state));
    const getInteriorColor = useSelector((state) => CarDetailsDuck.getInteriorColor(state));
    const getCarConfigurations = useSelector((state) => CarDetailsDuck.getCarConfigurations(state));

    const isValidForEcoBonus = `${FUEL_TYPES.HYBRID};${FUEL_TYPES.ELECTRIC}`.split(';').includes(fuel);
    const ecoBonus = useSelector(
        (state) => CarDetailsDuck.getOwnState(state).currentCarDetails?.importInputs?.ecologicalBonus
    );

    const filters = useSelector((state) => FilterDuck.getFilters(state));
    const isBenefitsEnabled = useFeatureSwitchEnabled(FEATURES_LIST.FEATURE_SWITCH_BENEFITS_MODE_ENABLED);
    const defaultConfiguration = useMemo(() => {
        const usingFilters = filters.filter((filter) => filter.value).length > 0;
        return (
            isBenefitsEnabled && !usingFilters && findDefaultConfiguration(carDetails?.offers, PaymentJourneyTypes.CASH)
        );
    }, [isBenefitsEnabled, carDetails?.offers, filters]);

    return {
        allCarDetails,
        carDetails,
        fuel,
        engineSlugs,
        enginePrices,
        engineFuels,
        leadTime,
        currentDeal,
        offers,
        engineGearboxes,
        interiorImages,
        interiorColorSlugs,
        exteriorColorSlugs,
        getMotorization,
        getCarConfigurations,
        getExteriorColor,
        getInteriorColor,
        dealDealerLayerEnabled,
        ecoBonus,
        isValidForEcoBonus,
        promoCodeInDeal,
        defaultConfiguration,
    };
};
