import { createActionTypes } from '../createActionTypes';
import {
    IAction,
    NETWORK_STATUS_START,
    NETWORK_STATUS_ERROR,
    NETWORK_STATUS_DONE,
    IResponseError,
} from '../redux.interface';
import { IduckState, IDealerDuck, IDealerDTO } from './dealer.duck.interface';
import { IDealer } from '../../interfaces/Dealer';
import { Dispatch } from 'redux';
import { dealerApi } from '../../services/dealer/dealer.service';
import { DefaultRootState } from 'react-redux';

const DealerDuck: any = {
    name: 'Dealer',
};
const initialState: IduckState = {
    dealer: {
        externalId: '',
        city: '',
        distance: null,
        name: '',
        postCode: '',
        streetName: '',
    },
    dealers: [],
};

const actionTypes: any = createActionTypes(
    {
        set_dealer: 'set_dealer',
        ACTION_FETCH_DEALER_TOLIST_START: 'ACTION_FETCH_DEALER_SINGLE_START',
        ACTION_FETCH_DEALER_TOLIST_FAILED: 'ACTION_FETCH_DEALER_SINGLE_FAILED',
        ACTION_FETCH_DEALER_TOLIST_DONE: 'ACTION_FETCH_DEALER_SINGLE_DONE',
    },
    DealerDuck.name
);
DealerDuck.reducer = (state: IduckState = initialState, action: IAction): IduckState => {
    switch (action.type) {
        case actionTypes.set_dealer:
            return {
                ...state,
                dealer: action.payload,
            };
        /*********************************** DEALERS REDUCERS ***************************************/
        case actionTypes.ACTION_FETCH_DEALER_TOLIST_START: {
            const reduxDealers: IDealerDTO[] = state.dealers.filter(
                (dealer: IDealerDTO) => dealer.id !== action.payload.id
            );
            const newDealer: IDealerDTO = {
                status: NETWORK_STATUS_START,
                error: null,
                dealer: null,
                id: action.payload.id,
            };
            return {
                ...state,
                dealers: [...reduxDealers, newDealer],
            };
        }
        case actionTypes.ACTION_FETCH_DEALER_TOLIST_DONE: {
            const reduxDealers: IDealerDTO[] = state.dealers.filter(
                (dealer: IDealerDTO) => dealer.id !== action.payload.id
            );
            const newDealer: IDealerDTO = {
                status: NETWORK_STATUS_DONE,
                error: null,
                dealer: action.payload.dealer,
                id: action.payload.id,
            };
            return {
                ...state,
                dealers: [...reduxDealers, newDealer],
            };
        }
        case actionTypes.ACTION_FETCH_DEALER_TOLIST_FAILED: {
            const reduxDealers: IDealerDTO[] = state.dealers.filter(
                (dealer: IDealerDTO) => dealer.id !== action.payload.id
            );
            const newDealer: IDealerDTO = {
                status: NETWORK_STATUS_ERROR,
                error: action.payload.error,
                dealer: null,
                id: action.payload.id,
            };
            return {
                ...state,
                dealers: [...reduxDealers, newDealer],
            };
        }
        default:
            return state;
    }
};
DealerDuck.setDealer = (dealer: IDealer): IAction => ({
    type: actionTypes.set_dealer,
    payload: dealer,
});
DealerDuck.getOwnState = (state: any): IduckState => state[DealerDuck.name] || initialState;

/*********************************** DEALERS ACTIONS ***************************************/
const actionFetchDealerToListStart = (id: string) => {
    return {
        type: actionTypes.ACTION_FETCH_DEALER_TOLIST_START,
        payload: { id },
    };
};

const actionFetchDealerToListDone = (id: string, dealer: IDealer) => {
    return {
        type: actionTypes.ACTION_FETCH_DEALER_TOLIST_DONE,
        payload: { id, dealer },
    };
};

const actionFetchDealerToListFailed = (id: string, error: IResponseError) => {
    return {
        type: actionTypes.ACTION_FETCH_DEALER_TOLIST_FAILED,
        payload: { id, error },
    };
};

DealerDuck.fetchDealerToList = (id: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(actionFetchDealerToListStart(id));
        try {
            const dealerResponse = await dealerApi.getDealer(id);
            dispatch(actionFetchDealerToListDone(id, dealerResponse.data));
        } catch (err: any) {
            dispatch(
                actionFetchDealerToListFailed(id, {
                    code: err?.response?.status,
                    data: err?.response?.data,
                    err,
                })
            );
        }
    };
};

DealerDuck.getDealerById = (state: DefaultRootState, dealerId: string) => {
    return DealerDuck.getOwnState(state).dealers.find(({ id }: IDealerDTO) => id === dealerId);
};
DealerDuck.getDealer = (state: DefaultRootState) => {
    return DealerDuck.getOwnState(state).dealer;
};

export default DealerDuck as IDealerDuck;
