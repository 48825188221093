import { IBreadcrumbItem } from '../redux/commonDucks/ui.duck.interface';
import get from 'lodash/get';

export const rebuildBreadcrumbs = (breadcrumbs: IBreadcrumbItem[]): IBreadcrumbItem[] => {
    let prevUrl: string = '';
    return breadcrumbs.reduce((res: IBreadcrumbItem[], breadcrumb: IBreadcrumbItem, idx: number) => {
        let newUrl: string = prevUrl + `/${get(breadcrumb, `originalUrl`, '')}`;
        if (newUrl[0] === '/') {
            newUrl = newUrl.slice(1);
        }
        prevUrl = newUrl;
        return [
            ...res,
            {
                ...breadcrumb,
                url: `/${newUrl}`,
                idx,
            },
        ];
    }, []);
};
