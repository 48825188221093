import { MARKET } from 'src/constants/main';
import NextI18Next from '../../i18n';

/**
 *
 * @param language  I18n.language
 *
 * extend function if you need long string for specific localizations
 *
 *
 */

type Market = 'FR' | 'PT' | 'ES' | 'IT' | 'BE';
type LanguageLongString = string; // 'fr-FR' | 'pt-PT' | 'es-ES' | 'it-IT' | 'fr-BE' | 'nl-BE';

export const getLanguageLongString = (language: string): LanguageLongString => {
    return `${language}-${MARKET.toUpperCase() as Market}`;
};

export const getLanguage = (): string => {
    return NextI18Next.i18n.language || NextI18Next.i18n.options.fallbackLng.toString();
};
