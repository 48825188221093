import React, { FunctionComponent, useEffect, useState } from 'react';
import ContentService from '../services/content/content.service';
import Maintenance from '@components/Maintenance';
import { setLastUpdateSetting } from '@utils/Session.utils';
import { checkLastSaveSettings } from '@utils/page.utils';
import useTranslations from '@hooks/useTranslations';
import { useDispatch, useSelector } from 'react-redux';
import { ContentDuck } from '../redux/content/content.duck';
import { Redux } from 'src/redux/redux.interface';

export interface IMaintenanceProps {
    page: string;
}

export const MaintenanceContext = React.createContext({});

const KEY = 'maintenance_page_enabled';

const MaintenanceProvider: FunctionComponent<IMaintenanceProps> = (props) => {
    const { children, page } = props;
    const [loading, setLoading] = useState(false);
    const { i18n } = useTranslations();
    const dispatch = useDispatch();
    const content = useSelector((state: Redux) => ContentDuck.getContent(state));
    const settings = useSelector((state: Redux) => ContentDuck.getSettings(state));

    const getSettings = async () => {
        if (checkLastSaveSettings().isExpired) {
            setLoading(true);
            const data = await ContentService.getSettings();
            if (data) {
                dispatch(ContentDuck.setContent({ ...content, settings: data }, i18n.language, i18n));
                setLastUpdateSetting();
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        getSettings();
    }, [page]);

    return (
        <MaintenanceContext.Provider value={{ loading, settings }}>
            {settings.find((setting) => setting.key === KEY)?.value ? <Maintenance /> : children}
        </MaintenanceContext.Provider>
    );
};

export default MaintenanceProvider;
