import {Icons} from '..';
import calendar from './icons/calendar.svg';
import car from './icons/car.svg';
import carsStore from './icons/carsStore.svg';
import settings from './icons/settings.svg';
import securePayment from './icons/securePayment.svg';
import engine from './icons/engine.svg';
import engineSimple from './icons/engineSimple.svg';
import offreMoment from './icons/offreMoment.svg';
import exterior from './icons/exterior.svg';
import interior from './icons/interior.svg';
import clock from './icons/clock.svg';
import extras from './icons/extras.svg';
import correct from './icons/correct.svg';
import logo from './icons/logo.svg';
import logoMobile from './icons/logoMobile.svg';
import info from './icons/info.svg';
import phone from './icons/phone.svg';
import basket from './icons/basket.svg';
import account from './icons/account.svg';
import logoSmall from './icons/logoSmall.svg';
import mail from './icons/mail.svg';
import emailTypeTwo from './icons/emailTypeTwo.svg';
import edit from './icons/edit.svg';
import iphone from './icons/iphone.svg';
import iphoneTypeTwo from './icons/iphoneTypeTwo.svg';
import mapPointTypeTwo from './icons/mapPointTypeTwo.svg';
import information from './icons/information.svg';
import iconClockSolid from './icons/IconClockSolid.svg';
import DashIcon from './icons/dash.svg';
import Dealer from './icons/dealer.svg';
import PlusIcon from './icons/plus.svg';
import PoiCommercialBuilding from './icons/poiCommercialBuilding.svg';

export const iconAC = (name: Icons) => {
    switch (name) {
        case Icons.Calendar:
            return calendar;
        case Icons.Car:
            return car;
        case Icons.edit:
            return edit;
        case Icons.ClockSolid:
            return iconClockSolid;
        case Icons.SecurePayment:
            return securePayment;
        case Icons.CarStore:
            return carsStore;
        case Icons.Dealer:
            return Dealer;
        case Icons.Settings:
            return settings;
        case Icons.Correct:
            return correct;
        case Icons.Engine:
            return engine;
        case Icons.EngineSimple:
            return engineSimple;
        case Icons.OffreMoment:
            return offreMoment;
        case Icons.Exterior:
            return exterior;
        case Icons.Interior:
            return interior;
        case Icons.Clock:
            return clock;
        case Icons.Information:
            return information;
        case Icons.Extras:
            return extras;
        case Icons.Logo:
            return logo;
        case Icons.LogoMobile:
            return logoMobile;
        case Icons.Info:
            return info;
        case Icons.Phone:
            return phone;
        case Icons.Basket:
            return basket;
        case Icons.Account:
            return account;
        case Icons.LogoSmall:
            return logoSmall;
        case Icons.Mail:
            return mail;
        case Icons.EmailTypeTwo:
            return emailTypeTwo;
        case Icons.Iphone:
            return iphone;
        case Icons.IphoneTypeTwo:
            return iphoneTypeTwo;
        case Icons.MapPointTypeTwo:
            return mapPointTypeTwo;
        case Icons.Dash:
            return DashIcon;
        case Icons.PoiCommercialBuilding:
            return PoiCommercialBuilding;
        case Icons.PlusIcon:
            return PlusIcon;
        default:
            return '';
    }
};
