import { IAction } from '../redux.interface';
import { createActionTypes } from '../createActionTypes';
import { IConfiguratorDuck, IConfiguratorState, LastPartSelected } from './configurator.duck.interface';
import { IFeature } from '../../services/features/featureList.types';
import { IPxValuation } from '../../partExchange/interfaces/PxWidget';
import { GearboxSpecKeys, ICarPart } from '../../interfaces/Car';

const name = 'Configurator';

const initialState: IConfiguratorState = {
    exteriorColors: [],
    interiorColors: [],
    motorizations: [],
    options: [],
    exteriorColor: null,
    interiorColor: null,
    motorization: null,
    selectedOptions: [],
    availableOptions: [],
    externalId: undefined,
    pxState: undefined,
    gearbox: null,
    energies: [],
    lastPartSelected: LastPartSelected.FULL_CONFIGURATION,
};

const actionTypes: any = createActionTypes(
    {
        set_exterior_color: 'set_exterior_color',
        set_interior_color: 'set_interior_color',
        set_motorization: 'set_motorization',
        set_exterior_colors: 'set_exterior_colors',
        set_interior_colors: 'set_interior_colors',
        set_motorizations: 'set_motorizations',
        set_energies: 'set_energies',
        set_motorization_and_gearbox: 'set_motorization_and_gearbox',
        set_options: 'set_options',
        set_selected_options: 'set_selected_options',
        set_available_options: 'set_available_options',
        set_external_id: 'set_external_id',
        set_px_state: 'set_px_state',
        set_initial_configurator: 'set_initial_configurator',
    },
    name
);

class Configurator implements IConfiguratorDuck {
    name = name;

    reducer(state: IConfiguratorState = initialState, action: IAction) {
        switch (action.type) {
            case actionTypes.set_initial_configurator:
                return initialState;
            case actionTypes.toggle_option:
                return {
                    ...state,
                    options: action.payload,
                    lastPartSelected: LastPartSelected.OPTIONS,
                };
            case actionTypes.set_exterior_color:
                return {
                    ...state,
                    exteriorColor: action.payload.body,
                    lastPartSelected: LastPartSelected.EXTERIOR_COLOR,
                };
            case actionTypes.set_interior_color:
                return {
                    ...state,
                    interiorColor: action.payload,
                    lastPartSelected: LastPartSelected.INTERIOR_COLOR,
                };
            case actionTypes.set_motorization:
                return {
                    ...state,
                    motorization: { ...action.payload },
                    lastPartSelected: LastPartSelected.MOTORIZATION,
                };
            case actionTypes.set_motorization_and_gearbox:
                return {
                    ...state,
                    motorization: action.payload.motorization,
                    gearbox: action.payload.gearbox,
                    lastPartSelected: LastPartSelected.MOTORIZATION,
                };
            case actionTypes.set_exterior_colors:
                return {
                    ...state,
                    exteriorColors: [...action.payload],
                };
            case actionTypes.set_interior_colors:
                return {
                    ...state,
                    interiorColors: [...action.payload],
                };
            case actionTypes.set_motorizations:
                return {
                    ...state,
                    motorizations: [...action.payload],
                };
            case actionTypes.set_energies:
                return {
                    ...state,
                    energies: [...action.payload],
                };
            case actionTypes.set_options:
                return {
                    ...state,
                    options: [...action.payload],
                };
            case actionTypes.set_selected_options:
                return {
                    ...state,
                    selectedOptions: [...action.payload],
                    lastPartSelected: LastPartSelected.OPTIONS,
                };
            case actionTypes.set_available_options:
                return {
                    ...state,
                    availableOptions: [...action.payload],
                };
            case actionTypes.set_external_id:
                return {
                    ...state,
                    externalId: action.payload,
                };
            case actionTypes.set_px_state:
                return {
                    ...state,
                    pxState: action.payload,
                };
            default:
                return state;
        }
    }

    getOwnState(state: any): IConfiguratorState {
        return state[this.name] || initialState;
    }

    resetConfigurator(): IAction {
        return {
            type: actionTypes.set_initial_configurator,
        };
    }

    setExteriorColor(color: IFeature): any {
        return (dispatch: any) => {
            dispatch({
                type: actionTypes.set_exterior_color,
                payload: {
                    body: color,
                },
            });
        };
    }

    setInteriorColor(color: IFeature): IAction {
        return {
            type: actionTypes.set_interior_color,
            payload: color,
        };
    }

    setMotorization(motorization: IFeature): IAction {
        return {
            type: actionTypes.set_motorization,
            payload: motorization,
        };
    }

    setMotorizationAndGearbox(motorization: IFeature, gearbox: ICarPart<GearboxSpecKeys>): IAction {
        return {
            type: actionTypes.set_motorization_and_gearbox,
            payload: { motorization, gearbox },
        };
    }

    setExteriorColors(colors: IFeature[]): IAction {
        return {
            type: actionTypes.set_exterior_colors,
            payload: colors,
        };
    }

    setInteriorColors(colors: IFeature[]): IAction {
        return {
            type: actionTypes.set_interior_colors,
            payload: colors,
        };
    }

    setMotorizations(motorizations: IFeature[]): IAction {
        return {
            type: actionTypes.set_motorizations,
            payload: motorizations,
        };
    }

    setAvailableEnergies(energies: any[]): IAction {
        return {
            type: actionTypes.set_energies,
            payload: energies,
        };
    }

    setOptions(options: any[]): IAction {
        return {
            type: actionTypes.set_options,
            payload: options,
        };
    }

    setSelectedOptions(options: any[]): IAction {
        return {
            type: actionTypes.set_selected_options,
            payload: options,
        };
    }

    setAvailableOptions(options: any[]): IAction {
        return {
            type: actionTypes.set_available_options,
            payload: options,
        };
    }

    setExternalId(externalId: string): IAction {
        return {
            type: actionTypes.set_external_id,
            payload: externalId,
        };
    }

    setPxState(payload: IPxValuation | undefined): IAction {
        return {
            type: actionTypes.set_px_state,
            payload,
        };
    }
}
export const ConfiguratorDuck = new Configurator();
export default ConfiguratorDuck;
