export const OPEN_MODAL: string = 'OPEN_MODAL';
export const UPDATE_WIDGET_DISPLAY: string = 'UPDATE_WIDGET_DISPLAY';
export const SET_WIDGET_CONFIG: string = 'SET_WIDGET_CONFIG';
export const SET_PART_EXCHANGE_DATA: string = 'SET_PART_EXCHANGE_DATA';
export const SET_WIDGET_TEMPLATE: string = 'SET_WIDGET_TEMPLATE';
export const PX_ERROR: string = 'PX_ERROR';
export const PX_THROW_ERROR: string = 'PX_THROW_ERROR';
export const REMOVE_SIMPLE_VALUATION: string = 'REMOVE_SIMPLE_VALUATION';
export const SET_GLOBAL_LOADING: string = 'SET_GLOBAL_LOADING';
export const SET_CONFIG: string = 'SET_CONFIG';
export const OPEN_ESTIMATE_MODAL: string = 'OPEN_ESTIMATE_MODAL';
export const OPEN_ENGAGEMENT_MODAL: string = 'OPEN_ENGAGEMENT_MODAL';
export const OPEN_PHOTO_MODAL: string = 'OPEN_PHOTO_MODAL';
export const SET_MODAL_BUTTON_ENABLED: string = 'SET_MODAL_BUTTON_ENABLED';
