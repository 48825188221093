import { IThemeInterface } from '../theme';
import { rem } from 'polished';
import { RecursivePartial } from '../../interfaces/RecursivePartial';

export const breakpoints = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
};

const themeAP: RecursivePartial<IThemeInterface> = {
    fonts: {
        fontBase: 'PeugeotNew-Regular',
        fontEmphasized: 'Peugeot-Italic',
        fontSecondary: 'Helvetica Neue',
    },
    fontSizes: {
        textBig: rem('20px'),
        textSupMedium: rem('19px'),
        textMedium: rem('16px'),
        textSupBase: rem('14px'),
        textBase: rem('12px'),
        textSmall: rem('11px'),
        textTiny: rem('10px'),
        h1: rem('70px'),
        h2: rem('32px'),
        h3: rem('36px'),
        h4: rem('24px'),
        h5: rem('18px'),
        h6: rem('12px'),
        h7: rem('10px'),
    },
    lineHeights: {
        textSmall: rem('14px'),
        textBig: rem('28px'),
        textSupBig: rem('24px'),
        textSupMedium: rem('26px'),
        textMedium: rem('18px'),
        h5: rem('20px'),
    },
    colors: {
        primary: '#0074E8',
        primaryLight: '#E5F1FD',
        primaryBlack: '#000000',
        primaryHover: '#000000',
        primaryDisabled: '#B4B4B4',
        default: '#000000',
        secondary: '#787B80',
        secondaryHover: '#152946',
        secondaryDisabled: '#B4B4B4',
        trimPageModelNameBackground: '#f1f3f2',
        lozenge: '#00A3E0',
        lozengeArrow: '#4E0230',
        accessibilityOutlinecolor: '#2F80ED',

        grey1: '#3C3947',
        grey2: '#848484',
        grey3: '#E5E5E5',
        grey4: '#cdcdcd',
        grey5: '#666666',
        grey6: '#818181',
        grey7: '#ECECEE',
        grey8: '#F5F5F5',
        grey9: '#F1F3F2',
        grey10: '#999999',
        grey11: '#68666F',
        grey12: '#ADAEB2',
        grey13: '#787B80',
        grey14: '#F7F7F7',
        grey15: '#E7E7EA',
        grey16: '#ADADAD',
        grey17: '#6E7175',
        danger: '#FF0909',
        success: '#39B54A',
        info: '#007EDB',
        warning: '#EDA207',
        white: '#ffffff',
        blue1: '#152032',
        blue2: '#162233',
        blue6: '#012642',
        blue7: '#142032',
        blue8: '#007bff',
        blue9: '#4CC9F9',
    },
};
export default themeAP;
