import { Redux } from 'src/redux/redux.interface';
import GlobalDuck from '../redux/global/global.duck';
import { useSelector } from 'react-redux';

import { CarJourneyType } from '../services/carList/carList.types';
import { useFeatureSwitchEnabled } from './useFeatureSwitchEnabled';
import { FEATURES_LIST } from '../context/featureSwitchApp';
import {useGlobalDuck} from "@hooks/useGlobalDuck";

export const useCarJourney = (): CarJourneyType => {
    const { carJourney } = useGlobalDuck();

    return carJourney;
};

/**
 * @description no param hook that return object {isStockJourney:boolean} don't forget to use function only on top level
 * @example const {isStockJourney} = useCarStockJourneyCheck()
 */
export const useCarStockJourneyCheck = (): { isStockJourney: boolean } => {
    const carJourney = useCarJourney();
    const isStockJourneyAllowed = useFeatureSwitchEnabled(
        FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS,
        CarJourneyType.STOCK
    );
    const isStockJourney = isStockJourneyAllowed && carJourney === CarJourneyType.STOCK;

    return { isStockJourney };
};
