import React, { FC } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SC } from '../../styles/theme';
import Icon, { Icons } from '../Icon';
import UIDuck from '../../redux/commonDucks/ui.duck';
import { IduckState, IAlert } from '../../redux/commonDucks/ui.duck.interface';

interface AlertProps extends SC {
    alerts: IAlert[];
    dispatch: Dispatch;
}

const AlertTemplate: FC<AlertProps> = ({ className, dispatch, alerts }) => (
    <div className={className}>
        {alerts.length > 0 &&
            alerts.map(alert => (
                <div
                    key={alert.id}
                    className={`alert alert-${alert.type.toLowerCase()} alert-${
                        alert.fixedOnTop ? 'fixed' : 'absolute'
                    }`}
                >
                    <div className="content">{alert.content}</div>
                    <Icon name={Icons.Plus} onClick={() => dispatch(UIDuck.removeAlert(alert.id))} />
                </div>
            ))}
    </div>
);

const mapStateToProps = (state: IduckState) => ({
    alerts: UIDuck.getAlerts(state),
});

export default connect(mapStateToProps)(AlertTemplate);
