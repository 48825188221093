export const isStorybook = (windowObject: Window = undefined) => {
    const w = windowObject || (typeof window !== 'undefined' && window);
    // @ts-ignore
    return typeof w?.STORYBOOK_ENV !== 'undefined';
};

export const isBrowser = (pr: any = undefined) => {
    const p = pr || process;
    return p?.isBrowser;
};

export const isTest = () => process.env.NODE_ENV === 'test';
