import { ThunkAction } from 'redux-thunk';
import { IduckState as IUIState } from './commonDucks/ui.duck.interface';
import { IFilterState } from './filters/filter.duck.interface';
import { IduckState as IOfferListState } from './carList/offerlist.duck.interface';
import { IContentState } from './content/content.duck.interface';
import { IConfiguratorState } from './configurator/configurator.duck.interface';
import { IduckState as ITrimSelectorState } from './trimSelector/trimSelector.duck.interface';
import { IduckState as IUserState } from './user/user.duck.interface';
import { IduckState as ICarDetailsState } from './carDetails/carDetails.duck.interface';
import { IFinanceWidgetDuckState } from './financeWidget/financeWidget.duck.interface';
import { IGlobalState as IGlobalDuckState } from './global/global.duck.interface';
import { IDealerDuck } from './dealer/dealer.duck.interface';

export interface IAction<Payload = any, Opt = any> {
    type: string;
    payload?: Payload;
    opt?: Opt;
}

export type ThunkResult<R> = ThunkAction<R, any, undefined, any>;

export interface Redux {
    UI: IUIState;
    Filters: IFilterState;
    OfferList: IOfferListState;
    Content: IContentState;
    Configurator: IConfiguratorState;
    TrimSelector: ITrimSelectorState;
    User: IUserState;
    CarDetails: ICarDetailsState;
    FinanceWidget: IFinanceWidgetDuckState;
    Global: IGlobalDuckState;
    Dealer: IDealerDuck;
}

export type IGlobalState = Redux;

export type IgetGlobalState = () => IGlobalState;

export const NETWORK_STATUS_START = 'START';
export const NETWORK_STATUS_ERROR = 'ERROR';
export const NETWORK_STATUS_DONE = 'DONE';
export type INetworkStatus = 'START' | 'ERROR' | 'DONE';
export interface IResponseError {
    code: number;
    data: any;
    err: any;
}
