import React, { FunctionComponent } from 'react';
import Cookies from 'js-cookie';
import { decodeFromBase64 } from '@utils/misc.utils';
import { CarJourneyType } from '../services';
import { FEATURE_SWITCHES_COOKIE_NAME } from '../constants/main';
import { PaymentJourneyTypes } from '../partExchange/interfaces/Default';
import { BANK_CHECKOUT_JOURNEY_TYPES, CONTACT_OPTIONS } from '../constants/enums';
import { IExperimentalFeatureFlagContext } from '@utils/experiments/@types';
import {IFeatureSwitchContext} from "./featureSwitchApp";

export interface IFeatureSwitchProps {
    features: IExperimentalFeatureFlagContext;
}

export const FeatureSwitchContext = React.createContext<IExperimentalFeatureFlagContext>({});

export const FeatureSwitchForTesting: FunctionComponent<IFeatureSwitchProps> = (props) => {
    const { features, children } = props;

    return <FeatureSwitchContext.Provider value={features}>{children}</FeatureSwitchContext.Provider>;
};

export default FeatureSwitchForTesting;
