import { carExtraOptionsProvider } from '../core/sdk/providers';
import { IOptionsParameters } from './optionsList.types';
import { getOptionsInput } from './optionsInput';
import { formatOptionsResponse } from './optionsUtils';
import { TBudgetType } from '../core/sdk/constants/sdkFields';
import { isBrandAP } from '../../constants/main';

// https://jira.summit.co.uk/browse/APP-6434
const restrictedOptions = isBrandAP && ['GMAQ'];

export const getOptionsList = async (
    configurationId: string,
    optionIds: string[] = [],
    paymentJourneyType: TBudgetType,
    hash?: string
) => {
    const parameters: IOptionsParameters = { configurationId, optionIds, paymentJourneyType, hash };
    try {
        console.log('%c FETCH getOptionsList', 'background: blue; color: white;');
        const input = getOptionsInput(parameters);
        const response = await carExtraOptionsProvider.getInputResults(input);

        const opts = formatOptionsResponse(response).items;
        // https://jira.summit.co.uk/browse/APP-6434
        const allowedOpts =
            opts?.options.filter((o: any) => !restrictedOptions || !restrictedOptions.includes(o.id)) || [];
        const data = { ...opts, options: allowedOpts };

        return { data, statusCode: response._statusCode };
    } catch (e: any) {
        console.log('EE__', e);
        return { data: { options: [], externalId: '', incompatibleOptionIds: [], images: [] }, statusCode: e?.statusCode };
    }
};
