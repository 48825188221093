import i18n from 'i18next';
import { MM_HEADERS } from '../../constants/main';

export const getDefaultHeaders = (): Record<string, string> => {
    const headers: Record<string, string | undefined | null> = {
        ...MM_HEADERS,
        'x-language': i18n.language || process.env.NEXT_PUBLIC_LANGUAGE,
    };
    const filteredHeaders: Record<string, string> = {};
    for (const [key, value] of Object.entries(headers)) {
        if (value) {
            filteredHeaders[key] = value;
        }
    }

    return filteredHeaders;
};
