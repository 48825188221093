import axios from 'axios';
import { TBudgetType } from '../../interfaces/IFilters';
import { carDetailProvider } from '../core/sdk/providers';
import { ICarDetailsParameters } from './carDetails.types';
import { getCurrentCarDetailsInput } from './carDetailsInput';
import { BACKEND_URL, IS_DEV } from '../../constants/main';
import { getDefaultHeaders } from '../apiLayer/apiLayer.utils';

/*
 * service example
 */
export class CarDetailsService {
    /**
     * Format response and return only required data
     * @param serverResponse
     */
    public static formatCarDetailsResponse = (serverResponse: any) => {
        const carDetail = serverResponse._data._details.get('detailsOptionsAggregated')._items;
        return {
            statusCode: serverResponse._statusCode,
            data: carDetail,
        };
    };

    /**
     * Get car details
     * @returns Promise
     */
    public static getCarDetails = async (
        paymentJourneyType: TBudgetType,
        externalId: string,
        hash?: string,
        debug: string = ''
    ) => {
        const parameters: ICarDetailsParameters = {
            paymentJourneyType,
            externalId,
            hash,
        };
        try {
            const input = getCurrentCarDetailsInput(parameters);

            if (IS_DEV) {
                console.log('%cFETCH DETAILS #1', 'background: rgba(0,0,255);color:white;');
                console.log(`%cdebug: ${debug}`, 'background: orange;');
                console.log('FETCH DETAILS input', input);
            }

            const response = await carDetailProvider.getInputResults(input);

            const data = CarDetailsService.formatCarDetailsResponse(response).data[0]
                ? CarDetailsService.formatCarDetailsResponse(response).data[0]._items._items[0]
                : {};

            return { data, statusCode: response?._statusCode };
        } catch (e: any) {
            console.log('EE__', e);
            return { data: [], statusCode: e?.statusCode };
        }
    };

    /**
     * Get car details
     * @returns Promise
     */
    public static getCarDetailsWithAllOptions = async (
        nameplateBodyStyleSlug: string,
        trimSlug: string,
        gearboxEngineSlug: string,
        exteriorColourSlug: string,
        interiorColourSlug: string,
        options: string[] = [],
        includeTrimAttributes: boolean = false,
    ) => {
        try {
            return axios
                .request({
                    method: 'POST',
                    url: `${BACKEND_URL}/car-details`,
                    headers: { ...getDefaultHeaders() },
                    data: {
                        ...(nameplateBodyStyleSlug && { nameplateBodyStyleSlug }),
                        ...(trimSlug && { trimSlug }),
                        ...(gearboxEngineSlug && { gearboxEngineSlug }),
                        ...(exteriorColourSlug && { exteriorColourSlug }),
                        ...(interiorColourSlug && { interiorColourSlug }),
                        ...(options?.length > 0 && { options }),
                        includeTrimAttributes,
                    },
                })
                .then((response) => {
                    return response?.data;
                });
        } catch (e: any) {
            throw new Error(`Could not fetch car details ${nameplateBodyStyleSlug}`);
        }
    };

    /**
     * Get cars details
     * @returns Promise
     */
    public static getCarsDetailsWithAllOptions = async (nameplateBodyStyleSlug: string) => {
        try {
            return axios
                .request({
                    method: 'POST',
                    url: `${BACKEND_URL}/trim-details`,
                    headers: { ...getDefaultHeaders() },
                    data: {
                        nameplateBodyStyleSlug,
                        selectedVehicleFields: [
                            'pricesV2',
                            'nameplateBodyStyleSlug',
                            'specPackSlug',
                            'engineGearboxFuelSlug',
                            'interiorColourSlug',
                            'exteriorColourSlug',
                            'images',
                            'prices',
                            'offers',
                            'trimContent',
                            'extraFields',
                            'exteriorColourSlug',
                            'standardFeatures',
                            'trimContent',
                            'specPack',
                            'externalId',
                        ],
                    },
                })
                .then((response) => {
                    return response?.data;
                });
        } catch (e: any) {
            throw new Error(`Could not fetch car details ${nameplateBodyStyleSlug}`);
        }
    };

    /**
     * Get cars details
     * @returns Promise
     */
    public static getCarsDetailsWithAllOptionsV2 = async (nameplateBodyStyleSlug: string, filters?: any) => {
        try {
            return axios
                .request({
                    method: 'POST',
                    url: `${BACKEND_URL.replace('v1', 'v2')}/trims/aggregation`,
                    headers: { ...getDefaultHeaders() },
                    data: {
                        nameplateBodyStyleSlug,
                        ...filters,
                    },
                })
                .then((response) => {
                    return response?.data;
                });
        } catch (e: any) {
            console.error(`Could not fetch car details ${nameplateBodyStyleSlug}`);
        }
    };

    /**
     * Get car details
     * @returns Promise
     */
    public static getAllCarDetails = async (
        paymentJourneyType: TBudgetType,
        nameplateBodyStyleSlug: string,
        speckPackSlug: string,
        hash: string
    ) => {
        const parameters: ICarDetailsParameters = {
            paymentJourneyType,
            nameplateBodyStyleSlug,
            speckPackSlug,
            hash,
        };
        try {
            console.log('%c FETCH getAllCarDetails', 'background: blue; color: white;');
            const input = getCurrentCarDetailsInput(parameters);
            const response = await carDetailProvider.getInputResults(input);
            const data = CarDetailsService.formatCarDetailsResponse(response).data.map(
                (item: any) => item._items._items[0]
            );
            return { data, statusCode: response?._statusCode };
        } catch (e: any) {
            console.log('EE__', e);
            return { data: [], statusCode: e?.statusCode };
        }
    };
}

export default CarDetailsService;
