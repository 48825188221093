export enum CURRENCY {
    EUR = '€',
}

export enum B2B_FIELD {
    COMPANY_NAME = 'company_name',
    COMPANY_VAT = 'company_vat',
    COMPANY_NUMBER = 'company_number',
}

export enum LEGAL_CONSENTS_KEYS {
    TOUCH = '1',
    DEALS = '2',
    PARTNERS = '3',
}

export enum LEGAL_CONSENTS_VALUES {
    AGREE = 'true',
    NOT_AGREE = 'false',
}

export enum BANK_CHECKOUT_JOURNEY_TYPES {
    DIRECT = 'direct',
    ASSISTED = 'assisted',
}

export enum CONTACT_OPTIONS {
    PHONE_NUMBER = 'phone_number',
    LEAD_FORM = 'lead_form',
    TEST_DRIVE = 'test_drive',
    FAQ = 'faq',
}

export enum DEAL_ERROR_TYPES {
    EXPIRED = 'expiredDeal',
}

export enum INPUT_TYPES {
    NUMBER = 'number',
    STRING = 'string',
}

export enum CarouselViewType {
    EXTERIOR = 'Exterior',
    INTERIOR = 'Interior',
}

export enum ImageViewType {
    INTERIOR = 'Int',
    EXTERIOR = 'Ext',
}

// TODO: unit testing move rest of the enums from main.ts so we can mock constants without destroying enums
