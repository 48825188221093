import { TBudgetType } from '../../interfaces/IFilters';
import { CarJourneyType } from '../../services/carList/carList.types';

export interface IErrorMessages {
    [x: string]: string;
}

export interface IObjectKey {
    [key: string]: string | null;
}

export interface IObjectKeyString {
    [key: string]: string;
}

export interface IRouteParams {
    paymentJourneyType: TBudgetType;
    carJourneyType: CarJourneyType;
}

export interface IPriceExtras {
    employeeDiscount: string;
    extraDiscount: string;
}

export interface IMonthlyPrice {
    priceFormat: string;
    financeProductId: string;
    lengthOfTerm: number;
    deposit: number;
    apr: number;
    amount: number;
    mileage: number;
}

export interface IPrice {
    currency: string;
    priceFormat: string;
    type: string;
    basePrice: number;
    margin: string;
    extras: IPriceExtras;
    bonus: string;
    malus: string;
    vat: string;
    monthlyPrices: IMonthlyPrice[];
}

export type IPriceWithMonthlyPricesArray = IPrice;

export interface IPriceWithFormat {
    basePrice: number;
    priceFormat: string;
    currency: string;
    extras?: any;
}

export interface IPriceWithFormatAndMonthlyPrices extends IPriceWithFormat {
    monthlyPrices: IMonthlyPrice[];
}

export enum PaymentJourneyTypes {
    CASH = 'cash',
    FINANCE = 'finance',
}
