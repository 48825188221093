import * as styledComponents from 'styled-components';
import { default as themeAC, breakpoints as breakpointsAC } from './AC/theme';
import { default as themeAP, breakpoints as breakpointsAP } from './AP/theme';
import { default as themeDS, breakpoints as breakpointsDS } from './DS/theme';
import { default as themeOV, breakpoints as breakpointsOV } from './OV/theme';
import { default as themeOV_GB, breakpoints as breakpointsOV_GB } from './OV/GB/theme';
import { BRAND, BRAND_OV, isMarketGB, MARKET, MARKETS } from '../constants/main';
import { rem } from 'polished';
import merge from 'lodash/merge';
import { emValue } from './convert';

const {
    default: styled,
    createGlobalStyle,
    css,
    ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<any> as styledComponents.ThemedStyledComponentsModule<IThemeInterface>;

export const defaultBreakpoints = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
};

export interface IColors {
    white: string;
    pureWhite: string;
    black: string;
    default: string;
    defaultHover: string;
    primary: string;
    primaryLight: string;
    primaryHover: string;
    primaryDisabled: string;
    primaryBlack: string;
    secondary: string;
    secondaryHover: string;
    secondaryDisabled: string;
    success: string;
    danger: string;
    warning: string;
    info: string;
    lozenge: string;
    lozengeArrow: string;

    black1: string;
    black2: string;
    black3: string;
    black4: string;
    black5: string;
    black6: string;

    grey1: string;
    grey2: string;
    grey3: string;
    grey4: string;
    grey5: string;
    grey6: string;
    grey7: string;
    grey8: string;
    grey9: string;
    grey10: string;
    grey11: string;
    grey12: string;
    grey13: string;
    grey14: string;
    grey15: string;
    grey16: string;
    grey17: string;
    grey18: string;
    grey19: string;
    grey20: string;
    grey21: string;
    grey22: string;
    grey23: string;
    grey24: string;
    grey25: string;
    grey26: string;
    grey27: string;
    grey28: string;
    grey29: string;
    grey30: string;
    grey31: string;
    hotGrey: string;
    lighterGrey: string;

    blue: string;
    blue1: string;
    blue2: string;
    blue3: string;
    blue4: string;
    blue5: string;
    blue6: string;
    blue7: string;
    blue8: string;
    blue9: string;
    blue10: string;
    blue11: string;
    blue12: string;
    blue13: string;

    brown1: string;
    brown2: string;
    brown3: string;
    brown4: string;
    brown5: string;
    brown6: string;
    brown7: string;
    brown8: string;

    orange1: string;

    red: string;
    focus?: string;
    red1: string;
    red2: string;
    red3: string;
    red4: string;
    red5: string;
    red6: string;
    red7: string;

    pink1: string;

    champagne1: string;
    champagne2: string;
    champagne3: string;

    lilyWhite: string;
    coffee: string;
    coffeeSpilled: string;
    coffeeDisabled: string;
    cream: string;
    coffeeDark: string;
    coconut: string;
    milkshake: string;
    _3000ShadesOfGrey: string;
    sweetHoney: string;
    heavyMetal: string;
    polishedRock: string;
    theWhiteStripes: string;

    deepPurple: string;
    shadePurple: string;

    transparentBlack: string;
    transparentRed: string;
    transparentWhite: string;

    accordionSecondaryBg: string;
    accordionAnswer: string;

    headerBackground: string;

    trimPageModelNameBackground: string;

    myAccountImageButtonBackground: string;
    myAccountImageButtonDescriptionBackground: string;

    pxCalculatorBlue: string;
    accessibilityOutlinecolor: string;
}

export interface IThemeInterface {
    isMyAccount?: boolean;
    borderRadius: string;
    colors: IColors;
    fonts: {
        fontBase: string;
        fontEmphasized: string;
        fontSecondary: string;
        fontTertiary: string;
        fontV2: string;
        fontWeight?: string;
    };
    fontWeights: {
        textLight: number;
        textRegular: number;
        textMedium: number;
        textBold: number;
        textExtraBold: number;
    };
    fontSizes: {
        textBase: string;
        textSupBase: string;
        textSmall: string;
        textSubMedium: string;
        textMedium: string;
        textSupMedium: string;
        textBig: string;
        textTiny: string;

        h1: string;
        h2: string;
        h3: string;
        h4: string;
        h5: string;
        h6: string;
        h7: string;
        t28?: string;
    };
    lineHeights: {
        textBase: string;
        textSmall: string;
        textSubMedium: string;
        textMedium: string;
        textSupMedium: string;
        textSupBig: string;
        textBig: string;
        h1: string;
        h2: string;
        h3: string;
        h4: string;
        h5: string;
    };

    flexboxgrid: {
        breakpoints: {
            xs: number;
            sm: number;
            md: number;
            lg: number;
        };
        container?: {
            sm?: number;
            md?: number;
            lg?: number;
        };
        gutterWidth: number; // rem
        outerMargin: number; // rem
    };
}

export const breakpoints = (() => {
    if (BRAND === 'OV') {
        if (MARKET === MARKETS.GB) {
            return breakpointsOV_GB;
        }
        return breakpointsOV;
    } else if (BRAND === 'AP') return breakpointsAP;
    else if (BRAND === 'DS') return breakpointsDS;
    else if (BRAND === 'AC') return breakpointsAC;
    else return defaultBreakpoints;
})();

export const defaultTheme: IThemeInterface = {
    borderRadius: rem('8px'),
    colors: {
        white: '#FFF',
        pureWhite: '#FFF',
        black: '#000',
        default: '#2F2727',
        defaultHover: '#1D1717',
        primary: '#2F80ED',
        primaryLight: '#2F80ED',
        primaryHover: '#2F80ED',
        primaryDisabled: '#2F80ED',
        primaryBlack: '#000000',
        secondary: '#C7C7C7',
        secondaryHover: '#C7C7C7',
        secondaryDisabled: '#C7C7C7',
        success: '#32D172',
        danger: '#FF7474',
        warning: '#F2994A',
        info: '#5BA1FF',
        lozenge: '#97055E',
        lozengeArrow: '#4E0230',

        black1: '#1D1717',
        black2: '#3b3938',
        black3: '#1A1B1B',
        black4: '#303131',
        black5: '#14032',
        black6: '#1D1D1B',

        grey1: '#3C3947',
        grey2: '#666666',
        grey3: '#B4B4B4',
        grey4: '#CDCDCD',
        grey5: '#ECECEE',
        grey6: '#E6E0D8',
        grey7: '#F2F2F2',
        grey8: '#F1F3F2',
        grey9: '#ECECEC',
        grey10: '#818181',
        grey11: '#898f98',
        grey12: '#162133',
        grey13: '#796f6e',
        grey14: '#e9e7e1',
        grey15: '#a3a4a4',
        grey16: '#f0f0f0',
        grey17: '#A19A99',
        grey18: '#796F6E',
        grey19: '#a4a44d',
        grey20: '#e6e6e6',
        grey21: '#757C87',
        grey22: '#e4dfce',
        grey23: '#E5E5E5',
        grey24: '#1A1B1B',
        grey25: '#E3E3E3',
        grey26: '#bfbfbf',
        grey27: '#393b38',
        grey28: '#E4E2E2',
        grey29: '#767676',
        grey30: '#d0d0d0',
        grey31: '#757575',

        blue: '#143D5F',
        blue1: '#152032',
        blue2: '#152946',
        blue3: '#0062c5',
        blue4: '#007edb',
        blue5: '#A0D3E8',
        blue6: '#012642',
        blue7: '#142032',
        blue8: '#02A8EC',
        blue9: '#0F1826',
        blue10: '#e2f2f8',
        blue11: '#133e60',
        blue12: '#425695',
        blue13: '#337ab7',

        brown1: '#f8f7f3',
        brown2: '#e6e0dc',
        brown3: '#b48f6d',
        brown4: '#c4bab4',
        brown5: '#c4a38f',
        brown6: '#665a55',
        brown7: '#FFEBCD',
        brown8: '#c6b8ae',

        orange1: '#EB6428',

        red: '#FF0000',
        red1: '#E1412D',
        red2: '#870031',
        red3: '#ad0040',
        red4: '#C90C0F',
        red5: '#9d0605',
        red6: '#f4e4e4',
        red7: '#fbe8e8',

        pink1: '#bf7892',

        champagne1: '#655A54',
        champagne2: '#AD9D91',
        champagne3: '#E6E0DB',

        lilyWhite: '#f1f3f2',
        coffee: '#9d8c83',
        coffeeSpilled: '#876a5b',
        coffeeDisabled: '#D2C7BE',
        cream: '#F1EFED',
        coffeeDark: '#434444',
        coconut: '#F1EFEF',
        milkshake: '#CAC6C5',
        lighterGrey: 'rgba(20, 32, 50, 0.5)',
        hotGrey: 'rgba(161, 154, 153, 0.1)',
        _3000ShadesOfGrey: '#F2F2F2',
        sweetHoney: 'rgba(237, 162, 7, 0.1)',
        heavyMetal: '#7F7F7F',
        polishedRock: '#e9e8e8',
        theWhiteStripes: '#F2F2F2',

        deepPurple: '#d90988',
        shadePurple: '#c2087a',

        transparentBlack: 'rgba(0,0,0,0.5)',
        transparentRed: 'rgba(255,0,0,0.5)',
        transparentWhite: 'rgba(255,255,255,0.4)',

        accordionSecondaryBg: '#f5f5f5',
        accordionAnswer: '#1d1717',

        headerBackground: '#1d1717',

        trimPageModelNameBackground: '#ffffff',

        myAccountImageButtonBackground: '#fcfcfc',
        myAccountImageButtonDescriptionBackground: '#1D1D1B',

        pxCalculatorBlue: '#152A46',
        accessibilityOutlinecolor: '#2F80ED',
    },
    fonts: {
        fontBase: 'Sans-serif',
        fontEmphasized: 'Sans-serif',
        fontSecondary: 'Helvetica Neue',
        fontTertiary: 'Helvetica Neue',
        fontV2: 'PeugeotNew-Bold',
    },
    fontWeights: {
        textLight: 300,
        textRegular: 400,
        textMedium: 500,
        textBold: 700,
        textExtraBold: 800,
    },
    fontSizes: {
        textTiny: rem('10px'),
        textSmall: rem('11px'),
        textBase: rem('14px'),
        textSupBase: rem('12px'),
        textSubMedium: rem('15px'),
        textMedium: rem('16px'),
        textSupMedium: rem('18px'),
        textBig: rem('22px'),
        h1: rem('70px'),
        h2: rem('48px'),
        h3: rem('36px'),
        h4: rem('24px'),
        h5: rem('20px'),
        h6: rem('12px'),
        h7: rem('10px'),
    },
    lineHeights: {
        textBase: rem('14px'),
        textSmall: rem('11px'),
        textSubMedium: rem('15px'),
        textSupMedium: rem('18px'),
        textMedium: rem('16px'),
        textBig: rem('22px'),
        textSupBig: rem('24px'),
        h1: rem('72px'),
        h2: rem('50px'),
        h3: rem('28px'),
        h4: rem('26px'),
        h5: rem('22px'),
    },
    flexboxgrid: {
        breakpoints: {
            xs: emValue(breakpoints.xs),
            sm: emValue(breakpoints.sm),
            md: emValue(breakpoints.md),
            lg: emValue(breakpoints.lg),
        },
        gutterWidth: 1, // rem
        outerMargin: 4, // rem
    },
};

export const theme = (() => {
    if (BRAND === BRAND_OV) {
        if (isMarketGB) {
            return merge(defaultTheme, themeOV_GB);
        }
        return merge(defaultTheme, themeOV);
    } else if (BRAND === 'AC') return merge(defaultTheme, themeAC);
    else if (BRAND === 'AP') return merge(defaultTheme, themeAP);
    else if (BRAND === 'DS') return merge(defaultTheme, themeDS);
    else return defaultTheme;
})();

export interface SC {
    className?: string;
    children?: any;
    style?: any;
    isUserLoggedInAsDealer?: boolean;
}

export default styled;
export { css, createGlobalStyle, ThemeProvider };
