import { Dispatch } from 'react';
import { IDispatchValue } from '../interfaces/IStore';
import { IWidgetState } from '../interfaces/PxWidget';
import { createPartExchange, getWidgetParams, updatePartExchange } from '../service/ApiService';
import { captureException } from '@utils/sentry.utils';
import { setLoading, throwGlobalError } from '../store/actions';
import UserDuck from '../../redux/user/user.duck';
import {PxVersion} from "../../constants/main";

/**
 * Watch for valuation changes and create new part exchange, then get widget params again
 * @param dispatch
 * @param state
 * @param pxId
 * @param mopId
 * @param clearSession
 */
export function useUpdateApiPX(
    dispatch: Dispatch<IDispatchValue<any>>,
    state: Partial<IWidgetState>,
    pxId?: string,
    mopId?: string,
    clearSession?: boolean,
    pxVersion?: PxVersion,
): void {
    setLoading(dispatch, true);

    if (!state.simpleValuation?.statusId) {
        createPartExchange(state, clearSession)
            .then(() => getWidgetParams(dispatch, state, pxId, mopId, null, pxVersion).then(() => setLoading(dispatch, false)))
            .catch(e => {
                captureException(e);
                throwGlobalError(dispatch, e);
            });
    } else {
        updatePartExchange(state)
            .then(() => {
                getWidgetParams(dispatch, state, pxId, mopId, true, pxVersion).then(() => setLoading(dispatch, false));
            })
            .catch(e => {
                captureException(e);
                throwGlobalError(dispatch, e);
            });
    }
}
