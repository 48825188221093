import { format, Locale, parseISO } from 'date-fns';
import { enGB, fr, es, it, pt, nlBE } from 'date-fns/locale';
import React, { FC, memo, useCallback } from 'react';
import useTranslations from '@hooks/useTranslations';
import { DATE_FORMAT, LANGUAGE, LANGUAGES, PRINT_DATEFORMAT_ERRORS } from '../constants/main';
import { useTranslation } from '../../i18n';
import capitalize from 'lodash/capitalize';
import { Trans } from '../../i18n';

export const LOCALE_FR = 'fr';
export const LOCALE_UK = 'en';
export const LOCALE_IT = 'it';
export const LOCALE_ES = 'es';
export const LOCALE_NL = 'nl';
export const LOCALE_PT = 'pt';

export type LocaleString =
    | typeof LOCALE_FR
    | typeof LOCALE_UK
    | typeof LOCALE_ES
    | typeof LOCALE_IT
    | typeof LOCALE_NL
    | typeof LOCALE_PT;
type SourceDate = Date | string;

type Locales = {
    [index in LocaleString]: Locale;
};

const locales: Locales = {
    fr: fr,
    en: enGB,
    es: es,
    it: it,
    nl: nlBE,
    pt: pt,
};

interface IFormatDate {
    date?: SourceDate;
    locale?: LocaleString;
    formatString?: string;
}

const getLocaleByLanguage = () => {
    switch (LANGUAGE) {
        case LANGUAGES.EN:
            return locales.en;
        case LANGUAGES.FR:
            return locales.fr;
        case LANGUAGES.ES:
            return locales.es;
        case LANGUAGES.IT:
            return locales.it;
        case LANGUAGES.NL:
            return locales.nl;
        case LANGUAGES.PT:
            return locales.pt;
        default:
            return locales.fr;
    }
};

export function formatLeadTime(leadTime: Date | string, maxLeadTime?: Date) {
    const { t } = useTranslations();

    if (!leadTime) {
        return '';
    }

    const leadTimeDate = new Date(leadTime);
    const maxLeadTimeDate = maxLeadTime ? new Date(maxLeadTime) : null;

    const MONTH_STRING = 'LLLL';
    const MONTH_NUMBER = 'M';
    const YEAR_NUMBER = 'YYY';

    const leadTimeMonth = formatDate(leadTimeDate, MONTH_STRING, getLocaleByLanguage());
    const leadTimeMonthNumber = formatDate(leadTimeDate, MONTH_NUMBER, getLocaleByLanguage());
    const leadTimeYear = leadTimeDate.getUTCFullYear();

    const maxLeadTimeMonth = maxLeadTimeDate ? formatDate(maxLeadTimeDate, MONTH_STRING, getLocaleByLanguage()) : null;
    const maxLeadTimeMonthNumber = maxLeadTimeDate
        ? formatDate(maxLeadTimeDate, MONTH_NUMBER, getLocaleByLanguage())
        : null;
    const maxLeadTimeYear = maxLeadTimeDate ? maxLeadTimeDate.getUTCFullYear() : null;

    if (
        maxLeadTime &&
        (Number(maxLeadTimeYear) > Number(leadTimeYear) || Number(maxLeadTimeMonthNumber) > Number(leadTimeMonthNumber))
    ) {
        return (
            <Trans
                i18nKey="delivery.leadTime.availableIn"
                t={t}
                values={{
                    from: `${capitalize(leadTimeMonth)} ${leadTimeYear}`,
                    to: `${capitalize(maxLeadTimeMonth)} ${maxLeadTimeYear}`,
                }}
            >
                <span className="availableInLabel">Available from</span>
                <span className="availableInDate">
                    {capitalize(leadTimeMonth)} {leadTimeYear}
                </span>
                <span className="availableInLabel">to</span>
                <span className="availableInDate">
                    {capitalize(maxLeadTimeMonth)} {maxLeadTimeYear}
                </span>
            </Trans>
        );
    }

    return (
        <Trans
            i18nKey="delivery.leadTime.availableTo"
            t={t}
            values={{ date: `${capitalize(leadTimeMonth)} ${leadTimeYear}` }}
        >
            Available to {capitalize(leadTimeMonth)} {leadTimeYear}
        </Trans>
    );
}

export function getMinutesBetweenTwoDates(startDate: Date, endDate?: Date): number {
    if (!startDate) {
        return 0;
    }

    const startTime = new Date(Number(startDate));
    const endTime = endDate ? new Date(Number(endDate)) : new Date();

    return Math.round((endTime.getTime() - startTime.getTime()) / 60000);
}

export function formatDate(date: Date, formatString: string, locale?: Locale) {
    return format(date, formatString, { locale });
}

export function formatDateByLang(date: string | Date): string {
    if (typeof date === 'string' && date.includes('T')) {
        date = date.split('T')[0];
    }
    try {
        switch (LANGUAGE) {
            case LANGUAGES.EN:
                return formatDate(new Date(date), DATE_FORMAT.EN, locales.en);
            case LANGUAGES.FR:
                return formatDate(new Date(date), DATE_FORMAT.FR, locales.fr);
            default:
                return formatDate(new Date(date), DATE_FORMAT.FR, locales.fr);
        }
    } catch (err: any) {
        console.error('Caught exception when formatting date - formatDateByLang', err);
        return '';
    }
}

export function getDateFormatByLang(): string {
    switch (LANGUAGE) {
        case LANGUAGES.EN:
            return DATE_FORMAT.EN;
        case LANGUAGES.FR:
            return DATE_FORMAT.FR;
        default:
            return DATE_FORMAT.FR;
    }
}

export const useDateUtils = () => {
    const { t } = useTranslations();
    const {
        i18n: { language },
    } = useTranslation();

    const formatDateFn = useCallback(
        (date?: SourceDate, locale?: LocaleString, formatString?: string) => {
            if (!date) return null;

            const dateToFormat = typeof date === 'string' ? parseISO(date) : date;
            const defaultFormatString = t('dateFormat.default');

            try {
                return formatDate(
                    dateToFormat,
                    formatString || defaultFormatString,
                    locales[locale || (language as LocaleString)]
                );
            } catch (e: any) {
                if (process.env.NODE_ENV !== 'production' || PRINT_DATEFORMAT_ERRORS) {
                    console.group('FORMAT DATE ERROR');
                    console.log('Format:', formatString || defaultFormatString);
                    console.log('Date:', date);
                    console.error(e);
                    console.groupEnd();
                }
                return formatDate(dateToFormat, 'dd/MM/yyyy');
            }
        },
        [t]
    );

    const FormattedDate: FC<IFormatDate> = ({ date, locale, formatString }) => {
        if (!date) return null;
        return (
            <time dateTime={typeof date === 'string' ? date : date.toISOString()}>
                {formatDateFn(date, locale, formatString)}
            </time>
        );
    };

    return { formatDate: formatDateFn, FormattedDate: memo(FormattedDate) };
};

export default formatDate;
