import { Icons } from '..';
import calendar from './icons/calendar.svg';
import information from './icons/information.svg';
import engine from './icons/engine.svg';
import exterior from './icons/exterior.svg';
import interior from './icons/interior.svg';
import extras from './icons/extras.svg';
import logo from './icons/logo.svg';
import info from './icons/info.svg';
import phone from './icons/phone.svg';
import mail from './icons/mail.svg';
import edit from './icons/edit.svg';
import basket from './icons/basket.svg';
import account from './icons/account.svg';
import logoSmall from './icons/logoSmall.svg';
import headset from './icons/headset.svg';
import drive from './icons/SteeringCircuit_Outline.svg';
import headsetV2 from './icons/Headset_Outline.svg';
import question from './icons/question.svg';
import correct from './icons/correct.svg';
import clock from './icons/clock.svg';
import clockV2 from './icons/clock.svg';
import settings from './icons/settings.svg';
import menu from './icons/menu.svg';
import securePayment from './icons/securePayment.svg';
import car from './icons/car.svg';
import carSolid from './icons/car_solid.svg';
import carStore from './icons/carsStore.svg';
import customize from './icons/customize.svg';
import dealer from './icons/dealer.svg';
import mapPoint from './icons/mapPointNew.svg';
import chevronLeftOutlined from './icons/chevronDown.svg';
import chevronRightOutlined from './icons/chevronDown.svg';
import PoiCommercialBuilding from './icons/poiCommercialBuilding.svg';
import PlusIcon from './icons/plus.svg';

export const iconsDS = (name: Icons) => {
    switch (name) {
        case Icons.Calendar:
            return calendar;
        case Icons.Information:
            return information;
        case Icons.Engine:
            return engine;
        case Icons.EngineSimple:
            return engine;
        case Icons.Menu:
            return menu;
        case Icons.HeadsetV2:
            return headsetV2;
        case Icons.Headset:
            return headset;
        case Icons.Question:
            return question;
        case Icons.Dealer:
            return dealer;
        case Icons.Exterior:
            return exterior;
        case Icons.Interior:
            return interior;
        case Icons.Extras:
            return extras;
        case Icons.Logo:
            return logo;
        case Icons.Info:
            return info;
        case Icons.Correct:
            return correct;
        case Icons.Phone:
            return phone;
        case Icons.Mail:
            return mail;
        case Icons.edit:
            return edit;
        case Icons.Basket:
            return basket;
        case Icons.Account:
            return account;
        case Icons.Settings:
            return settings;
        case Icons.LogoSmall:
            return logoSmall;
        case Icons.Clock:
            return clock;
        case Icons.SecurePayment:
            return securePayment;
        case Icons.Car:
            return car;
        case Icons.StoreCarSolid:
            return carSolid;
        case Icons.CarStore:
            return carStore;
        case Icons.Customize:
            return customize;
        case Icons.MapPoint:
            return mapPoint;
        case Icons.Drive:
            return drive;
        case Icons.ClockV2:
            return clockV2;
        case Icons.chevronLeftOutlined:
            return chevronLeftOutlined;
        case Icons.chevronRightOutlined:
            return chevronRightOutlined;
        case Icons.PoiCommercialBuilding:
            return PoiCommercialBuilding;
        case Icons.PlusIcon:
            return PlusIcon;
        default:
            return '';
    }
};
