import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { PX_BACKEND_URL } from '../../constants/main';
import { APPLICATION_JSON, getAuthTokenFromCookie, TIMEOUT_PERIOD } from '../constants/api';

const pxInstance: AxiosInstance = axios.create({
    baseURL: PX_BACKEND_URL,
    headers: {
        Accept: APPLICATION_JSON,
        'Content-Type': APPLICATION_JSON,
    },
    timeout: TIMEOUT_PERIOD,
});

// get current auth token from cookie at the time of request
pxInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        const authToken = getAuthTokenFromCookie();
        if (authToken) config.headers['X-Auth-Token'] = authToken;

        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

export default pxInstance;
