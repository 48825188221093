import styled from 'styled-components';
import AlertTemplate from './AlertTemplate';
import { rem } from 'polished';

export const AlertStyled = styled(AlertTemplate)`
    .alert {
        top: 0;
        left: 0;
        width: 100%;
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
        z-index: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: ${rem(5)};
        color: ${({ theme }) => theme.colors.white};
        padding: 1rem 1.5rem;
        background-color: #fff;

        &-fixed {
            position: fixed;
        }

        &-absolute {
            position: absolute;
        }

        &-success {
            background-color: ${({ theme }) => theme.colors.success};
        }

        &-error {
            background-color: ${({ theme }) => theme.colors.danger};
        }

        &-info {
            background-color: ${({ theme }) => theme.colors.info};
        }

        &-warning {
            background-color: ${({ theme }) => theme.colors.warning};
        }

        & > div {
            display: flex;
            height: auto;
        }
        svg {
            width: ${rem(16)};
            height: ${rem(16)};
            transform: rotate(45deg);
            vertical-align: bottom;
            cursor: pointer;
            path {
                fill: ${({ theme }) => theme.colors.white};
            }
        }
    }

    .content {
        display: flex;
        align-items: center;
        & * {
            margin: 0 0.5rem;
        }

        a {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`;
