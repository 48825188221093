import { applyMiddleware, combineReducers, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import UIDuck from './commonDucks/ui.duck';
import OfferListDuck from './carList/offerlist.duck';
import { FilterDuck } from './filters/filter.duck';
import { ContentDuck } from './content/content.duck';
import { ConfiguratorDuck } from './configurator/configurator.duck';
import TrimSelectorDuck from './trimSelector/trimSelector.duck';
import UserDuck from './user/user.duck';
import carDetailsDuck from './carDetails/carDetails.duck';
import financeWidgetDuck from './financeWidget/financeWidget.duck';
import GlobalDuck from './global/global.duck';
import DealerDuck from './dealer/dealer.duck';
import NavigationDuck from './navigation/navigation.duck';

const combineDucks = (...ducks: any): any =>
    combineReducers(
        ducks.reduce((root: any, duck: any) => {
            return { ...root, [duck.name]: duck.reducer };
        }, {})
    );

const rootReducer: any = combineDucks(
    UIDuck,
    FilterDuck,
    OfferListDuck,
    ContentDuck,
    ConfiguratorDuck,
    TrimSelectorDuck,
    UserDuck,
    carDetailsDuck,
    financeWidgetDuck,
    GlobalDuck,
    DealerDuck,
    NavigationDuck
);

export const initializeStore = (initialState: any = {}) =>
    createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(reduxThunk)));
