import React from 'react';
import { BRAND } from '../../constants/main';
import { AlertStyledAC } from './AC/AlertStyled.AC';
import { AlertStyledOV } from './OV/AlertStyled.OV';
import { AlertStyledAP } from './AP/AlertStyled.AP';
import { AlertStyledDS } from './DS/AlertStyled.DS';
import { AlertStyled } from './AlertStyled';

export enum AlertType {
    INFO = 'INFO',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    WARNING = 'WARNING',
}

const Alert = () => {
    if (BRAND === 'AC') return <AlertStyledAC />;
    if (BRAND === 'OV') return <AlertStyledOV />;
    if (BRAND === 'AP') return <AlertStyledAP />;
    if (BRAND === 'DS') return <AlertStyledDS />;
    return <AlertStyled />;
};

export default Alert;
