export interface IExperimentalFeatureFlagContext {
    TRIM_PAGE_CONTINUE_STICKY?: string;
    SHOW_SUMMARY_BEFORE_BASKET_MTO?: string;
    SHOW_SUMMARY_BEFORE_BASKET_STOCK?: string;
    SHOW_UPSELL_OPTIONS_ON_SUMMARY_PAGE?: string;
}

export type FeatureFlagVariants =
    (typeof EXPERIMENTATION_FEATURE_VARIANTS)[keyof typeof EXPERIMENTATION_FEATURE_VARIANTS];

export type FeatureFlags = Record<EXPERIMENTAL_FEATURES_LIST, FeatureFlagVariants>;

export type DecideEndpointResponse = FeatureFlags & {
    [key: string]: unknown;
};
export enum EXPERIMENTAL_FEATURES_LIST {
    TRIM_PAGE_CONTINUE_STICKY = 'TRIM_PAGE_CONTINUE_STICKY',
    SHOW_SUMMARY_BEFORE_BASKET_MTO = 'SHOW_SUMMARY_BEFORE_BASKET_MTO',
    SHOW_SUMMARY_BEFORE_BASKET_STOCK = 'SHOW_SUMMARY_BEFORE_BASKET_STOCK',
    SHOW_UPSELL_OPTIONS_ON_SUMMARY_PAGE = 'SHOW_UPSELL_OPTIONS_ON_SUMMARY_PAGE',
}
export enum ShowHideVariant {
    DEFAULT_VARIANT = 'control',
    TEST_HIDDEN = 'TEST_HIDDEN',
}

export const EXPERIMENTATION_FEATURE_VARIANTS = {
    [EXPERIMENTAL_FEATURES_LIST.TRIM_PAGE_CONTINUE_STICKY]: ShowHideVariant,
    [EXPERIMENTAL_FEATURES_LIST.SHOW_SUMMARY_BEFORE_BASKET_MTO]: ShowHideVariant,
    [EXPERIMENTAL_FEATURES_LIST.SHOW_SUMMARY_BEFORE_BASKET_STOCK]: ShowHideVariant,
    [EXPERIMENTAL_FEATURES_LIST.SHOW_UPSELL_OPTIONS_ON_SUMMARY_PAGE]: ShowHideVariant,
};
