import { AxiosPromise } from 'axios';
import coreApiService from './coreApi.service';
import { BRAND, SESSION_REQUEST_HEADER_NAME, MARKET, LANGUAGE } from '../../constants/main';
import DealService from '../deal/deal.service';

const FINANCE_QUOTE_URL: string = 'digital-api/api/finance-quotes';
const UPDATE_MOP_STEP_URL: string = 'digital-api/api/update-mop-step';
const CREATE_RETAIL_MOP_URL: string = 'digital-api/api/create-retail-mop';
const UPDATE_RETAIL_MOP_URL: string = 'digital-api/api/deals/update-mop-for-deal';
const LOGOUT_URL: string = 'digital-api/api/customers/logout';
const CHECK_PAYMENT_STATUS_URL: string = 'digital-api/api/check-payment-status';
const SHARED_CONFIGURATION: string = 'digital-api/api/shared-deal';
const SHARED_CONFIGURATION_EMAIL: string = 'digital-api/api/shared-deal/email';
const SHARED_CONFIGURATION_EMAIL_DEEP_LINK: string = 'digital-api/api/shared-deal/deep-link/email';
const SHARED_CONFIGURATION_AGENT_PORTAL_BY_EMAIL: string = 'digital-api/api/agent-portal/email';
const SHARED_CONFIGURATION_AGENT_PORTAL_BY_LINK: string = 'digital-api/api/agent-portal/link';
const SHARED_CONFIGURATION_LINK: string = 'digital-api/api/shared-deal/link';
const UPDATE_MOP_CUSTOMER_URL: string = 'digital-api/api/update-mop-customer';

export const sessionInfoApi: {
    updateMopStep: (step: string | undefined, code: number | undefined, token: string, mopId?: string) => AxiosPromise;
    financeQuote: (id: string) => AxiosPromise;
    createRetailMop: (token: string) => AxiosPromise;
    updateRetailMop: (token: string) => AxiosPromise;
    linkPxToMop: (token: string, mopId: string, partExchangeId: string) => AxiosPromise;
    logout: (token: string) => AxiosPromise;
    checkPaymentStatus: (token: string) => AxiosPromise;
    shareConfiguration: (
        email: string,
        token: string,
        dealerId?: string,
        salesmanId?: string | false,
        page?: string,
        salesmanType?: number,
        isAssistedSale?: boolean
    ) => AxiosPromise;
    shareConfigurationEmailDeepLink: (email: string, token: string, externalId: string) => AxiosPromise;
    shareConfigurationEmail: (
        email: string,
        token: string,
        dealerId?: string,
        salesmanId?: string | false,
        page?: string,
        salesmanType?: number,
        isAssistedSale?: boolean,
        salutation?: string,
        firstName?: string,
        lastName?: string,
        companyName?: string,
        mobilePersonalTelephone?: string,
        checkBox1Meaning?: string,
        checkBox1Value?: boolean,
        checkBox2Meaning?: string,
        checkBox2Value?: boolean,
        checkBox3Meaning?: string,
        checkBox3Value?: boolean
    ) => AxiosPromise;
    copyLinkAgentPortal: (validity: string, token: string) => AxiosPromise;
    shareConfigurationEmailAgentPortal: (
        email: string,
        token: string,
        salutation: string,
        firstName: string,
        lastName: string,
        validity: string,
        companyName: string,
        checkBox1Meaning?: string,
        checkBox1Value?: boolean,
        checkBox2Meaning?: string,
        checkBox2Value?: boolean,
        checkBox3Meaning?: string,
        checkBox3Value?: boolean
    ) => AxiosPromise;
    getShareConfigurationLink: (
        token: string,
        dealerId?: string,
        salesmanId?: string,
        salesmanType?: number,
        isAssistedSale?: boolean
    ) => AxiosPromise;
    updateMopCustomer: (token: string) => AxiosPromise;
} = {
    financeQuote: (id: string) => coreApiService.get(FINANCE_QUOTE_URL + `/${id}`),
    createRetailMop: async (token: string) => {
        // wait till all deal updates are done before creating mop
        await DealService.lock;
        const headers = {
            [SESSION_REQUEST_HEADER_NAME]: token || '',
        };
        return coreApiService.get(CREATE_RETAIL_MOP_URL, {}, headers);
    },
    updateRetailMop: async (token: string) => {
        await DealService.lock;
        const headers = {
            [SESSION_REQUEST_HEADER_NAME]: token || '',
        };
        return coreApiService.post(UPDATE_RETAIL_MOP_URL, {}, headers);
    },
    linkPxToMop: (token, mopId, partExchangeId) => {
        const headers = {
            [SESSION_REQUEST_HEADER_NAME]: token || '',
        };
        return coreApiService.post(
            `px/api/${MARKET}/${LANGUAGE}/${BRAND}/mop/${mopId}/update/${partExchangeId}`,
            {},
            headers
        );
    },
    logout: (token: string) => {
        const headers = {
            [SESSION_REQUEST_HEADER_NAME]: token || '',
        };
        return coreApiService.delete(LOGOUT_URL, {}, headers);
    },
    checkPaymentStatus: (token: string) => {
        const headers = {
            [SESSION_REQUEST_HEADER_NAME]: token || '',
        };
        return coreApiService.get(CHECK_PAYMENT_STATUS_URL, {}, headers);
    },
    updateMopStep: (step: string | undefined, code: number | undefined, token: string, mopId?: string) => {
        const headers = {
            [SESSION_REQUEST_HEADER_NAME]: token || '',
        };
        return coreApiService.post(
            UPDATE_MOP_STEP_URL,
            {
                step,
                code,
                mopId,
            },
            headers
        );
    },
    shareConfiguration: (email, token, dealerId, salesmanId, page = 'basket', salesmanType, isAssistedSale = false) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.post(
            SHARED_CONFIGURATION,
            {
                email,
                isAssistedSale,
                ...(dealerId && { idSiteGeo: dealerId }),
                ...(salesmanId && { salesmanId }),
                page,
                ...(salesmanType && { salesmanType }),
            },
            headers
        );
    },
    shareConfigurationEmailDeepLink: (externalId, email, token) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };

        return coreApiService.post(
            SHARED_CONFIGURATION_EMAIL_DEEP_LINK,
            {
                externalId,
                email,
            },
            headers
        );
    },
    shareConfigurationEmail: (
        email,
        token,
        dealerId,
        salesmanId,
        page = 'basket',
        salesmanType,
        isAssistedSale = false,
        salutation,
        firstName,
        lastName,
        companyName,
        mobilePersonalTelephone,
        checkBox1Meaning,
        checkBox1Value,
        checkBox2Meaning,
        checkBox2Value,
        checkBox3Meaning,
        checkBox3Value
    ) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.post(
            SHARED_CONFIGURATION_EMAIL,
            {
                email,
                isAssistedSale,
                ...(dealerId && { idSiteGeo: dealerId }),
                ...(salesmanId && { salesmanId }),
                page,
                ...(salesmanType && { salesmanType }),
                ...(salutation && { salutation }),
                ...(firstName && { firstName }),
                ...(lastName && { lastName }),
                ...(companyName && { companyName }),
                ...(mobilePersonalTelephone && { mobilePersonalTelephone }),
                ...(checkBox1Meaning && { checkBox1Meaning }),
                ...(checkBox2Meaning && { checkBox2Meaning }),
                ...(checkBox3Meaning && { checkBox3Meaning }),
                ...(checkBox1Value && { checkBox1Value }),
                ...(checkBox2Value && { checkBox2Value }),
                ...(checkBox3Value && { checkBox3Value }),
            },
            headers
        );
    },
    copyLinkAgentPortal: (validity, token: string) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.post(
            SHARED_CONFIGURATION_AGENT_PORTAL_BY_LINK,
            {
                ...(validity && { offerValidityDate: validity }),
            },
            headers
        );
    },
    shareConfigurationEmailAgentPortal: (
        email,
        token,
        salutation,
        firstName,
        lastName,
        validity,
        companyName,
        checkBox1Meaning,
        checkBox1Value,
        checkBox2Meaning,
        checkBox2Value,
        checkBox3Meaning,
        checkBox3Value
    ) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.post(
            SHARED_CONFIGURATION_AGENT_PORTAL_BY_EMAIL,
            {
                email,
                ...(validity && { offerValidityDate: validity }),
                ...(salutation && { salutation }),
                ...(firstName && { firstName }),
                ...(lastName && { lastName }),
                ...(companyName && { companyName }),
                ...(checkBox1Meaning && { checkBox1Meaning }),
                ...(checkBox2Meaning && { checkBox2Meaning }),
                ...(checkBox3Meaning && { checkBox3Meaning }),
                ...(checkBox1Value && { checkBox1Value }),
                ...(checkBox2Value && { checkBox2Value }),
                ...(checkBox3Value && { checkBox3Value }),
            },
            headers
        );
    },
    getShareConfigurationLink: (token, dealerId, salesmanId, salesmanType, isAssistedSale = false) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.post(
            SHARED_CONFIGURATION_LINK,
            {
                isAssistedSale,
                ...(dealerId && { idSiteGeo: dealerId }),
                ...(salesmanId && { salesmanId }),
                ...(salesmanType && { salesmanType }),
            },
            headers
        );
    },
    updateMopCustomer: (token: string = '') => {
        const headers = {
            [SESSION_REQUEST_HEADER_NAME]: token || '',
        };
        return coreApiService.post(UPDATE_MOP_CUSTOMER_URL, {}, headers);
    },
};

export default sessionInfoApi;
