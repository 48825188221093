import React, { FunctionComponent, useEffect, useState } from 'react';
import BrowserUpdate from '@components/BrowserUpdate';
import { detectBrowserVersion } from '@utils/misc.utils';

export const BrowserDetectionContext = React.createContext({});

const BrowserDetectionProvider: FunctionComponent = (props) => {
    const { children } = props;
    const [isBrowserSupported, setIsBrowserSupported] = useState(true);

    useEffect(() => {
        setIsBrowserSupported(detectBrowserVersion().supported);
    }, []);

    return (
        <BrowserDetectionContext.Provider value={{ isBrowserSupported }}>
            {!isBrowserSupported ? <BrowserUpdate /> : children}
        </BrowserDetectionContext.Provider>
    );
};

export default BrowserDetectionProvider;
