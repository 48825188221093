import { useContext } from 'react';
import { FEATURES_LIST, FeatureSwitchContext as FeatureSwitchContextApp } from '../context/featureSwitchApp';
import { FeatureSwitchContext as FeatureSwitchContextTesting } from '../context/featureSwitchTesting';
import { isFeatureEnabled } from '@utils/featureSwitches.utils';
import { EXPERIMENTAL_FEATURES_LIST } from '@utils/experiments/@types';
import { isFeatureFlagEnabled } from '@utils/experiments/getExperimentationFeatureFlags';

export const useFeatureSwitchEnabled = (name: FEATURES_LIST, value?: any): boolean => {
    const context = useContext(FeatureSwitchContextApp);
    return isFeatureEnabled(name, context, value);
};

export const useExperimentalFeatureFlagEnabled = (name: EXPERIMENTAL_FEATURES_LIST, value?: any): boolean => {
    const context = useContext(FeatureSwitchContextTesting);
    return isFeatureFlagEnabled(name, context, value);
};
