// fe-app/src/input/carInput.ts
import { createInput } from '../core/sdk/baseInput';
import { CAR_DETAIL_FIELDS, JOURNEY, DETAILS_OPTIONS_AGGREGATED } from '../core/sdk/constants/sdkFields';
import { carDetailProvider } from '../core/sdk/providers';
import {
    addNameplateBodystyleSlugFilter,
    addSpeckPackSlugFilter,
    addEngineGearboxFuelSlug,
    addExteriorColourSlug,
    addInteriorColourSlug,
    addExternalId,
} from '../core/sdk/sdk.service';
import { CASH } from '../filters/filters';
import { ICarDetailsParameters } from './carDetails.types';

export const getCurrentCarDetailsInput = ({
    paymentJourneyType,
    nameplateBodyStyleSlug,
    speckPackSlug,
    engineGearboxFuelSlug,
    exteriorColourSlug,
    interiorColourSlug,
    externalId,
}: ICarDetailsParameters) => {
    const RELEVANCY_AGREGATION = paymentJourneyType === CASH ? 'prices.basePrice' : 'prices.monthlyPrices.amount';

    const input = createInput(carDetailProvider)
        .addLevelAggregation(DETAILS_OPTIONS_AGGREGATED, null, DETAILS_OPTIONS_AGGREGATED)
        .addRelevancyAggregation(
            RELEVANCY_AGREGATION,
            { size: 1, sort: 'asc' },
            DETAILS_OPTIONS_AGGREGATED,
            CAR_DETAIL_FIELDS
        )
        .addExtra(JOURNEY, paymentJourneyType);

    if (nameplateBodyStyleSlug) addNameplateBodystyleSlugFilter(input, nameplateBodyStyleSlug);
    if (speckPackSlug) addSpeckPackSlugFilter(input, speckPackSlug);
    if (engineGearboxFuelSlug) addEngineGearboxFuelSlug(input, engineGearboxFuelSlug);
    if (exteriorColourSlug) addExteriorColourSlug(input, exteriorColourSlug);
    if (interiorColourSlug) addInteriorColourSlug(input, interiorColourSlug);
    if (externalId) addExternalId(input, externalId);
    // if (!externalId && paymentJourneyType === FINANCE) addMonthlyRangeFilter(input);

    return input;
};
