import { ParsedUrlQuery } from 'querystring';
import {
    ConfigurableParentNames,
    IFilterCategory,
    IFilterRule,
    IGeoLocation,
    IPriceRange,
    StockParentNames,
} from '../../../redux/filters/filter.duck.interface';
import { createFilterPayload } from '../filterHelpers';
import { IFilter } from '../../carList/carList.types';
import { TBudgetType } from '../../../interfaces/IFilters';
import { SORT_TYPE } from '../../core/sdk/constants/sdkFields';
import { IStockVehiclesFilters, SortFilterKey } from '../../stock/stock.types';
import { PRICES, SORT, NATIONAL } from '../filters';
import { IAggregations, SortOrder, SortType } from '../../stock/models/stockItems.service.model';
import { PaymentJourneyTypes } from '../../../partExchange/interfaces/Default';
import {IDealer} from "../../../interfaces/Dealer";
import {formatCoordsString} from "@components/GeoLocationInput/utils";

export const modifyFilters = (filters: IFilterRule[], name: string, value: boolean | IPriceRange): IFilterRule[] =>
    filters.map((filter) => {
        if (filter.name === name) {
            return { ...filter, value };
        }

        return filter;
    });

export const modifySortFilter = (filters: IFilterRule[], type?: SortType, order?: SortOrder): IFilterRule[] =>
    filters.map((filter) => {
        if (filter.name === SORT && type && order) {
            return { ...filter, type, order };
        }

        return filter;
    });

export const getSelectedSortFilterKey = (filters: IFilterRule[]): SortFilterKey | undefined => {
    const filterRule = filters.find((filter) => filter.name === SORT);

    if (!filterRule) {
        return;
    }

    return Object.values(SortFilterKey).find((value) => `${filterRule.type}-${filterRule.order}` === value);
};

export const filtersToUrl = (
    filterCategories: IFilterCategory[],
    filters: IFilterRule[],
    sort: SORT_TYPE,
    q: string,
    geoLocation?: IGeoLocation,
    distanceRadius?: number,
    geoLocationName?: string,
    priceRange?: IPriceRange,
    isHomePage?: boolean,
    dealerId?: string,
): string => {
    const searchParams = new URLSearchParams(q);

    searchParams.delete('resetFilters');

    const sortFilter = filters.find((filter) => filter.parent === SORT);
    if (!isHomePage && sortFilter?.type && sortFilter?.order) {
        searchParams.set(SORT, `${sortFilter.type},${sortFilter.order}`);
    } else {
        searchParams.delete(SORT);
    }
    // searchParams.set('sort', sort);

    if (geoLocation && distanceRadius >= 0 && geoLocationName) {
        searchParams.set('geoLocation', encodeURIComponent(Object.values(geoLocation).join(',')));
        searchParams.set('distanceRadius', distanceRadius === 0 ? NATIONAL : String(distanceRadius));
        searchParams.set('geoLocationName', geoLocationName);
    } else {
        searchParams.delete('geoLocation');
        searchParams.delete('distanceRadius');
        searchParams.delete('geoLocationName');
    }

    if (dealerId) {
        searchParams.set('idsitegeo', dealerId);
    } else {
        searchParams.delete('idsitegeo');
    }

    for (const cat of filterCategories) {
        const values = filters
            .map((filter) => {
                if (filter.parent === cat.name) {
                    if (typeof filter.value === 'boolean' && filter.value) {
                        return filter.name.substr(filter.name.indexOf('.') + 1);
                    } else if (typeof filter.value !== 'boolean' && filter.value && priceRange) {
                        return `${filter.value.min},${filter.value.max}`;
                    }
                }
            })
            .filter((f) => f !== undefined);

        if (values.length > 0) {
            searchParams.set(cat.name, values.join(','));
        } else {
            searchParams.delete(cat.name);
        }
    }

    const query = searchParams.toString();

    return query ? `?${searchParams.toString()}` : '';
};

const getPriceFiltersFromAllFilters = (allFilters: any = {}): { min: number; max: number } => {
    if (allFilters['proces.basePrice']) {
        return {
            min: allFilters?.['prices.basePrice']?.min,
            max: allFilters?.['prices.basePrice']?.max,
        };
    } else if (allFilters.minBudget && allFilters.maxBudget) {
        return {
            min: allFilters?.minBudget?.value,
            max: allFilters?.maxBudget?.value,
        };
    } else {
        return {
            min: 0,
            max: 50000,
        };
    }
};

export const filtersFromUrl = (filters: IFilterRule[], query: ParsedUrlQuery, allFilters: any = {}): IFilterRule[] =>
    filters.map((filter) => {
        const indexOfCharacter = filter.name.indexOf('.');
        let searchNames: string[] = [];

        if (indexOfCharacter > 0) {
            searchNames = [filter.name.substring(0, indexOfCharacter)];
        }
        searchNames = [...searchNames, filter.name.substring(indexOfCharacter + 1)];
        const searchCode = filter.code;
        // There is a value for this filter
        if (query[searchNames[0]]) {
            const values = query[searchNames[0]].toString().split(',');
            // Special price range needs
            if (searchNames[0] === 'prices' && values) {
                const defaults = getPriceFiltersFromAllFilters(allFilters);
                filter.value = {
                    min: Number(values[0]) || defaults.min,
                    max: Number(values[1]) || defaults.max,
                };
                return filter;
            } else if (searchNames[0] === SORT) {
                filter.type = values[0] as SortType;
                filter.order = values[1] as SortOrder;
                return filter;
            } else {
                const foundValue = values.find((v) => v === searchNames[1]);
                const foundCode = values.find((v) => v === searchCode);
                if (foundValue || foundCode) {
                    filter.value = true;
                } else {
                    filter.value = false;
                }
                return filter;
            }
        } else {
            if (typeof filter.value === 'boolean') {
                filter.value = false;
            }
        }
        return filter;
    });

export const getGeoLocationPropertiesFromURL = (
    query: ParsedUrlQuery
): { geoLocation?: IGeoLocation; distanceRadius?: any; geoLocationName?: string } => {
    let distanceRadiusParsed;
    if (query.distanceRadius === NATIONAL) {
        distanceRadiusParsed = 0;
    } else {
        distanceRadiusParsed = query.distanceRadius && parseInt(query.distanceRadius as string);
    }
    const isQueryParamArray = (param: string | string[]): param is Array<string> => Array.isArray(param);

    if (
        !query.geoLocation ||
        (!isQueryParamArray(query.geoLocation) && !decodeURIComponent(query.geoLocation).includes(','))
    ) {
        return {
            distanceRadius: distanceRadiusParsed,
            ...(query.geoLocationName && { geoLocationName: query.geoLocationName as string }),
        };
    }

    const [lat, lng] = isQueryParamArray(query.geoLocation)
        ? query.geoLocation
        : decodeURIComponent(query.geoLocation).split(',');

    const locationName = query.geoLocationName as string;
    return {
        geoLocation: { lat: parseFloat(lat), lng: parseFloat(lng) },
        distanceRadius: distanceRadiusParsed,
        geoLocationName: locationName,
    };
};

export const getGeolocationByQueryParameters = (storedGeolocation: { lat: number; lng: number }, dealer: IDealer) => {
    if (storedGeolocation) return storedGeolocation;
    if (dealer) return { lat: dealer.latitude, lng: dealer.longitude };

    return null;
};

export const getGeolocationNameByQueryParameters = (
    storedGeolocation: { lat: number; lng: number },
    dealer: IDealer,
    storedGeoLocationName: string
) => {
    if (storedGeoLocationName) return storedGeoLocationName;
    if (dealer) return `${dealer.streetName}, ${dealer.postCode} ${dealer.city}, ${dealer.country}`;

    return formatCoordsString(storedGeolocation?.lat, storedGeolocation?.lng);
};

export const stockSortingFiltersExportToParam = (filters: IFilterRule[]): IStockVehiclesFilters => {
    const params: IStockVehiclesFilters = {};

    for (const filter of filters) {
        if (filter.parent === SORT && filter.type && filter.order) {
            params['sortType'] = filter.type.trim() as SortType;
            params['sortOrder'] = filter.order.trim() as SortOrder;
            break;
        }
    }

    return params;
};

export const stockCarFiltersExportToParam = (filters: IFilterRule[]): IStockVehiclesFilters => {
    const params: IStockVehiclesFilters = {};

    for (const filter of filters) {
        if (filter.parent === SORT && filter.type && filter.order) {
            params['sort'] = `${filter.type}:${filter.order}`;
        }

        if (!filter.value) {
            continue;
        }

        if (filter.parent === PRICES) {
            params['maxBudget'] = (filter.value as IPriceRange).max;
            params['minBudget'] = (filter.value as IPriceRange).min;
            continue;
        }

        if (filter.parent in params) {
            params[filter.parent as StockParentNames] = `${params[filter.parent as StockParentNames]};${filter.code}`;
            continue;
        }

        params[filter.parent as StockParentNames] = filter.code;
    }

    return params;
};

export const parseQueryToStockParams = (query: { [key: string]: string }): IStockVehiclesFilters => {
    if (!query) {
        return null;
    }

    const maxBudget = query.prices?.split(',')?.[1];
    const minBudget = query.prices?.split(',')?.[0];

    const filters = {
        maxBudget: maxBudget && parseInt(maxBudget),
        minBudget: minBudget && parseInt(minBudget),
        transmissionTypes: query.transmissionTypes,
        energies: query.energies,
        engines: query.engines,
        grades: query.grades,
        gearboxTypes: query.gearboxTypes,
        colorGroups: query.colorGroups,
    };

    return Object.entries(filters).reduce((agg, [key, val]) => {
        if (val) {
            return {
                ...agg,
                [key]: val,
            };
        }
        return agg;
    }, {});
};

export const filtersExport = (
    filters: IFilterRule[],
    budget: TBudgetType,
    shouldSendPriceRange: boolean = true
): IFilter[] => {
    const reducedFilters: any = {};
    for (const filter of filters) {
        const searchNames = filter.name.split('.');
        if (typeof filter.value === 'boolean') {
            if (!reducedFilters[searchNames[0]]) reducedFilters[searchNames[0]] = [];
            if (filter.value) {
                reducedFilters[searchNames[0]].push(searchNames[1]);
            }
        } else if (shouldSendPriceRange) {
            reducedFilters[searchNames[0]] = filter.value;
        }
    }

    const filterExport = Object.keys(reducedFilters)
        .map((type) => {
            const value = reducedFilters[type];
            if (Array.isArray(value)) {
                if (!value.length) {
                    return null;
                }
            }
            return createFilterPayload(type as ConfigurableParentNames, value, budget);
        })
        .filter((f) => f !== null);

    return filterExport;
};

export const getPriceRangeByPaymentJourneyType = (
    paymentJourney: TBudgetType,
    filters: IAggregations
): { min: number; max: number } => {
    return paymentJourney === PaymentJourneyTypes.FINANCE
        ? {
              min: Number(filters.minPriceFinanced.value),
              max: Number(filters.maxPriceFinanced.value),
          }
        : {
              min: Number(filters.minPrice.value),
              max: Number(filters.maxPrice.value),
          };
};
