import { getCarTrimsInput, getCarTrimConfigurationsInput } from './trimInput';
import { carDetailProvider } from '../core/sdk/providers';
import { ICarTrimsParameters } from './trimList.types';
import { formatCarTrimsResponse, formatCarTrimConfigurationsResponse } from './trimList.utils';
import { TBudgetType } from '../../interfaces/IFilters';
import { SORT_TYPE } from '../core/sdk/constants/sdkFields';
import { CASH, EMPLOYEE_PRICE_TYPE } from '../filters/filters';

export const getCarTrims = async (
    paymentJourneyType: TBudgetType,
    nameplateBodyStyleSlug: string,
    filters: any[],
    sort: SORT_TYPE
) => {
    const parameters: ICarTrimsParameters = { paymentJourneyType, nameplateBodyStyleSlug, filters, sort };
    try {
        const input = getCarTrimsInput(parameters);
        const response = await carDetailProvider.getInputResults(input);
        console.log('%c FETCH getCarTrims', 'background: blue; color: white;');

        if (paymentJourneyType === CASH) {
            return formatCarTrimsResponse(response);
        }

        return {
            ...formatCarTrimsResponse(response),
            items: formatCarTrimsResponse(response).items.filter((item: any) => {
                const price = item.prices.find((price: any) => price.type === EMPLOYEE_PRICE_TYPE);
                return price && price.monthlyPrices.length;
            }),
        };
    } catch (e: any) {
        throw e;
    }
};

export const getCarTrimConfigurations = async (
    paymentJourneyType: TBudgetType,
    nameplateBodyStyleSlug: string,
    filters: any[],
    sort: SORT_TYPE
) => {
    const parameters: ICarTrimsParameters = { paymentJourneyType, nameplateBodyStyleSlug, filters, sort };
    try {
        console.log('%c FETCH getCarTrimConfigurations', 'background: blue; color: white;');
        const input = getCarTrimConfigurationsInput(parameters);
        const response = await carDetailProvider.getInputResults(input);
        return formatCarTrimConfigurationsResponse(response);
    } catch (e: any) {
        throw e;
    }
};
