import { css } from '../theme';
import fonts from './fonts';
import text from './text';
import { rem } from 'polished';

const main = css`
    ${fonts}
    ${text}

body {
        .buorg {
            font-family: 'DSAutomobiles-Regular', 'Arial', 'sans-serif' !important;
        }

        .buorg::before {
            background-color: #1d1717;
        }

        .buorg-pad {
            font-size: 16px !important;
        }

        #buorgul,
        #buorgpermanent {
            background-color: #ad0040;
        }

        #buorgul:hover,
        #buorgpermanent:hover {
            opacity: 0.8;
        }

        #buorgig {
            border: 1px solid #ad9d91;
            background: #fff;
            color: #ad9d91;
        }

        #buorgig:hover {
            border: 1px solid #655a54;
            color: #655a54;
        }
    }

    .sectionTitle {
        font-size: ${rem(20)};
        color: ${({ theme }) => theme.colors.black} !important;
        margin-bottom: ${rem(10)} !important;
    }
`;

export default main;
