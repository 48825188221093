import React from 'react';
import { BRAND, isMarketGB } from '../../constants/main';
import { BrowserUpdateStyled } from './BrowserUpdateStyled';
import { BrowserUpdateStyledAC } from './AC/BrowserUpdateStyled.AC';
import { BrowserUpdateStyledOV } from './OV/BrowserUpdateStyled.OV';
import { BrowserUpdateStyledOV as BrowserUpdateStyledOVGB } from './OV/GB/BrowserUpdateStyled.OV';
import { BrowserUpdateStyledAP } from './AP/BrowserUpdateStyled.AP';
import { BrowserUpdateStyledDS } from './DS/BrowserUpdateStyled.DS';
import { SC } from '../../styles/theme';

export interface BrowserUpdateProps extends SC {}

const BrowserUpdate = (props: BrowserUpdateProps) => {
    if (BRAND === 'AC') return <BrowserUpdateStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <BrowserUpdateStyledOVGB {...props} /> : <BrowserUpdateStyledOV {...props} />;
    if (BRAND === 'AP') return <BrowserUpdateStyledAP {...props} />;
    if (BRAND === 'DS') return <BrowserUpdateStyledDS {...props} />;
    return <BrowserUpdateStyled {...props} />;
};

export default BrowserUpdate;
