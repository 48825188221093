import { Icons } from '..';
import car from './icons/car.svg';
import calendar from './icons/calendar.svg';
import engine from './icons/engine.svg';
import exterior from './icons/exterior.svg';
import interior from './icons/interior.svg';
import extras from './icons/extras.svg';
import logo from './icons/logo.svg';
import info from './icons/info.svg';
import customize from './icons/customize.svg';
import correct from './icons/correct.svg';
import minus from './icons/minus.svg';
import mail from './icons/mail.svg';
import edit from './icons/edit.svg';
import phone from './icons/phone.svg';
import question from './icons/question.svg';
import profile from './icons/profile.svg';
import engineSimple from './icons/engineSimple.svg';
import basket from './icons/basket.svg';
import account from './icons/account.svg';
import logoSmall from './icons/logoSmall.svg';
import securePayment from './icons/securePayment.svg';
import carsStore from './icons/carsStore.svg';
import headsetOutlined from './icons/Headset_Outline.svg';
import steeringCircuitOutline from './icons/SteeringCircuit_Outline.svg';
import dealer from './icons/dealer.svg';
import logout from './icons/logout.svg';
import chevronLeft from './icons/chevronLeft.svg';
import chevronLeftOutlined from './icons/chevronLeftOutlined.svg';
import chevronRightOutlined from './icons/chevronRightOutlined.svg';
import clock from './icons/clock.svg';
import PoiCommercialBuilding from './icons/poiCommercialBuilding.svg';
import PlusIcon from './icons/plus.svg';

export const iconAP = (name: Icons) => {
    switch (name) {
        case Icons.Clock:
            return clock;
        case Icons.Calendar:
            return calendar;
        case Icons.chevronLeftOutlined:
            return chevronLeftOutlined;
        case Icons.chevronRightOutlined:
            return chevronRightOutlined;
        case Icons.chevronLeft:
            return chevronLeft;
        case Icons.Logout:
            return logout;
        case Icons.Dealer:
            return dealer;
        case Icons.Engine:
            return engine;
        case Icons.Customize:
            return customize;
        case Icons.Exterior:
            return exterior;
        case Icons.Interior:
            return interior;
        case Icons.Extras:
            return extras;
        case Icons.Logo:
            return logo;
        case Icons.Info:
            return info;
        case Icons.Correct:
            return correct;
        case Icons.Minus:
            return minus;
        case Icons.Phone:
            return phone;
        case Icons.Mail:
            return mail;
        case Icons.edit:
            return edit;
        case Icons.EngineSimple:
            return engineSimple;
        case Icons.Basket:
            return basket;
        case Icons.Account:
            return account;
        case Icons.LogoSmall:
            return logoSmall;
        case Icons.Car:
            return car;
        case Icons.Profile:
            return profile;
        case Icons.SecurePayment:
            return securePayment;
        case Icons.Request:
            return headsetOutlined;
        case Icons.Question:
            return question;
        case Icons.Drive:
            return steeringCircuitOutline;
        case Icons.CarStore:
            return carsStore;
        case Icons.PlusIcon:
            return PlusIcon;
        case Icons.PoiCommercialBuilding:
            return PoiCommercialBuilding;
        default:
            return '';
    }
};
