import { Store } from 'redux';
// eslint-disable-next-line no-restricted-imports
import { SingletonRouter } from 'next/router';

import { buildParameterizedPathname, extractParamsFromPathname } from './url.utils';
import GlobalDuck from '../redux/global/global.duck';
import { CarJourneyType } from '../services/carList/carList.types';
import { NextPageContext } from 'next';
import routes from '../constants/routes';
import { isFeatureEnabled } from './featureSwitches.utils';
import { FEATURES_LIST, IFeatureSwitchContext } from '../context/featureSwitchApp';
import {
    BR_DISABLE_PAGE_REDIRECT_URL,
    IS_SERVER,
    isBrandOV,
    LANGUAGE,
    MULTI_LANGUAGES_ENABLED,
    SETTINGS_SYNCHRONIZE_MINUTES,
} from '../constants/main';
import { getLastUpdateSetting } from '@utils/Session.utils';

const isValidCarJourney = (journey: string): journey is CarJourneyType =>
    (Object.values(CarJourneyType) as string[]).includes(journey);

const storeInitialURLParams = (store: Store, pathname: string) => {
    const { carJourney } = extractParamsFromPathname(pathname);

    if (carJourney && isValidCarJourney(carJourney)) {
        store.dispatch(GlobalDuck.setCarJourney(carJourney));
    }
};

export const checkLastSaveSettings = () => {
    const lastUpdate = getLastUpdateSetting();

    if (!lastUpdate || (Date.now() - Number(lastUpdate)) / 1000 / 60 >= SETTINGS_SYNCHRONIZE_MINUTES) {
        return { lastUpdate, isExpired: true };
    }

    return { lastUpdate, isExpired: false };
};

export const getOfferSidebarItems = (): any => {
    return [
        { text: 'offers.delivery.tips.item1' },
        { text: 'offers.delivery.tips.item2' },
        { text: 'offers.delivery.tips.item3' },
    ];
};

export const getSidebarItems = (isFinance: boolean, skipPaymentDeposit: boolean): any => {
    // do not add .cash suffix, it will be added automatically in components
    if (isBrandOV && !isFinance) {
        return [
            {
                text: 'checkout.delivery.tips.item1',
            },
            !skipPaymentDeposit && {
                title: 'checkout.payReservationFee',
                text: 'checkout.payReservationFeeDesc',
                withoutSuffix: true,
            },
            {
                title: 'checkout.stepDescriptionOrderConfirmation',
                text: 'checkout.stepDescriptionOrderConfirmationDesc',
                withoutSuffix: true,
            },
            {
                title: 'checkout.stepDescriptionPayForTheVehicle',
                text: 'checkout.stepDescriptionPayForTheVehicleDesc',
                withoutSuffix: true,
            },
            {
                title: 'checkout.stepDescriptionCollectionDelivery',
                text: 'checkout.stepDescriptionCollectionDeliveryDesc',
                withoutSuffix: true,
            },
        ];
    }

    return [
        { text: 'checkout.delivery.tips.item1' },
        { text: 'checkout.delivery.tips.item2' },
        { text: 'checkout.delivery.tips.item3' },
        { text: 'checkout.delivery.tips.item4' },
    ];
};

const createRedirectHandler =
    (res: NextPageContext['res'], router: SingletonRouter, prefix: string = '') =>
    (pathname: string) => {
        if (prefix) {
            pathname = prefix + pathname;
        }

        if (!process.browser) {
            res.writeHead(301, {
                Location: pathname,
            });

            return res.end();
        }

        return router.replace(pathname);
    };

export const handleInitialURLParams = (
    store: Store,
    pathname: string,
    res: NextPageContext['res'],
    router: SingletonRouter,
    featureContext: IFeatureSwitchContext
) => {
    const { carJourney, ...remainingParams } = extractParamsFromPathname(
        pathname
            ?.split('/')
            ?.filter((part) => part !== LANGUAGE)
            ?.join('/')
    );
    const { nameplateBodyStyleSlug } = remainingParams;

    const handleRedirect = createRedirectHandler(res, router, MULTI_LANGUAGES_ENABLED ? `/${LANGUAGE}` : '');

    const isBrandRecommendsJourneyAllowed = isFeatureEnabled(
        FEATURES_LIST.FEATURE_SWITCH_BRAND_RECOMMENDS_ENABLED,
        featureContext
    );

    // @NOTE if BR journey allowed, disable home and trim page
    if (
        isBrandRecommendsJourneyAllowed &&
        (pathname.includes(routes.SELECTOR) ||
            (featureContext[FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS]?.length > 0 &&
                Object.values(featureContext[FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS] as string[]).includes(
                    pathname.split('/')[1] ? pathname.split('/')[1] : CarJourneyType.CONFIGURABLE
                )))
    ) {
        return BR_DISABLE_PAGE_REDIRECT_URL.includes('http')
            ? IS_SERVER
                ? (res as any).redirect(BR_DISABLE_PAGE_REDIRECT_URL)
                : (window.location.href = BR_DISABLE_PAGE_REDIRECT_URL)
            : handleRedirect(BR_DISABLE_PAGE_REDIRECT_URL);
    }

    // @NOTE if 'nameplateBodyStyleSlug' not specified in URL and page = TrimPage, redirect to car selection page '/[carJourney]/[paymentJourney]'
    if (pathname.includes(routes.SELECTOR) && nameplateBodyStyleSlug === undefined) {
        const newPathname = buildParameterizedPathname(routes.ROOT, carJourney as CarJourneyType);

        return handleRedirect(newPathname);
    }

    // @NOTE if there's no car journey specified and only 1 is allowed, go for it
    if (!carJourney && featureContext[FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS]?.length >= 1) {
        const newPathname = buildParameterizedPathname(
            pathname,
            featureContext[FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS][0],
            remainingParams
        );
        return handleRedirect(newPathname);
    }

    // @NOTE if carJourney is missing in url, redirect to the journey selection splash (homepage)
    if (!carJourney) {
        return handleRedirect(routes.ROOT);
    }

    const isCarJourneyAllowed = isFeatureEnabled(
        FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS,
        featureContext,
        carJourney
    );

    // @NOTE if the car journey specified in url is not allowed, redirect to the allowed one
    if (carJourney && !isCarJourneyAllowed) {
        return handleRedirect(routes.ROOT);
    }

    // @NOTE if carJourney and paymentJourney is missing in url and stock, configurable journey are allowed, redirect to the stock finance journey
    if (!carJourney && featureContext[FEATURES_LIST.FEATURE_SWITCH_ALLOWED_CAR_JOURNEYS]?.length === 2) {
        const newPathname = buildParameterizedPathname(pathname, CarJourneyType.STOCK, remainingParams);

        return handleRedirect(newPathname);
    }

    storeInitialURLParams(store, pathname);
};
