import { useSelector } from 'react-redux';
import { useTranslation } from '../../i18n';
import mockedContent from '../../assets/public/i18n/AC/FR/fr/fr.json';
import UIDuck from 'src/redux/commonDucks/ui.duck';
import { translateMock } from './__mocks__/translate.mock';
import { isStorybook, isTest } from '@utils/runtime';
import ContentDuck from 'src/redux/content/content.duck';
import { IS_DEV } from 'src/constants/main';
import { TFunction, I18n } from 'next-i18next';

export type TranslateFunction = TFunction;

// TODO This simulates the the parameterizing of translate function
// however other features are not included therefore it's only a workaround and should be refactored completely
const replaceOptions = (translatedString: string, options: { [key: string]: string | number } = {}): string => {
    return Object.entries(options).reduce((agg, [key, value]) => {
        if (!agg.includes(`{{${key}}`)) {
            return agg;
        }

        return replaceOptions(agg.replace(`{{${key}}}`, String(value)), options);
    }, translatedString);
};

const graceFullStockMissingKeyFallback = (key: string) => {
    if (key.includes('.stock.')) {
        return key
            .split('.')
            .filter((word) => word !== 'stock')
            .join('.');
    }

    return key;
};

const createTranslateFunctionWithFallback =
    (t: TranslateFunction) => (key: string, options?: Record<string, string>) => {
        // TODO-TRANSLATION-FIX
        const translated = t(key, options);
        if (translated === key) {
            // TODO-TRANSLATION-FIX
            return t(graceFullStockMissingKeyFallback(key), options);
        }
        return translated;
    };
export type UseTranslationsResult = {
    t: TFunction;
    i18n: I18n;
    ready: boolean;
    hasValue: (key: string) => boolean;
};
export function useTranslations(isMocked?: boolean): UseTranslationsResult {
    if (isStorybook() || isTest()) {
        return {
            t: translateMock,
        } as UseTranslationsResult;
    }
    const translationsKeyView = useSelector((state) => UIDuck.getOwnState(state).translationKeysView);
    const translations =
        IS_DEV && isMocked ? mockedContent : useSelector((state) => ContentDuck.getTranslations(state));

    const { t, ...rest } = useTranslation();
    const tWithFallback = createTranslateFunctionWithFallback(t);

    // TODO - Refactor
    const translate: TranslateFunction = (key: string, options?: {}) => {
        if (!translationsKeyView && translations[key]) {
            return replaceOptions(translations[key], options);
        }
        return translationsKeyView ? key : tWithFallback(key, options);
    };

    const hasValue = (key: string): boolean => {
        // TODO-TRANSLATION-FIX
        const translated = translations[key];
        return translated && translated?.trim() && translated !== key;
    };
    return { t: translate, hasValue, ...rest } as UseTranslationsResult;
}

export default useTranslations;
