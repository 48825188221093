import { TBudgetType } from '../../interfaces/IFilters';
import { SORT_TYPE } from '../core/sdk/constants/sdkFields';

// @NOTE these have to be duplicated in constants/common.js
export enum CarJourneyType {
    CONFIGURABLE = 'configurable',
    STOCK = 'stock',
}

export type IauthToken = string;
export interface IFilter {
    name: string;
    operation: string;
    value: any;
    nesting: string[];
    parent: string | null;
}

export interface ICarListParameters {
    paymentJourneyType: TBudgetType;
    filters: IFilter[];
    sort: SORT_TYPE;
}

// todo add types to offer
export interface IOffer {
    name: string;
}
