import { PRINT_MEMORY_USAGE } from '../constants/main';

export const logMemoryUsage = (route?: string) => {
    if (PRINT_MEMORY_USAGE) {
        const memoryUsage = process.memoryUsage();

        console.group('*** Memory Usage ***');
        if (route) console.log(`Page: ${route}`);

        for (const key in memoryUsage) {
            // @ts-ignore
            console.log(`${key}: ${Math.round((memoryUsage[key] / 1024 / 1024) * 100) / 100} MB`);
        }

        console.groupEnd();
    }
};
