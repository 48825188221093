import { AxiosPromise } from 'axios';
import { IUpdateFinanceQuoteResponse, IUpdateFinanceQuote, IGetFinanceQuoteResponse } from './financePrices.types';
import coreApiService from '../core/coreApi.service';
import { BRAND, LANGUAGE, SESSION_REQUEST_HEADER_NAME } from '../../constants/main';
import { mapBrandStringToNumericCode } from '@utils/appConfig.utils';
import { captureException } from '@utils/sentry.utils';

const FINANCE_CALULATOR_SERVICE_URL = 'fc/';
const ENDPOINT_BASE: string = `${FINANCE_CALULATOR_SERVICE_URL}api/v3/${mapBrandStringToNumericCode(
    BRAND
)}/${LANGUAGE}`;
const FINANCE_DECLINED_REDIRECT_URL: string = 'digital-api/api/deals/get-redirect-to-spc';

class FinanceCalculatorService {
    public static getFinanceQuote = (token: string): AxiosPromise<IGetFinanceQuoteResponse> => {
        return coreApiService.get(`${ENDPOINT_BASE}/finance-quote`, { token }).then((response) => {
            const responseData = response?.data;

            if (!responseData?.deposit) {
                captureException(`Failed to get finance quote for token ${token}!`);
            }

            return response;
        });
    };

    public static getFinanceDeclinedRedirectUrl = (token: string): AxiosPromise => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.get(FINANCE_DECLINED_REDIRECT_URL, {}, headers);
    };

    public static createFinanceQuote = (data: IUpdateFinanceQuote): AxiosPromise<IUpdateFinanceQuoteResponse> => {
        return coreApiService.post(`${ENDPOINT_BASE}/finance-quote`, data).then((response) => {
            const responseData = response?.data;

            if (!responseData?.deposit) {
                captureException(`Failed to create finance quote with data ${data}!`);
            }

            return response;
        });
    };
    public static updateFinanceQuote = (
        data: IUpdateFinanceQuote,
        token: string
    ): AxiosPromise<IUpdateFinanceQuoteResponse> => {
        return coreApiService.patch(`${ENDPOINT_BASE}/finance-quote/${token}`, data).then((response) => {
            const responseData = response?.data;

            if (!responseData?.deposit) {
                captureException(`Failed to create finance quote with data ${data}!`);
            }

            return response;
        });
    };
    public static getFinanceQuoteById = (token: string, id: string) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.get(`digital-api/api/finance-quotes/${id}`, {}, headers);
    };
}

export default FinanceCalculatorService;
