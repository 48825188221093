import { createActionTypes } from '../createActionTypes';
import { IAction, Redux } from '../redux.interface';
import { IGlobalState, IGlobalDuck } from './global.duck.interface';
import { CarJourneyType } from '../../services/carList/carList.types';

const GLOBAL_DUCK_NAME = 'Global';

const initialState: IGlobalState = {
    carJourney: null,
    showPromoCodeError: false,
    carteGriseError: false,
    isWithScrappageDone: false,
    isTracked: false,
};

const actionTypes = createActionTypes(
    {
        set_car_journey: 'set_car_journey',
        set_promo_code_error: 'set_promo_code_error',
        set_carte_grise_error: 'set_carte_grise_error',
        set_is_with_scrappage_done: 'set_is_with_scrappage_done',
        set_is_tracked: 'set_is_tracked',
    },
    GLOBAL_DUCK_NAME
);

const reducer = (state: IGlobalState = initialState, action: IAction): IGlobalState => {
    switch (action.type) {
        case actionTypes.set_car_journey:
            const { carJourney } = action.payload;

            return {
                ...state,
                carJourney,
            };
        case actionTypes.set_promo_code_error:
            const { showPromoCodeError } = action.payload;

            return {
                ...state,
                showPromoCodeError,
            };
        case actionTypes.set_carte_grise_error:
            const { carteGriseError } = action.payload;

            return {
                ...state,
                carteGriseError,
            };
        case actionTypes.set_is_with_scrappage_done:
            return {
                ...state,
                isWithScrappageDone: action.payload,
            };
        case actionTypes.set_is_tracked:
            return {
                ...state,
                isTracked: action.payload,
            };
        default:
            return state;
    }
};

const setCarJourney = (carJourney: CarJourneyType): IAction<{ carJourney: CarJourneyType }> => {
    return {
        type: actionTypes.set_car_journey,
        payload: { carJourney },
    };
};

const setPromoCodeError = (showPromoCodeError: boolean): IAction<{ showPromoCodeError: boolean }> => {
    return {
        type: actionTypes.set_promo_code_error,
        payload: { showPromoCodeError },
    };
};

const setCarteGriseError = (carteGriseError: boolean): IAction<{ carteGriseError: boolean }> => {
    return {
        type: actionTypes.set_carte_grise_error,
        payload: { carteGriseError },
    };
};
const setIsWithScrappageDone = (isWithScrappageDone: boolean): IAction<boolean> => {
    return {
        type: actionTypes.set_is_with_scrappage_done,
        payload: isWithScrappageDone,
    };
};
const setIsTracked = (isTracked: boolean): IAction<boolean> => {
    return {
        type: actionTypes.set_is_tracked,
        payload: isTracked,
    };
};

const getOwnState = (state: Redux): IGlobalState => state[GLOBAL_DUCK_NAME];
const getCarJourney = (state: Redux): CarJourneyType => getOwnState(state).carJourney;
const getPromoCodeError = (state: Redux): boolean => getOwnState(state).showPromoCodeError;
const getCarteGriseError = (state: Redux): boolean => getOwnState(state).carteGriseError;
const getIsWithScrappageDone = (state: Redux): boolean => getOwnState(state).isWithScrappageDone;
const getIsTracked = (state: Redux): boolean => getOwnState(state).isTracked;

export const GlobalDuck: IGlobalDuck = {
    name: GLOBAL_DUCK_NAME,
    reducer,
    setCarJourney,
    setPromoCodeError,
    getPromoCodeError,
    setCarteGriseError,
    getCarteGriseError,
    getOwnState,
    getCarJourney,
    setIsWithScrappageDone,
    getIsWithScrappageDone,
    getIsTracked,
    setIsTracked,
};

export default GlobalDuck;
