import { IThemeInterface } from '../theme';
import { rem } from 'polished';
import { RecursivePartial } from '../../interfaces/RecursivePartial';

export const breakpoints = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
};

const colors = {
    white: '#f9f9f9',
};

const themeOV: RecursivePartial<IThemeInterface> = {
    fonts: {
        fontBase: 'Opel Next',
        fontEmphasized: 'Opel-Italic',
        fontSecondary: 'Opel Next',
    },
    fontSizes: {
        textTiny: rem('10px'),
        textSmall: rem('11px'),
        textBase: rem('14px'),
        textSupBase: rem('12px'),
        textSubMedium: rem('14px'),
        textMedium: rem('16px'),
        textBig: rem('18px'),
        h1: rem('36px'),
        h2: rem('30px'),
        h3: rem('24px'),
        h4: rem('18px'),
        h5: rem('14px'),
        h6: rem('12px'),
        h7: rem('10px'),
    },
    lineHeights: {
        textMedium: rem('20px'),
        h1: rem('39px'),
        h2: rem('32px'),
        h3: rem('26px'),
        h4: rem('20px'),
        h5: rem('18px'),
    },
    colors: {
        primary: '#f7ff14',
        secondary: '#A70F11',
        white: '#f9f9f9',
        pureWhite: '#ffffff',
        primaryHover: '#000000',
        primaryDisabled: '#CDCDCD',
        secondaryHover: colors.white,
        secondaryDisabled: '#CDCDCD',
        trimPageModelNameBackground: '#f7f8f8',
        lozenge: '#C90C0F',
        lozengeArrow: '#4E0230',

        grey1: '#333333',
        grey2: '#A19A99',
        grey3: '#CAC6C5',
        grey4: '#e4e2e3',
        grey5: '#262626',
        grey6: '#f1f0f0',
        grey7: '#ececee',
        grey8: '#E5E5E5',
        grey9: '#818181',
        grey10: '#F2F2F2',
        grey11: '#BFBFBF',
        grey12: '#3C3947',
        grey13: '#B4B4B4',
        grey14: '#3B3938',
        grey15: '#444444',
        grey16: '#D9D9D9',
        grey17: '#E4E2E2',
        grey18: '#CCCCCC',
        grey19: '#333333',
        grey20: '#f3f3f3',
        grey21: '#b4bbbf',
        grey22: '#888888',
        grey27: '#3b3938',
        grey28: '#666666',
        blue1: '#2885A1',
        black: '#1D1D1B',
        black2: '#000000',
        black3: '#2F2726',
        accordionSecondaryBg: '#f7ff14',
        accessibilityOutlinecolor: '#000000',
    },
    fontWeights: {
        textLight: 300,
        textRegular: 400,
        textMedium: 500,
        textBold: 600,
        textExtraBold: 800,
    },
};
export default themeOV;
