import styled from 'styled-components';
import { BrowserUpdateStyled } from '../BrowserUpdateStyled';
import { rem } from 'polished';

export const BrowserUpdateStyledAP = styled(BrowserUpdateStyled)`
    .wrapper .image {
        width: 100%;
        height: 80vh;
    }

    @media only screen and (max-width: 768px) {
        .wrapper .image {
            height: 40vh;
        }
    }

    .wrapper .description {
        text-align: center;
        padding-bottom: ${rem(40)};
    }

    .wrapper h2 {
        font-size: ${rem(25)};
        line-height: ${rem(30)};
        margin-top: ${rem(20)};
    }

    .wrapper p {
        font-size: ${({ theme }) => theme.fontSizes.textBase};
    }

    .wrapper button {
        font-size: ${({ theme }) => theme.fontSizes.textTiny};
        cursor: pointer;
        background: ${({ theme }) => theme.colors.primary};
        text-transform: uppercase;
        padding: ${rem(15)} ${rem(30)};
        border: 1px solid ${({ theme }) => theme.colors.primary};

        a {
            text-decoration: none;
            color: ${({ theme }) => theme.colors.white};
        }
    }

    .wrapper button:hover {
        color: ${({ theme }) => theme.colors.white};
        background: ${({ theme }) => theme.colors.black};
        border: 1px solid ${({ theme }) => theme.colors.black};
    }

    .logo {
        position: absolute;
        width: ${rem(100)};
        height: ${rem(100)};
        position: absolute;
        right: ${rem(30)};
        top: ${rem(10)};
    }

    .link:hover {
        opacity: 0.75;
    }

    @media (min-width: 1440px) {
        body {
            display: grid;
            grid-template-rows: auto ${rem(350)};
        }
    }
`;
