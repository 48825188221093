import { withHttpTimeout } from '@utils/httpTimeout';
import {
    DecideEndpointResponse,
    EXPERIMENTAL_FEATURES_LIST,
    EXPERIMENTATION_FEATURE_VARIANTS,
    IExperimentalFeatureFlagContext,
    ShowHideVariant,
} from './@types';
import { IncomingMessage } from 'http';
import React from 'react';
import Cookies from 'js-cookie';

type Context = {
    brand: string;
    market: string;
    locale: string;
    channel: string;
};

type GetExperimentationFeatureFlagsParams = {
    isEnabled: boolean;
    providerDomain: string;
    providerApiKey: string;
    sessionId: string;
    context: Context;
};

export const ExperimentalFeatureFlagContext = React.createContext<IExperimentalFeatureFlagContext>({});

type Cookies = {
    [key: string]: string;
};

const FEATURE_FLAGS_PROVIDER_ENDPOINT = '/decide?v=3';

const HTTP_TIMEOUT = parseInt(process.env.NEXT_PUBLIC_POSTHOG_HTTP_TIMEOUT ?? '5000', 10);

const TENANT = 'tenant';

const getCookieValue = (cookies: Cookies, key: string): string | null => {
    return cookies[key] ?? null;
};

const isValidVariant = (key: string, value: string): boolean => {
    return Object.values(EXPERIMENTATION_FEATURE_VARIANTS[key as EXPERIMENTAL_FEATURES_LIST] ?? {}).includes(
        value as ShowHideVariant
    );
};

export const isFeatureFlagEnabled = (
    name: EXPERIMENTAL_FEATURES_LIST,
    context: IExperimentalFeatureFlagContext,
    value?: any
): boolean => {
    switch (name) {
        // by default it is TRUE, as we have to make default behaviour independent (by default displayed) on experimental features
        case EXPERIMENTAL_FEATURES_LIST.TRIM_PAGE_CONTINUE_STICKY:
            return context && context[name] == ShowHideVariant.TEST_HIDDEN;

        // by default it is FALSE, as we have to make default behaviour independent on experimental features
        case EXPERIMENTAL_FEATURES_LIST.SHOW_SUMMARY_BEFORE_BASKET_MTO:
        case EXPERIMENTAL_FEATURES_LIST.SHOW_SUMMARY_BEFORE_BASKET_STOCK:
            return context && context[name] == ShowHideVariant.DEFAULT_VARIANT;

        case EXPERIMENTAL_FEATURES_LIST.SHOW_UPSELL_OPTIONS_ON_SUMMARY_PAGE:
            return context && context[name] == ShowHideVariant.DEFAULT_VARIANT;

        default:
            return !!context?.[name];
    }
};

export const getOverriddenFeatureFlags = (
    req: IncomingMessage & {
        cookies?: Record<string, string>;
    },
    featureFlags: Record<string, string>
) => {
    const cookies = (req?.cookies ?? Cookies.get()) as Cookies;

    return Object.entries(featureFlags).reduce((result, [key, value]) => {
        const cookieValue = getCookieValue(cookies, key);
        const isCookieValueValid = isValidVariant(key, cookieValue);

        return {
            ...result,
            [key]: isCookieValueValid ? cookieValue : value,
        };
    }, {});
};

export const getExperimentationFeatureFlags = async ({
    isEnabled,
    providerDomain,
    providerApiKey,
    sessionId,
    context,
}: GetExperimentationFeatureFlagsParams) => {
    if (!isEnabled) {
        return { featureFlags: {} };
    }

    try {
        const response = await fetch(`${providerDomain}${FEATURE_FLAGS_PROVIDER_ENDPOINT}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                distinct_id: sessionId,
                api_key: providerApiKey,
                groups: {
                    [TENANT]: sessionId, // Note, using the session id value so all users for a specific tenant receive different variants
                },
                group_properties: {
                    [TENANT]: context,
                },
            }),
            signal: withHttpTimeout(HTTP_TIMEOUT),
        });

        if (!response.ok) {
            throw new Error(`Error fetching feature flags.`);
        }

        const { featureFlags = {} } = (await response.json()) as DecideEndpointResponse;

        return {
            featureFlags,
        };
    } catch (error: any) {
        console.error(`[Experiments]: Unable to get experiments - ${error?.message ?? 'Unknown'}`);

        return {
            featureFlags: {},
        };
    }
};
