import { SPC } from 'js-sdk';
import { SDK_CONFIG } from './constants/sdk-config';

export interface IProvider {
    createInput: (val?: any) => any;
    getInputResults: (input: any) => any;
}

SPC.config().override(SDK_CONFIG);

export const carDetailProvider: IProvider = SPC.getCarDetailProvider();
export const featureListProvider: IProvider = SPC.getFeatureListProvider();
export const filterProvider: IProvider = SPC.getFilterProvider();
export const carExtraOptionsProvider: IProvider = SPC.getCarOptionsProvider();
export const carListProvider: IProvider = SPC.getCarListProvider();
export const carSsmProvider: IProvider = SPC.getCarSsmProvider();
