import { IThemeInterface } from '../theme';
import { rem } from 'polished';
import { RecursivePartial } from '../../interfaces/RecursivePartial';

export const breakpoints = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200,
};

const colors = {
    white: '#fff',
};

const themeAC: RecursivePartial<IThemeInterface> = {
    fonts: {
        fontBase: 'CitroenType',
        fontSecondary: 'Citroen',
        fontTertiary: 'Peugeot',
    },
    fontSizes: {
        h1: rem('40px'),
        h2: rem('24px'),
        h3: rem('20px'),
        h4: rem('16px'),
        h5: rem('14px'),
        h6: rem('12px'),
        t28: rem(28),
    },
    lineHeights: {
        h1: rem('44px'),
        h2: rem('28px'),
        h3: rem('23px'),
        h4: rem('18px'),
        h5: rem('16px'),
    },
    colors: {
        primary: '#9D0605',
        secondary: '#EB6428',
        white: '#fff',
        primaryHover: colors.white,
        primaryDisabled: '#CDCDCD',
        secondaryHover: colors.white,
        secondaryDisabled: '#CDCDCD',
        trimPageModelNameBackground: '#f7f8f8',
        lozenge: '#EB6428',
        lozengeArrow: '#4E0230',

        grey1: '#796F6E',
        grey2: '#A19A99',
        grey3: '#CAC6C5',
        grey4: '#e4e2e3',
        grey5: '#3b3938',
        grey6: '#f1f0f0',
        grey7: '#ececee',
        grey8: '#E4E2E2',
        grey9: '#D9D9D9',
        grey10: '#848484',
        grey11: '#818181',
        grey12: '#3C3947',
        grey14: '#bfbfbf',
        grey15: '#f5f5f5',
        blue1: '#2885A1',
        blue2: '#57718a',
        blue3: '#9dc7e0',
        black: '#000000',
        black1: '#162233',
        black2: '#2F2726',
        red: '#da291c',
        focus: '#4766ff',
        champagne1: '#F1F0F0',
        orange1: '#eb6428',

        accordionSecondaryBg: colors.white,
        accessibilityOutlinecolor: '#9D0605',
    },
    fontWeights: {
        textLight: 300,
        textRegular: 400,
        textMedium: 500,
        textBold: 700,
        textExtraBold: 800,
    },
};
export default themeAC;
