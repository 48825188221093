import { IPageTitleDescriptionProperties } from '../interfaces/Page';
import { IConfigurableCar } from '../interfaces/Car';
import { IVehicleTransformed } from '../services/stock/models/stockItems.service.model';

export const mapBrandStringToNumericCode = (brand: string): number => {
    switch (brand) {
        case 'AP':
            return 2;
        case 'AC':
            return 4;
        case 'DS':
            return 5;
        case 'OV':
            return 6;
        default:
            return 0;
    }
};

export const setPageTitleDescriptionProperties = (
    brand: string,
    car: IConfigurableCar | IVehicleTransformed
): IPageTitleDescriptionProperties => ({
    brand,
    model: car?.model?.title,
    motorization: car?.engine?.title,
    gearbox: car?.gearbox?.title,
    bodyStyle: car?.bodyStyle?.title,
    trimLevel: car?.specPack?.title,
});

export const removeLastCharacterFromPathname = (string: string, character: string) => {
    const lastCharacter = string.substring(string.length, string.length - 1);
    if (lastCharacter === character) {
        return string.substring(0, string.length - 1)
    }

    return string;
};
