import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { StringMapping } from '../../interfaces/StringMapping';
import StorageService from './storage.service';
import { IS_SERVER } from '../../constants/main';
import { getDefaultHeaders } from '../apiLayer/apiLayer.utils';

const URL_PREFIXES = {
    DIGITAL_API: '/digital-api',
    FINANCE_CALCULATOR_API: '/fc',
    SPC_API: '/spc-api',
    DEALER_API: '/dealer',
    PART_EXCHANGE_API: '/px',
};

const SERVER_SIDE_URL_PREFIX_MAP = IS_SERVER
    ? {
          [URL_PREFIXES.DIGITAL_API]: process.env.NEXT_SERVER_INTERNAL_DIGITAL_API_URL,
          [URL_PREFIXES.FINANCE_CALCULATOR_API]: process.env.NEXT_SERVER_INTERNAL_FINANCE_CALCULATOR_API_URL,
          [URL_PREFIXES.SPC_API]: process.env.NEXT_SERVER_INTERNAL_SPC_API_URL,
          [URL_PREFIXES.DEALER_API]: process.env.NEXT_SERVER_INTERNAL_DEALER_API_URL,
          [URL_PREFIXES.PART_EXCHANGE_API]: process.env.NEXT_SERVER_INTERNAL_PART_EXCHANGE_API_URL,
      }
    : {};

// Polyfill btoa for Axios Auth
if (IS_SERVER) {
    global.Buffer = global.Buffer || require('buffer').Buffer;

    if (typeof btoa === 'undefined') {
        global.btoa = function (str) {
            return new Buffer(str, 'binary').toString('base64');
        };
    }

    if (typeof atob === 'undefined') {
        global.atob = function (b64Encoded) {
            return new Buffer(b64Encoded, 'base64').toString('binary');
        };
    }
}

// Disabled axiosLoggerService for FE because it is getting attached not only to this instance,
// but also to the global one, which is then causing issues in form.service.ts with the axios-jsonp adapter
// For now there is no reason to have axiosLoggerService on FE as we don't have sentry anyways..
let axiosDebug: any;
let axiosLoggerService: any;

if (IS_SERVER) {
    // Don't even require these on the frontend
    const debugLibrary = require('debug');
    const { createAxiosLoggerService } = require('../logger/AxiosLoggerService');
    axiosDebug = debugLibrary('axios:core-api-service');
    //axiosLoggerService = createAxiosLoggerService();
}

class CoreAPIService {
    axiosInstance: AxiosInstance;

    API = process.env.NEXT_PUBLIC_API_URL;
    API_BASIC_AUTH_USERNAME = process.env.NEXT_SERVER_API_BASIC_AUTH_USERNAME;
    API_BASIC_AUTH_PASSWORD = process.env.NEXT_SERVER_API_BASIC_AUTH_PASSWORD;

    constructor() {
        // When developing locally against testing environment, you will need the basic auth option
        if (IS_SERVER && this.API_BASIC_AUTH_USERNAME && this.API_BASIC_AUTH_PASSWORD) {
            this.axiosInstance = axios.create({
                auth: {
                    username: this.API_BASIC_AUTH_USERNAME,
                    password: this.API_BASIC_AUTH_PASSWORD,
                },
            });
        } else {
            this.axiosInstance = axios.create();
        }

        if (IS_SERVER) {
            //axiosLoggerService.addLogger(this.axiosInstance, axiosDebug);
            axiosDebug('Axios logger has been initialized.');
        }
    }

    getToken() {
        return StorageService.getToken();
    }

    /**
     * If we are on the server, try to use SSR url from the SSR url map instead of the public one
     */
    getFinalUrl(url: string) {
        if (IS_SERVER) {
            const match = url.match(/^(.*?)\/(.*)/);
            if (match) {
                const prefix = '/' + match[1];
                const urlWithoutPrefix = match[2];
                const serverSidePrefix = SERVER_SIDE_URL_PREFIX_MAP[prefix];
                if (serverSidePrefix) {
                    return `${serverSidePrefix}/${urlWithoutPrefix}`;
                }
            }
        }

        return `${this.API}/${url}`;
    }

    async get<TResult = any>(
        url: string,
        params = {},
        headers: StringMapping<string> = {}
    ): Promise<AxiosResponse<TResult>> {
        headers = {
            ...getDefaultHeaders(),
            ...headers,
        };
        return await this.axiosInstance.request({
            method: 'get',
            url: this.getFinalUrl(url),
            headers,
            params,
        });
    }

    async post(
        url: string,
        data: StringMapping<any>,
        headers: StringMapping<string> = {},
        axiosConfig: AxiosRequestConfig = {}
    ): Promise<AxiosResponse<any>> {
        headers = {
            ...getDefaultHeaders(),
            ...headers,
        };
        return await this.axiosInstance.request({
            ...axiosConfig,
            method: 'post',
            url: this.getFinalUrl(url),
            data,
            headers,
        });
    }

    async put(url: string, data: StringMapping<any>, headers: StringMapping<string> = {}): Promise<AxiosResponse<any>> {
        headers = {
            ...getDefaultHeaders(),
            ...headers,
        };
        return await this.axiosInstance.request({
            method: 'put',
            url: this.getFinalUrl(url),
            data,
            headers,
        });
    }

    async patch(
        url: string,
        data: StringMapping<any>,
        headers: StringMapping<string> = {}
    ): Promise<AxiosResponse<any>> {
        headers = {
            ...getDefaultHeaders(),
            ...headers,
        };
        return await this.axiosInstance.request({
            method: 'patch',
            url: this.getFinalUrl(url),
            data,
            headers,
        });
    }

    async delete(url: string, data: StringMapping<any>, headers: StringMapping<any> = {}): Promise<AxiosResponse<any>> {
        headers = {
            ...getDefaultHeaders(),
            ...headers,
        };
        return await this.axiosInstance.request({
            method: 'delete',
            url: this.getFinalUrl(url),
            data,
            headers,
        });
    }
}

export default new CoreAPIService();
