import { IDefaultConfigurationData, IOffer, IOfferItem, IOfferItemBase } from '../interfaces/IOfferItem';
import { ICarOffer, IConfigurableCar } from '../interfaces/Car';
import { PaymentJourneyTypes } from '../partExchange/interfaces/Default';

export const sortOffersDescByPriority = (a: IOfferItem | IConfigurableCar, b: IOfferItem | IConfigurableCar) =>
    Number(
        b.offers.sort(
            (offerA: IOffer | ICarOffer, offerB: IOffer | ICarOffer) => offerB?.priority - offerA?.priority
        )?.[0]?.priority
    ) -
    Number(
        a.offers.sort(
            (offerA: IOffer | ICarOffer, offerB: IOffer | ICarOffer) => offerB?.priority - offerA?.priority
        )?.[0]?.priority
    );

export const transformStockOfferItem = ({
    topVehicle,
    cheapestCarImage,
    totalVehiclesCount,
    model,
    bodyStyle,
}: IOfferItemBase) => ({
    _id: `${model.groupCode}${bodyStyle.groupCode}`,
    ...topVehicle,
    totalVehiclesCount,
    images: [{ ...cheapestCarImage }],
});

export const findDefaultConfiguration = (
    offers: IOffer[],
    paymentJourney: PaymentJourneyTypes = PaymentJourneyTypes.FINANCE
): IDefaultConfigurationData | null => {
    const defaultConfigurationOffer =
        offers?.find((offer) => offer.defaultConfiguration?.[paymentJourney])?.defaultConfiguration?.[paymentJourney] ??
        null;
    // check for pricesV2 property to really know that the default configuration is available
    return defaultConfigurationOffer?.pricesV2 ? defaultConfigurationOffer : null;
};

export const createExternalIdFromDefaultConfiguration = (defaultConfig: IDefaultConfigurationData): string => {
    return `${defaultConfig.lcdv16}+${defaultConfig.exteriorColourCode}+${defaultConfig.interiorColourCode}`;
};
