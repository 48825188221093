import { css } from '../../theme';
import fonts from './fonts';

const main = css`
    ${fonts}

    body {
        background-color: #f2f2f2;

        .buorg {
            font-family: 'Vauxhall Neue', 'Arial', 'sans-serif' !important;
        }

        .buorg::before {
            background-color: #999999;
        }

        .buorg-pad {
            font-size: 18px !important;
        }

        #buorgul,
        #buorgpermanent {
            background-color: #eb6428;
        }

        #buorgul:hover,
        #buorgpermanent:hover {
            background-color: #e1412d;
        }

        #buorgig {
            border: 1px solid #e1412d;
            background: #fff;
            color: #e1412d;
        }

        #buorgig:hover {
            border: 1px solid #eb6428;
            color: #eb6428;
        }
    }

    .sectionTitle {
        font-size: 24px !important;
    }
`;

export default main;
