// eslint-disable-next-line no-restricted-imports
import { useRouter } from 'next/router';
import { extractRouteFromUrl, removeBasePath } from '@utils/url.utils';

interface UrlObject {
    auth?: string;
    hash?: string;
    host?: string;
    hostname?: string;
    href?: string;
    path?: string;
    pathname?: string;
    protocol?: string;
    search?: string;
    slashes?: boolean;
    port?: string | number;
    query?: string | null | { [key: string]: any };
}

export const useCustomRouter = () => {
    const { push: routerPush, replace: routerReplace, ...rest } = useRouter();
    rest.asPath = removeBasePath(rest.asPath);

    const createCustomRouterFunction = (
        routerFn: (url: UrlObject | string, as?: UrlObject | string, options?: {}) => Promise<boolean>
    ) => (url: string | UrlObject, options?: { shallow?: boolean; scroll?: boolean }) => {
        if (typeof url === 'object') {
            const route = extractRouteFromUrl(url.pathname);
            const fullPath = `${route}`;

            return routerFn({ ...url, pathname: fullPath }, url, options);
        } else {
            const route = extractRouteFromUrl(url);
            const query = url.indexOf('?') >= 0 ? url.substring(url.indexOf('?'), url.length) : '';
            const hashParams = rest.asPath.match(/#([a-z0-9]+)/gi);
            const fullPath = `${route}${query}${hashParams ? hashParams.join('') : ''}`;

            return routerFn(fullPath, url, options);
        }
    };
    return {
        ...rest,
        push: createCustomRouterFunction(routerPush),
        replace: createCustomRouterFunction(routerReplace),
    };
};

export default useCustomRouter;
