import { ISelectOption } from './GeoLocationInputTemplate';
import { ICoordinates } from './index';

export const createOption = (value: number, t: (key: string) => string): ISelectOption => {
    const valueWithUnit = `${value} ${t('geoLocationInput.distanceRadiusOption')}`;
    return {
        value,
        label: `${value === 0 ? t('geoLocationInput.allVehicles') : valueWithUnit}`,
    };
};

export const generateOptions = (options: [], t: (key: string) => string): ISelectOption[] => {
    const sortAsc = ({ value: a }: ISelectOption, { value: b }: ISelectOption) => a - b;
    return options.map(option => createOption(option, t)).sort(sortAsc);
};

export const validateCoords = (coords: ICoordinates | void, t: (key: string) => string) => {
    if (!coords || !coords.lat || !coords.lng) {
        throw new Error(t('GeoLocationInput.coordsNotFound'));
    }
};

export const formatCoordsString = (lat: number, lng: number) =>
    `${Math.round(lat * 100) / 100}, ${Math.floor(lng * 100) / 100}`;
