import { createActionTypes } from '../createActionTypes';
import { IAction } from '../redux.interface';
import { INavigationState, INavigationDuck } from './navigation.duck.interface';

const NavigationDuck: any = {
    name: 'Navigation',
};

export const headerConfig = {
    defaultConfig: {
        cartIcon: {
            isDisabled: false,
            nrOfProducts: 1,
            url: '#',
        },
        hasReturnGuaranteeBlock: true,
    },
    confirmationConfig: {
        accountIcon: {
            isDisabled: false,
            url: '/my-account',
        },
        cartIcon: {
            isDisabled: false,
            nrOfProducts: 1,
            url: '#',
        },
        hasReturnGuaranteeBlock: true,
    },
};

const initialState: INavigationState = {
    steps: [],
    stepsNumber: 0,
    currentStepIndex: 0,
    currentSubStepIndex: 0,
    journey: '',
    substep: '',
    headerConfig: headerConfig.defaultConfig,
    paymentStatus: null,
    hasBasketBeenClicked: false,
    orderNumber: null,
    redirectUrl: '',
    modelRedirectUrl: '',
    mopId: null,
};

const actionTypes: any = createActionTypes(
    {
        LOAD_STEPS: 'LOAD_STEPS',
        SET_REDIRECT_URL: 'SET_REDIRECT_URL',
        SET_PAYMENT_STATUS: 'SET_PAYMENT_STATUS',
        SET_ORDER_NUMBER: 'SET_ORDER_NUMBER',
        SET_BASKET_REDIRECT_URL: 'SET_BASKET_REDIRECT_URL',
    },
    NavigationDuck.name
);

NavigationDuck.reducer = (state: INavigationState = initialState, action: IAction): INavigationState => {
    switch (action.type) {
        case actionTypes.SET_BASKET_REDIRECT_URL:
            return {
                ...state,
                modelRedirectUrl: action.payload.url,
            };
        case actionTypes.SET_REDIRECT_URL:
            return {
                ...state,
                redirectUrl: action.payload.url,
                mopId: action.payload.mopId,
            };
        case actionTypes.SET_PAYMENT_STATUS:
            return {
                ...state,
                paymentStatus: action.payload,
            };
        case actionTypes.SET_ORDER_NUMBER:
            return {
                ...state,
                orderNumber: action.payload,
            };
        default:
            return state;
    }
};

NavigationDuck.loadSteps = (journey: string, step: string, substep: string): IAction => ({
    type: actionTypes.LOAD_STEPS,
    payload: { journey, step, substep },
});

NavigationDuck.setRedirectUrl = (url: string, mopId: number | null): IAction => {
    return {
        type: actionTypes.SET_REDIRECT_URL,
        payload: { url, mopId },
    };
};

NavigationDuck.setModelRedirectUrl = (url: string): IAction => {
    return {
        type: actionTypes.SET_BASKET_REDIRECT_URL,
        payload: { url },
    };
};

NavigationDuck.setPaymentStatus = (paymentStatus: string): IAction => ({
    type: actionTypes.SET_PAYMENT_STATUS,
    payload: paymentStatus,
});

NavigationDuck.setOrderNumber = (orderNumber: number): IAction => {
    return {
        type: actionTypes.SET_ORDER_NUMBER,
        payload: orderNumber,
    };
};

NavigationDuck.getOwnState = (state: any): INavigationState => state[NavigationDuck.name] || initialState;

export default NavigationDuck as INavigationDuck;
