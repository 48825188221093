import Cookies from 'js-cookie';
import { BRAND, isMarketGB, isBrandOV } from '../constants/main';
import {
    IS_DEV,
    LANGUAGE_KEY,
    PROMO_CODE_KEY,
    SESSION_COOKIE_NAME,
    SESSION_COOKIE_NAME_EXPERIMENTS,
    SESSION_DOMAIN,
    SESSION_LIFETIME,
    SESSION_REQUEST_HEADER_NAME,
} from '../constants/main';

export const setSessionIdCookie: (cookieValue: string) => void = (cookieValue) => {
    const cookieLifetime: number = (parseInt(SESSION_LIFETIME, 10) / 24 / 3600) * 30;

    Cookies.set(SESSION_COOKIE_NAME, cookieValue, {
        domain: SESSION_DOMAIN,
        expires: cookieLifetime,
        secure: !IS_DEV,
    });
};

export const setSessionIdForPosthog: () => string = () => {
    const cookieLifetime: number = (parseInt(SESSION_LIFETIME, 10) / 24 / 3600) * 30;

    function randomHash() {
        const hashLength = 32;
        let pool = '';

        pool = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        let buf = '';

        for (let i = 0; i < hashLength; i++) {
            buf += pool.charAt(Math.floor(Math.random() * pool.length));
        }

        return buf;
    }

    const hashValue = randomHash();
    Cookies.set(SESSION_COOKIE_NAME_EXPERIMENTS, hashValue, {
        domain: !IS_DEV ? SESSION_DOMAIN : '',
        expires: cookieLifetime,
        secure: !IS_DEV,
    });

    return hashValue;
};

export const getSessionIdCookie = () =>
    process.env.NODE_ENV === 'development' &&
    process.env[`NEXT_PUBLIC_LOGIN_CREDS_${isMarketGB && isBrandOV ? `OVGB` : BRAND}`]
        ? process.env[`NEXT_PUBLIC_LOGIN_CREDS_${isMarketGB && isBrandOV ? `OVGB` : BRAND}`]
        : Cookies.get(SESSION_COOKIE_NAME);
export const getSessionIdForPosthog: () => string = () => {
    const tokenString = Cookies.get(SESSION_COOKIE_NAME_EXPERIMENTS);
    return tokenString?.length ? tokenString : setSessionIdForPosthog();
};
export const getLastUpdateSetting = () => Cookies.get('LAST_UPDATE_SETTINGS');
export const setLastUpdateSetting: () => void = () => {
    const cookieLifetime: number = (parseInt(SESSION_LIFETIME, 10) / 24 / 3600) * 30;

    Cookies.remove('LAST_UPDATE_SETTINGS', {
        domain: SESSION_DOMAIN,
    });
    Cookies.set('LAST_UPDATE_SETTINGS', String(Date.now()), {
        expires: cookieLifetime,
        domain: SESSION_DOMAIN,
        sameSite: 'strict',
        secure: !IS_DEV,
    });
};

export const setPromoCodeCookie: (promoCode: string) => void = (promoCode) => {
    const cookieLifetime: number = (parseInt(SESSION_LIFETIME, 10) / 24 / 3600) * 30;

    Cookies.set(PROMO_CODE_KEY, promoCode, {
        domain: SESSION_DOMAIN,
        expires: cookieLifetime,
        sameSite: 'strict',
        secure: !IS_DEV,
    });
};

export const getPromoCodeCookie = () => Cookies.get(PROMO_CODE_KEY);

export const setLanguageCookie: (lang: string) => void = (lang) => {
    const cookieLifetime: number = (parseInt(SESSION_LIFETIME, 10) / 24 / 3600) * 30;

    Cookies.set(LANGUAGE_KEY, lang, {
        domain: SESSION_DOMAIN,
        expires: cookieLifetime,
        sameSite: 'strict',
        secure: !IS_DEV,
    });
};

export const getLanguageCookie = () => Cookies.get(LANGUAGE_KEY);

export const removePromoCodeCookie = () =>
    Cookies.remove(PROMO_CODE_KEY, {
        domain: SESSION_DOMAIN,
    });

export const getSessionHeaders = () => {
    const sessionCookie: string = getSessionIdCookie();
    const sessionIdToken: { [key: string]: string } = {};

    if (sessionCookie) {
        sessionIdToken[SESSION_REQUEST_HEADER_NAME] = sessionCookie;
    }

    return sessionIdToken;
};
