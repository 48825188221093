import { createActionTypes } from '../createActionTypes';
import { IAction, Redux } from '../redux.interface';
import { IduckState, ITrimSelectorDuck, ITrimItem, ITrimConfigurations } from './trimSelector.duck.interface';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import { ENGINE_GEARBOX_FUEL_SLUG } from '../../services/core/sdk/constants/sdkFields';
import { STOCK_TRIMS_PER_PAGE } from '../../constants/main';
import { IVehicleTransformed } from '../../services/stock/models/stockItems.service.model';
import { IOfferItemBase } from '../../interfaces/IOfferItem';

const TRIM_SELECTOR_DUCK_NAME = 'TrimSelector';

const initialState: IduckState = {
    configurable: {
        trims: [],
        filteredTrims: [],
        trimConfigurations: {},
        isTrimCustomized: false,
    },
    stock: {
        currentPageNumber: 0,
        totalItems: 0,
        totalFilteredItems: 0,
        stockTrims: [],
        filteredStockTrims: [],
        itemsPerPage: STOCK_TRIMS_PER_PAGE,
        dataLoadError: null,
    },
};
const actionTypes: any = createActionTypes(
    {
        set_trims: 'set_trims',
        set_filtered_trims: 'set_filtered_trims',
        set_trim_configurations: 'set_trim_configurations',
        set_stock_trims: 'set_stock_trims',
        set_more_stock_trims: 'set_more_stock_trims',
        set_stock_dataLoadError: 'set_stock_dataLoadError',
        set_is_trim_customized: 'set_is_trim_customized',
    },
    TRIM_SELECTOR_DUCK_NAME
);

const reducer = (state: IduckState = initialState, action: IAction): IduckState => {
    switch (action.type) {
        case actionTypes.set_trims:
            return {
                ...state,

                configurable: { ...state.configurable, trims: [...action.payload] },
            };
        case actionTypes.set_filtered_trims:
            return {
                ...state,

                configurable: { ...state.configurable, filteredTrims: [...action.payload] },
            };
        case actionTypes.set_trim_configurations:
            return {
                ...state,

                configurable: {
                    ...state.configurable,
                    trimConfigurations: mapValues(groupBy(action.payload, 'specPackSlug'), (configurations) => ({
                        engines: uniqBy(map(configurations, 'engine'), 'id'),
                        gearboxes: uniqBy(map(configurations, 'gearbox'), 'id'),
                        exteriorColours: uniqBy(map(configurations, 'exteriorColour'), 'id'),
                        engineGearboxSlugs: uniq(map(configurations, ENGINE_GEARBOX_FUEL_SLUG)),
                    })),
                },
            };

        case actionTypes.set_stock_trims:
            return {
                ...state,
                stock: {
                    ...state.stock,
                    stockTrims: action.payload.stockTrims,
                    currentPageNumber: action.payload.pageNumber,
                    filteredStockTrims: action.payload.filteredStockTrims,
                    totalFilteredItems: action.payload.totalFilteredItems,
                    totalItems: action.payload.totalItems,
                    dataLoadError: null,
                },
            };

        case actionTypes.set_more_stock_trims:
            return {
                ...state,
                stock: {
                    ...state.stock,
                    filteredStockTrims: state.stock.filteredStockTrims.concat(action.payload.filteredStockTrims),
                    stockTrims: state.stock.stockTrims.concat(action.payload.stockTrims),
                    currentPageNumber: action.payload.pageNumber,
                    totalItems: action.payload.totalItems,
                    totalFilteredItems: action.payload.totalFilteredItems,
                    dataLoadError: null,
                },
            };

        case actionTypes.set_stock_dataLoadError:
            return { ...state, stock: { ...state.stock, dataLoadError: action.payload } };

        default:
            return state;
    }
};

const setAllTrims = (trims: ITrimItem[] = []): IAction => ({
    type: actionTypes.set_trims,
    payload: trims,
});

const setFilteredTrims = (trims: ITrimItem[] = []): IAction => ({
    type: actionTypes.set_filtered_trims,
    payload: trims,
});

const setTrimConfigurations = (trimConfigurations: Partial<ITrimItem>[] = []): IAction => ({
    type: actionTypes.set_trim_configurations,
    payload: trimConfigurations,
});

const setStockTrims = (payload: {
    stockTrims: IOfferItemBase[];
    totalItems: number;
    pageNumber: number;
    filteredStockTrims: IOfferItemBase[];
    totalFilteredItems: number;
}): IAction => ({
    type: actionTypes.set_stock_trims,
    payload,
});

const setMoreStockTrims = (payload: {
    filteredStockTrims: IVehicleTransformed[];
    stockTrims: IVehicleTransformed[];
    totalItems: number;
    totalFilteredItems: number;
    pageNumber: number;
}): IAction => ({
    type: actionTypes.set_more_stock_trims,
    payload,
});

const setStockDataLoadError = (error: string) => ({
    type: actionTypes.set_stock_dataLoadError,
    payload: error,
});

const setIsTrimCustomized = (isCustomized: boolean) => ({
    type: actionTypes.set_is_trim_customized,
    payload: isCustomized,
});

const getOwnState = (state: Redux): IduckState => state[TRIM_SELECTOR_DUCK_NAME] || initialState;

const getFilteredTrims = (state: Redux): ITrimItem[] => getOwnState(state).configurable.filteredTrims;

const getFilteredOutTrims = (state: Redux): ITrimItem[] => {
    const trims = getOwnState(state).configurable.trims;
    const filteredTrims = getOwnState(state).configurable.filteredTrims;

    return trims.filter((trim: any) => {
        return !filteredTrims.find((filteredTrim: any) => trim._id === filteredTrim._id);
    });
};

const getFilteredStockTrims = (state: Redux): IVehicleTransformed[] => getOwnState(state).stock.filteredStockTrims;
const getFilteredStockTrimsCount = (state: Redux): number => getOwnState(state).stock.totalItems;

const getFilteredOutStockTrims = (state: Redux): IVehicleTransformed[] => {
    const trims = getOwnState(state).stock.stockTrims;
    const filteredTrims = getOwnState(state).stock.filteredStockTrims;

    return []; // TODO
};

const getAllConfigurableTrims = (state: Redux): ITrimItem[] => getOwnState(state).configurable.trims;

const getTrimConfigurations = (state: Redux): ITrimConfigurations => getOwnState(state).configurable.trimConfigurations;

const isTrimCustomized = (state: Redux): boolean => getOwnState(state).configurable.isTrimCustomized;

export const TrimSelectorDuck: ITrimSelectorDuck = {
    name: TRIM_SELECTOR_DUCK_NAME,
    reducer,
    setTrimConfigurations,
    setFilteredTrims,
    setAllTrims,
    getOwnState,
    getAllConfigurableTrims,
    getFilteredTrims,
    getFilteredOutTrims,
    getFilteredStockTrims,
    getFilteredStockTrimsCount,
    getFilteredOutStockTrims,
    getTrimConfigurations,
    setStockTrims,
    setMoreStockTrims,
    setStockDataLoadError,
    setIsTrimCustomized,
    isTrimCustomized,
};

export default TrimSelectorDuck;
