import coreApiService from '../core/coreApi.service';

export class HashService {
    public static getHash: () => Promise<any> = () => {
        const headers = {};
        return coreApiService.get(`spc-api/api/v1/import/hash`, {}, headers);
    };
}

export default HashService;
