import { useEffect, useState } from 'react';
import Router from 'next/router';

export const useRoutingInProgress = () => {
    const [routingInProgress, setRoutingInProgress] = useState(false);

    useEffect(() => {
        const handleRoutingStarted = () => setRoutingInProgress(true);
        const handleRoutingStopped = () => setRoutingInProgress(false);

        Router.events.on('routeChangeStart', handleRoutingStarted);
        Router.events.on('routeChangeComplete', handleRoutingStopped);
        Router.events.on('routeChangeError', handleRoutingStopped);

        return () => {
            Router.events.off('routeChangeStart', handleRoutingStarted);
            Router.events.off('routeChangeComplete', handleRoutingStopped);
            Router.events.off('routeChangeError', handleRoutingStopped);
        };
    }, []);

    return routingInProgress;
};
