import styled from 'styled-components';
import { MaintenanceStyled } from '../MaintenanceStyled';
import { rem } from 'polished';

export const MaintenanceStyledDS = styled(MaintenanceStyled)`
    margin: 0;
    text-align: center;
    padding: 0;
    font-family: 'DS Automobiles', sans-serif;
    color: ${({ theme }) => theme.colors.black1};

    .dsMaintenanceWrapper {
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .dsMaintenanceBgImage {
            width: 100%;
            height: 75vh;
            display: block;
            background-position: center center;
            background-size: cover;
            background-image: url('/public/DS/maintanence_desktop.jpg');
            background-repeat: no-repeat;
        }

        .dsMaintenanceContent {
            height: 25vh;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > .dsMaintenanceInnerContent {
                p {
                    margin-bottom: 35px;
                    margin-top: 15px;
                    max-width: ${rem('766px')};
                }

                a {
                    padding: ${rem('15px')} ${rem('24px')};
                    border-radius: ${rem('24px')};
                    background-color: #1a1b1b;
                    color: white;
                    text-decoration: none;

                    &:hover,
                    &:focus-visible {
                        background: ${({ theme }) => theme.colors.primary};
                    }
                }

                a,
                p {
                    font-family: 'DSAutomobiles';
                }
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .dsMaintenanceBgImage {
            height: 70vh;
            background-image: url('/public/DS/maintenence_mobile.jpg');
        }

        .dsMaintenanceContent {
            height: 30vh;
            padding: unset;
            width: 100%;
            align-items: center;

            h2 {
                line-height: normal;
                max-width: ${rem('200px')};
                margin-top: ${rem('-5px')};
            }

            > .dsMaintenanceInnerContent {
                p {
                    margin-top: ${rem('-12px')};
                }
            }
        }
    }
`;
