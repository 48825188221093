import coreApiService from '../core/coreApi.service';
import { IUser } from '../../interfaces/User';
import { BRAND, SESSION_REQUEST_HEADER_NAME } from '../../constants/main';

export const ACCESS_DENIED_ERROR_MESSAGE = 'Access denied.';

class UserServices {
    public static getUserData = () => {
        return coreApiService.get(`digital-api/brandid/userdata/${BRAND.toLowerCase()}`);
    };

    public static validateEmail = (email: string) => {
        return coreApiService.get(`digital-api/brandid/check-email/${email}`, {});
    };

    public static userDetails = (token: string) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.get(`digital-api/api/customers/details`, {}, headers);
    };

    public static updateUser = (token: string, user: IUser) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.put(`digital-api/api/customers/details`, user, headers);
    };

    public static userAdress = (token: string) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.get(`digital-api/api/customers/address`, {}, headers);
    };

    public static updateAddress = (token: string, address: any) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.put(`digital-api/api/customers/address`, address, headers);
    };
    public static fetchMyOrdersTotal = (token: string) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.get(`digital-api/api/orders/total`, {}, headers).then(data => data).catch(error => error);
    };

    public static fetchMySavesTotal = (token: string) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.get(`digital-api/api/deals/total`, {}, headers).then(data => data).catch(error => error);
    };

    public static fetchMySaves = (token: string, page: number, perPage: number) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.get(`digital-api/api/deals?page=${page}&itemsPerPage=${perPage}`, {}, headers);
    };

    public static fetchMySave = (token: string, dealToken: string) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.get(`digital-api/api/deals?token=${dealToken}`, {}, headers);
    };

    public static fetchMyOrders = (token: string, page: number, perPage: number) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.get(`digital-api/api/orders?page=${page}&itemsPerPage=${perPage}`, {}, headers);
    };

    public static sendDataToCRM = (token: string, crmData: any) => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        return coreApiService.post(`digital-api/api/customer-first/lead`, crmData, headers);
    };
}

export default UserServices;
