import { IPxValuation } from '../interfaces/PxWidget';
import AssetManager from '@utils/externalDependencies/AssetManager';
import { PX_STATUSES_APPROVED, pxWidgetConfig } from '../constants/config';
import { AUTOBIZ_WIDGET_URL_V1, AUTOBIZ_WIDGET_URL_V2, DependencyType } from '@utils/externalDependencies/dependencies';
import { PxVersion } from '../../constants/main';
import { setModalButtonEnabled, throwGlobalError } from '../store/actions';
import { captureException } from '@utils/sentry.utils';
/**
 * Method returns px valuation after it's being processed in order to be displayed in px widget
 * @param valuationJSON
 * NOTE: tested
 */
export function processValuation(valuationJSON: any): IPxValuation {
    const {
        Valuation,
        CarDetails: { regNumber, makeName, modelName },
        bonusAmount,
        statusId,
        mopId,
    }: any = JSON.parse(valuationJSON);

    return {
        statusId,
        price: Valuation.estimateValuationApplied,
        licensePlate: regNumber,
        makeName,
        modelName,
        bonusAmount,
        mopId,
    };
}

/**
 * Method returns px valuation after it's being processed in order to be displayed in px widget
 * @param mopData
 * NOTE: tested
 */
export function processMopData(mopData: any): IPxValuation {
    const parsedCarDetailsMopData = mopData?.mopData ? JSON.parse(mopData?.mopData) : null;

    return {
        id: mopData?.id,
        statusId: mopData?.statusId,
        price: PX_STATUSES_APPROVED?.includes(mopData?.statusId)
            ? mopData?.firmValuation
            : mopData?.valuation?.engagementValuationApplied,
        mopId: mopData?.mopId,
        licensePlate: mopData?.carDetails?.regNumber,
        makeName: mopData?.carDetails?.makeName,
        modelName: mopData?.carDetails?.modelName,
        bonusAmount: mopData?.valuation?.bonusAmount,
        externalReferenceId: mopData?.externalReferenceId,
        vin: parsedCarDetailsMopData?.object?.CarDetails?.vin,
        registrationDate: parsedCarDetailsMopData?.object?.CarDetails?.firstRegistration,
        overallMilleage: parsedCarDetailsMopData?.object?.CarDetails?.CarState?.overallMileage,
    };
}

/**
 * Method returns true if simple valuation has data on widgetData
 * @param widgetData
 * NOTE: tested
 */
export function noSimpleValuation(widgetData: any): boolean {
    if (widgetData) {
        return !widgetData.simpleValuation || (widgetData.simpleValuation && !widgetData.simpleValuation.price);
    }

    return false;
}

export function isEmptyObject(obj: object): boolean {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
}

export function getDeviceTypeByWidthOfBrowser(): string {
    const MOBILE_BREAKPOINT: number = 480;
    const TABLET_BREAKPOINT: number = 768;

    if (window?.innerWidth < MOBILE_BREAKPOINT) {
        return 'mobile';
    }
    if (window?.innerWidth >= MOBILE_BREAKPOINT && window?.innerWidth < TABLET_BREAKPOINT) {
        return 'tablet';
    }

    return 'desktop';
}

export function addAssetScripts(domain: string, pxVersion: PxVersion, dispatch?: any) {
    return AssetManager.instance
        .then(async () => {
            // Load autobiz widget js after domain is available
            AssetManager.instance.removeScript(
                domain + (pxVersion === PxVersion.v2 ? AUTOBIZ_WIDGET_URL_V2 : AUTOBIZ_WIDGET_URL_V1)
            );

            if (pxWidgetConfig.env === 'dev') {
                AssetManager.instance.addScripts([
                    {
                        type: DependencyType.Script,
                        url: domain + (pxVersion === PxVersion.v2 ? AUTOBIZ_WIDGET_URL_V2 : AUTOBIZ_WIDGET_URL_V1),
                    },
                ]);
            } else {
                AssetManager.instance.addScriptsAndSuppressErrors([
                    {
                        type: DependencyType.Script,
                        url: domain + (pxVersion === PxVersion.v2 ? AUTOBIZ_WIDGET_URL_V2 : AUTOBIZ_WIDGET_URL_V1),
                    },
                ]);
            }

            // enable button
            if (dispatch) AssetManager.instance.then(() => setModalButtonEnabled(dispatch, true));
        })
        .catch((e) => {
            captureException(e);
            throwGlobalError(dispatch, e);
        });
}
