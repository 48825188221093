import AxiosLoggerService from 'axios-debug-log';
import { Debugger } from 'debug';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

class AxiosError extends Error {}

const createAxiosLoggerService: any = (sentry: any) => {
    const userOptions: any = {
        request(debug: Debugger, config: AxiosRequestConfig) {
            const { method, baseURL = '', url } = config;

            debug(`ℹ  Request: ${method.toUpperCase()} ${baseURL}${url}`);
        },
        response(debug: Debugger, response: AxiosResponse) {
            const { request, config } = response;
            const method = request.method ? request.method : config.method;

            debug(
                `✅ Response: ${method.toUpperCase()} ${config.baseURL ?? ''}${config.url} ${response &&
                    response.status}`
            );
        },
        error(debug: Debugger, error: AxiosError) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const { request, config, response } = error;

            // If the error is something else, like missing btoa function in node
            if (!request) {
                console.error(error);
            }

            const { axiosLoggerConfig = {} } = config ?? {};

            const URL = config && (config.baseURL ?? '') + config.url;

            debug(
                `❌ ${request.method ? request.method : '-'} ${URL} ${
                    response ? response.status : 'UNKNOWN STATUS'
                }\n` +
                    `→ Request data: ${JSON.stringify(config.data)}\n` +
                    `← Response data: ${response ? JSON.stringify(response.data) : '< -- NO DATA -->'}`
            );

            const debugNamespace = debug.namespace;

            let shouldCaptureException = true;
            if (axiosLoggerConfig && axiosLoggerConfig.shouldLogToSentry instanceof Function) {
                shouldCaptureException = axiosLoggerConfig.shouldLogToSentry(error);
            }

            if (sentry && shouldCaptureException) {
                sentry.configureScope(function(scope: any) {
                    scope.setExtra('method', request && request.method);
                    scope.setExtra('url', URL);
                    scope.setExtra('response_status', response && response.status);
                    scope.setExtra('request_data', JSON.stringify(config && config.data));
                    scope.setExtra('response_data', response && JSON.stringify(response.data));
                    sentry.captureException(new AxiosError(`AxiosError of ${debugNamespace}`));
                });
            }
        },
    };

    AxiosLoggerService(userOptions);

    return AxiosLoggerService;
};

export { createAxiosLoggerService, AxiosError };
