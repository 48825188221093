import { IOfferItemBase } from '../../interfaces/IOfferItem';
import {
    Country,
    Culture,
    SortType,
    SortOrder,
    IVehicleTransformedResponse,
    IFiltersResponse,
    StockPriceTypes,
} from './models/stockItems.service.model';
import { TBudgetType } from '../../interfaces/IFilters';
import { IGeoLocation } from '../../redux/filters/filter.duck.interface';
import { IConfigurableCarExtended, IDeal } from '../../interfaces/Car';

export type IStockModelsEndpointResponse = {
    totalVehiclesCount: number;
    vehicles: IOfferItemBase[];
    filters: any;
};

export type IStockModelCreateDealResponse = {
    success: boolean;
    data: {
        deal: IDeal<IConfigurableCarExtended>;
    };
};

export type IStockModelCheckAvailabilityResponse = {
    available: boolean;
};

export interface IGetStockVehicles {
    resultsPerPage: number;
    page: number;
    country: Country;
    priceType: StockPriceTypes;
    lang: Culture;
    brand: string;
    geoLocalization?: string;
    distanceRadius?: number;
    maxBudget?: number;
    minBudget?: number;
    transmissionTypes?: string;
    energies?: string;
    colorGroups?: string;
    sortType?: string;
    sortOrder?: string;
}

export interface IStockVehiclesFilters {
    maxBudget?: number;
    minBudget?: number;
    transmissionTypes?: string;
    energies?: string;
    gearboxTypes?: string;
    colorGroups?: string;
    prices?: string;
    engines?: string;
    grades?: string;
    sort?: string;
    sortType?: SortType;
    sortOrder?: SortOrder;
}

export enum FilterPaymentType {
    CASH = 'cash',
    FINANCED = 'financed',
}

export enum SortFilterKey {
    PRICE_ASC = 'price-asc',
    PRICE_DESC = 'price-desc',
    PROXIMITY_ASC = 'proximity-asc',
    PROXIMITY_DESC = 'proximity-desc',
}

export type GetStockVehiclesType = (
    namePlateBodySlug: string | string[],
    filters: IStockVehiclesFilters,
    geoLocation?: IGeoLocation,
    distanceRadius?: number
) => Promise<IVehicleTransformedResponse>;

export type GetStockVehiclesFilters = (
    namePlateBodySlug: string,
    filters: IStockVehiclesFilters,
    geoLocation?: IGeoLocation,
    distanceRadius?: number
) => Promise<IFiltersResponse>;

export type GetStockModelsFilters = () => Promise<IFiltersResponse>;
