import { createActionTypes } from '../createActionTypes';
import {
    IAction,
    IResponseError,
    NETWORK_STATUS_START,
    NETWORK_STATUS_DONE,
    NETWORK_STATUS_ERROR,
} from '../redux.interface';
import {
    IFinanceWidgetDuckState,
    IFinanceWidgetOutput,
    IFinanceWidgetDuck,
    IReduxFinanceQuote,
    IFinanceWidgetParameters,
} from './financeWidget.duck.interface';
import { GlobalTexts, IFinanceQuote } from '../../services/calculateSummary/calculateSummary.types';
import { IPriceResponse } from '../../services/financePrices/financePrices.types';
import { Dispatch } from 'redux';
import FinanceQuoteService from '../../services/financeQuote/financePrices.service';
import { getSessionIdCookie } from '@utils/Session.utils';

const FINANCE_WIDGET_DUCK_NAME = 'FinanceWidget';

const initialState: IFinanceWidgetDuckState = {
    financeWidgetOutput: null,
    financeQuote: null,
    leasysQuote: null,
    defaultLeasysQuote: null,
    pricesList: null,
    isPricesListLoading: false,
    financeQuoteList: [],
    financeWidgetParameters: null,
};

const actionTypes = createActionTypes(
    {
        set_finance_widget_output: 'set_finance_widget_output',
        set_finance_quote: 'set_finance_quote',
        set_leasys_quote: 'set_leasys_quote',
        set_default_leasys_quote: 'set_default_leasys_quote',
        set_prices_list: 'set_prices_list',
        set_is_prices_list_loading: 'set_is_prices_list_loading',
        ACTION_FETCH_FINANCE_QUOTE_START: 'ACTION_FETCH_FINANCE_QUOTE_START',
        ACTION_FETCH_FINANCE_QUOTE_FAILED: 'ACTION_FETCH_FINANCE_QUOTE_FAILED',
        ACTION_FETCH_FINANCE_QUOTE_DONE: 'ACTION_FETCH_FINANCE_QUOTE_DONE',
    },
    FINANCE_WIDGET_DUCK_NAME
);

const reducer = (state: IFinanceWidgetDuckState = initialState, action: IAction): IFinanceWidgetDuckState => {
    switch (action.type) {
        case actionTypes.set_finance_widget_output:
            if (!action.payload) {
                return {
                    ...state,
                    financeWidgetOutput: null,
                    financeWidgetParameters: null,
                };
            }
            return {
                ...state,
                financeWidgetOutput: action.payload,
                ...(action.payload && { financeWidgetParameters: action.payload.parameters }),
            };
        case actionTypes.set_leasys_quote:
            return {
                ...state,
                leasysQuote: action.payload,
            };
        case actionTypes.set_default_leasys_quote:
            return {
                ...state,
                defaultLeasysQuote: action.payload,
            };
        case actionTypes.set_finance_quote: {
            const financeQuote = action.payload ?? {};

            const parametersUpdate: Partial<IFinanceWidgetDuckState> = {};
            // financeGatewayParameters should take priority even when set to null
            if ('financeGatewayParameters' in financeQuote) {
                parametersUpdate.financeWidgetParameters = financeQuote.financeGatewayParameters;
            } else if (financeQuote.isPersonalized) {
                parametersUpdate.financeWidgetParameters = {
                    duration: financeQuote.duration,
                    deposit: financeQuote.deposit,
                    annualMileage: financeQuote.annualMileage,
                    productKey: financeQuote.financeProductKey,
                    depositAmountKind: financeQuote.depositAmountKind,
                    services: [
                        {
                            packageCode: financeQuote.packageCode || financeQuote.financePackageKey,
                            serviceCode: financeQuote.serviceCode,
                        },
                    ],
                };
            } else {
                parametersUpdate.financeWidgetParameters = null;
            }

            return {
                ...state,
                ...parametersUpdate,
                financeQuote: action.payload,
            };
        }
        case actionTypes.set_prices_list:
            return {
                ...state,
                pricesList: action.payload,
            };
        case actionTypes.set_is_prices_list_loading:
            return {
                ...state,
                isPricesListLoading: action.payload.isLoading,
            };
        /*********************************** FINANCE QUOTE REDUCERS ***************************************/
        case actionTypes.ACTION_FETCH_FINANCE_QUOTE_START: {
            const reduxFinanceQuotes: IReduxFinanceQuote[] = state.financeQuoteList.filter(
                (reduxFinanceQuote: IReduxFinanceQuote) => reduxFinanceQuote.id !== action.payload.id
            );
            const newFinanceQuote: IReduxFinanceQuote = {
                status: NETWORK_STATUS_START,
                error: null,
                financeQuote: null,
                id: action.payload.id,
            };
            return {
                ...state,
                financeQuoteList: [...reduxFinanceQuotes, newFinanceQuote],
            };
        }
        case actionTypes.ACTION_FETCH_FINANCE_QUOTE_DONE: {
            const reduxFinanceQuotes: IReduxFinanceQuote[] = state.financeQuoteList.filter(
                (reduxFinanceQuote: IReduxFinanceQuote) => reduxFinanceQuote.id !== action.payload.id
            );
            const newFinanceQuote: IReduxFinanceQuote = {
                status: NETWORK_STATUS_DONE,
                error: null,
                financeQuote: action.payload.financeQuote,
                id: action.payload.id,
            };
            return {
                ...state,
                financeQuoteList: [...reduxFinanceQuotes, newFinanceQuote],
            };
        }
        case actionTypes.ACTION_FETCH_FINANCE_QUOTE_FAILED: {
            const reduxFinanceQuotes: IReduxFinanceQuote[] = state.financeQuoteList.filter(
                (reduxFinanceQuote: IReduxFinanceQuote) => reduxFinanceQuote.id !== action.payload.id
            );
            const newFinanceQuote: IReduxFinanceQuote = {
                status: NETWORK_STATUS_ERROR,
                error: action.payload.error,
                financeQuote: null,
                id: action.payload.id,
            };
            return {
                ...state,
                financeQuoteList: [...reduxFinanceQuotes, newFinanceQuote],
            };
        }
        default:
            return state;
    }
};

const setFinanceWidgetOutput = (output: IFinanceWidgetOutput): IAction<IFinanceWidgetOutput> => {
    return {
        type: actionTypes.set_finance_widget_output,
        payload: output,
    };
};

const setFinanceQuote = (quote: IFinanceQuote): IAction<IFinanceQuote> => {
    return {
        type: actionTypes.set_finance_quote,
        payload: quote,
    };
};

const setLeasysQuote = (quote: IFinanceQuote): IAction<IFinanceQuote> => {
    return {
        type: actionTypes.set_leasys_quote,
        payload: quote,
    };
};

const setDefaultLeasysQuote = (quote: IFinanceQuote): IAction<IFinanceQuote> => {
    return {
        type: actionTypes.set_default_leasys_quote,
        payload: quote,
    };
};

const setFinancePricesList = (pricesList: IPriceResponse[]) => ({
    type: actionTypes.set_prices_list,
    payload: pricesList,
});

const setIsPricesListLoading = (isLoading: boolean) => ({
    type: actionTypes.set_is_prices_list_loading,
    payload: { isLoading },
});

/******************************************* FINANCE QUOTE LIST ACTIONS ***************************************************/

const actionFetchFinanceQuoteStart = (id: string) => {
    return {
        type: actionTypes.ACTION_FETCH_FINANCE_QUOTE_START,
        payload: { id },
    };
};

const actionFetchFinanceQuoteFailed = (id: string, error: IResponseError) => {
    return {
        type: actionTypes.ACTION_FETCH_FINANCE_QUOTE_FAILED,
        payload: { id, error },
    };
};

const actionFetchFinanceQuoteDone = (id: string, financeQuote: IFinanceQuote) => {
    return {
        type: actionTypes.ACTION_FETCH_FINANCE_QUOTE_DONE,
        payload: { id, financeQuote },
    };
};

const actionFetchFinanceQuoteToList = (id: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(actionFetchFinanceQuoteStart(id));
        try {
            const financeQuote = await FinanceQuoteService.getFinanceQuoteById(getSessionIdCookie(), id);
            dispatch(actionFetchFinanceQuoteDone(id, financeQuote.data));
        } catch (err: any) {
            dispatch(
                actionFetchFinanceQuoteFailed(id, {
                    code: err?.response?.status,
                    data: err?.response?.data,
                    err,
                })
            );
        }
    };
};

const getOwnState = (state: any): IFinanceWidgetDuckState => state[FINANCE_WIDGET_DUCK_NAME] || initialState;
const getFinanceWidgetOutput = (state: any): IFinanceWidgetOutput | null => getOwnState(state).financeWidgetOutput;
const getFinanceQuote = (state: any): IFinanceQuote => getOwnState(state).financeQuote;
const getLeasysQuote = (state: any): IFinanceQuote => getOwnState(state).leasysQuote;
const getDefaultLeasysQuote = (state: any): IFinanceQuote => getOwnState(state).defaultLeasysQuote;
const getFinancePricesList = (state: any): IPriceResponse[] => getOwnState(state).pricesList;
const getIsPricesListLoading = (state: any): boolean => getOwnState(state).isPricesListLoading;
const getFinanceWidgetParameters = (state: any): IFinanceWidgetParameters | null =>
    getOwnState(state).financeWidgetParameters;
const getFinanceWidgetGlobalTexts = (state: any): GlobalTexts | null => getFinanceQuote(state)?.globalTexts ?? null;

export const FinanceWidgetDuck: IFinanceWidgetDuck = {
    name: FINANCE_WIDGET_DUCK_NAME,
    reducer,
    setFinanceWidgetOutput,
    setFinanceQuote,
    setLeasysQuote,
    setDefaultLeasysQuote,
    setFinancePricesList,
    getOwnState,
    getFinanceWidgetOutput,
    getFinanceQuote,
    getLeasysQuote,
    getDefaultLeasysQuote,
    getFinancePricesList,
    setIsPricesListLoading,
    getIsPricesListLoading,
    actionFetchFinanceQuoteToList,
    getFinanceWidgetParameters,
    getFinanceWidgetGlobalTexts,
};

export default FinanceWidgetDuck;
