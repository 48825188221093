import NextApp, { AppProps as NextAppProps } from 'next/app';
import React, { Fragment } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { theme } from '../styles/theme';
import main from '../styles/main';
import withRedux from 'next-redux-wrapper';
import { initializeStore } from '../redux/store';
import { Provider, ProviderProps } from 'react-redux';
import 'react-multi-carousel/lib/styles.css';
import 'simplebar/dist/simplebar.min.css';
import 'react-notifications/lib/notifications.css';
import 'wid00-psabpf-simulation/main.css';
import Router from 'next/router';
import NProgress from 'nprogress';
import UserDuck from '../redux/user/user.duck';
import { appWithTranslation } from '../../i18n';
import 'nprogress/nprogress.css';
import ContentService from '../services/content/content.service';
import ContentDuck from '../redux/content/content.duck';
import MissingPartsHandler from '@components/MissingPartsHandler';
import Posthog from '@components/Posthog';
import FinanceLegal from '@components/FinanceLegal';
import UIDuck from '../redux/commonDucks/ui.duck';
import { captureException } from '@utils/sentry.utils';
import { NotificationContainer } from 'react-notifications';
import { logMemoryUsage } from '@utils/logMemoryUsage';
import FeatureSwitchForApp, { features, IFeatureSwitchContext } from '../context/featureSwitchApp';
import FeatureSwitchForTesting from '../context/featureSwitchTesting';
import FilterDuck from '../redux/filters/filter.duck';
import { DealService } from '../services';
import { PaymentJourneyTypes } from '../partExchange/interfaces/Default';
import routes from '../constants/routes';
import MaintenanceProvider from '../context/maintenance';
import {
    SESSION_COOKIE_NAME,
    SESSION_COOKIE_NAME_EXPERIMENTS,
    BRAND,
    LANGUAGE,
    MARKET,
    POSTHOG_API_KEY,
    POSTHOG_DOMAIN,
    CONTEXT,
} from '../constants/main';
import { getSessionIdForPosthog, getSessionIdCookie } from '@utils/Session.utils';
import { IExperimentalFeatureFlagContext } from '@utils/experiments/@types';
import {
    getExperimentationFeatureFlags,
    getOverriddenFeatureFlags,
} from '@utils/experiments/getExperimentationFeatureFlags';
import BrowserDetectionProvider from '../context/browserDetection';

NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const GlobalStyles = createGlobalStyle<any>`${main}`;

export interface AppState {
    error?: any;
    errorInfo?: any;
    hasError?: boolean;
}

export type AppProps = NextAppProps & { featureContext: IFeatureSwitchContext } & {
    experimentalFeatureContext: IExperimentalFeatureFlagContext;
    isServer: boolean;
};

class App extends NextApp<AppProps & ProviderProps, AppState> {
    state = {
        hasError: false,
    };
    public static async getInitialProps(initProps: any) {
        const { Component, ctx } = initProps;
        let pageProps = {};
        let content;

        // @NOTE if payment journey exist in URL, so redirect to customer to page = HOME without payment journey
        if (
            ctx?.asPath?.includes(`/${PaymentJourneyTypes.FINANCE}`) ||
            ctx?.asPath?.includes(`/${PaymentJourneyTypes.CASH}`)
        ) {
            if (ctx?.asPath?.includes(routes.SELECTOR) || ctx?.asPath?.includes(routes.CAR)) {
                return ctx.res?.redirect(
                    ctx.asPath
                        .replace(`/${PaymentJourneyTypes.FINANCE}`, '')
                        .replace(`/${PaymentJourneyTypes.CASH}`, '')
                );
            }
            return ctx.res?.redirect(routes.ROOT);
        }

        ctx.featureContext = features(ctx.req?.cookies);

        ctx.store.dispatch(UserDuck.setIsLoggingOut(false));

        if (!FilterDuck.getPaymentJourneyType(ctx.store.getState())) {
            const defaultPaymentJourney =
                ctx.featureContext.FEATURE_SWITCH_DEFAULT_PAYMENT_JOURNEY ?? PaymentJourneyTypes.FINANCE;
            ctx.store.dispatch(FilterDuck.changeBudgetType(defaultPaymentJourney));
        }

        // APP-12810
        // getInitialProps caleld on client (when window is not undefiend) means, we are in CSR, SPA mode,
        // DY API must be explicitely called only in CSR mode, navigationg via NExt router, or browser history.
        // FUTURE: getServerSideProps can also be called on server (SSR), while navigating via Next router, or browser,
        // getServerSideProps are called on server, and return only JSON of props, no html...
        if (typeof window !== 'undefined') {
            (window as any).CSR = true;

            if (!ctx?.asPath?.includes(routes.SUMMARY)) {
                window.removeEventListener('popstate', () => null);
            }
        }

        if (ctx.req) {
            content = await ContentService.getContent(ctx.req.i18n.language);

            if (content) {
                ctx.store.dispatch(ContentDuck.setContent(content, ctx.req.i18n.language, ctx.req.i18n));
            }
        }

        if (ctx.featureContext.FEATURE_SWITCH_POSTHOG_ENABLED) {
            const session_id = ctx?.req?.cookies?.[SESSION_COOKIE_NAME_EXPERIMENTS] ?? getSessionIdForPosthog();

            const experimentationFeatureFlags = await getExperimentationFeatureFlags({
                isEnabled: ctx.featureContext.FEATURE_SWITCH_POSTHOG_ENABLED,
                providerDomain: POSTHOG_DOMAIN,
                providerApiKey: POSTHOG_API_KEY,
                sessionId: session_id,
                context: {
                    brand: BRAND,
                    market: MARKET,
                    locale: LANGUAGE,
                    channel: CONTEXT,
                },
            });
            ctx.experimentalFeatureContext = getOverriddenFeatureFlags(
                ctx?.req,
                experimentationFeatureFlags.featureFlags
            );
        }

        if (Component.getInitialProps) {
            ctx.store.dispatch(UIDuck.setIsRouting(true));
            pageProps = await Component.getInitialProps(ctx);

            if (pageProps !== undefined) {
                ctx.store.dispatch(UIDuck.setIsRouting(false));
            }
        }

        if (typeof window === 'undefined') {
            const token = ctx?.req?.cookies?.[SESSION_COOKIE_NAME];
            if (token) {
                try {
                    const currentDeal = (await DealService.currentDeal(token)).data;
                    ctx.store.dispatch(UIDuck.setShowBasketInHeader(!!currentDeal));
                } catch {
                    ctx.store.dispatch(UIDuck.setShowBasketInHeader(false));
                }
            } else {
                ctx.store.dispatch(UIDuck.setShowBasketInHeader(false));
            }
        }
        if (ctx.featureContext.FEATURE_SWITCH_POSTHOG_ENABLED) {
            pageProps = { ...pageProps, posthog: ctx?.req?.cookies?.[SESSION_COOKIE_NAME_EXPERIMENTS] };
        }
        logMemoryUsage(ctx.asPath);
        const namespacesRequired = ['common'];

        return {
            pageProps,
            featureContext: ctx.featureContext,
            experimentalFeatureContext: ctx?.experimentalFeatureContext,
            namespacesRequired,
            isServer: typeof window === 'undefined',
        };
    }

    componentDidCatch(error: any, errorInfo: any) {
        captureException(error, { errorInfo });
        this.setState({ error, errorInfo, hasError: true });
    }

    async componentDidMount() {
        // check if user is logged in and store to redux
        const { store } = this.props;
        //store.dispatch(UserDuck.actionSetUserLogged());
    }

    public render() {
        const { Component, pageProps, store, featureContext, experimentalFeatureContext } = this.props;
        const { error = null, errorInfo = null, hasError }: AppState = this.state;
        const isMyAccount = this.props.router.pathname.includes('/my-account');
        const isPosthogEnabled = featureContext.FEATURE_SWITCH_POSTHOG_ENABLED;
        let session_id = '';
        if (isPosthogEnabled) {
            session_id = pageProps?.posthog ? pageProps.posthog : getSessionIdForPosthog();
        }

        return (
            <Fragment>
                <Provider store={store}>
                    <FeatureSwitchForTesting features={experimentalFeatureContext}>
                        <FeatureSwitchForApp features={featureContext}>
                            <ThemeProvider theme={theme}>
                                <GlobalStyles isMyAccount={isMyAccount} />
                                <BrowserDetectionProvider>
                                    <MaintenanceProvider page={this.props.router.pathname}>
                                        {pageProps.error || hasError ? (
                                            <Fragment>{error || errorInfo || 'Error'}</Fragment>
                                        ) : (
                                            <Component {...pageProps} />
                                        )}
                                        {isPosthogEnabled && <Posthog sessionId={session_id} />}
                                        <MissingPartsHandler />
                                        <NotificationContainer />
                                        <FinanceLegal />
                                    </MaintenanceProvider>
                                </BrowserDetectionProvider>
                            </ThemeProvider>
                        </FeatureSwitchForApp>
                    </FeatureSwitchForTesting>
                </Provider>
            </Fragment>
        );
    }
}

export default withRedux(initializeStore)(appWithTranslation(App));
