import { css } from '../theme';
import { rem } from 'polished';

const text = css`
    h2,
    .as-h2 {
        font-size: ${rem('24px')};
        font-family: ${({ theme }) => theme.fonts.fontBase};
        font-weight: ${({ theme }) => theme.fontWeights.textRegular};
    }
`;

export default text;
