import { Icons } from '../..';
import calendar from './icons/calendar.svg';
import engineSimple from './icons/engineSimple.svg';
import exterior from './icons/exterior.svg';
import interior from './icons/interior.svg';
import correct from './icons/correct.svg';
import logo from './icons/logo.svg';
import info from './icons/info.svg';
import phone from './icons/phone.svg';
import dealer from './icons/dealer.svg';
import account from './icons/account.svg';
import car from './icons/car.svg';
import mail from './icons/mail.svg';
import edit from './icons/edit.svg';
import carStore from './icons/carStore.svg';
import carStoreV2 from './icons/carStoreV2.svg';
import basketV2 from './icons/basketV2.svg';
import securePayent from './icons/securePayment.svg';
import squarePlus from './icons/squarePlus.svg';
import Successicon from './icons/Succesicon.svg';
import Folder from './icons/folder.svg';
import PoiCommercialBuilding from './icons/poiCommercialBuilding.svg';
import PlusIcon from './icons/plus.svg';

export const iconsOV = (name: Icons) => {
    switch (name) {
        case Icons.Logo:
            return logo;
        case Icons.Account:
            return account;
        case Icons.StoreCar:
            return carStoreV2;
        case Icons.Basket:
            return basketV2;
        case Icons.Calendar:
            return calendar;
        case Icons.Mail:
            return mail;
        case Icons.edit:
            return edit;
        case Icons.EngineSimple:
            return engineSimple;
        case Icons.InteriorV2:
            return interior;
        case Icons.ExteriorV2:
            return exterior;
        case Icons.Info:
            return info;
        case Icons.Dealer:
            return dealer;
        case Icons.Phone:
            return phone;
        case Icons.Car:
            return car;
        case Icons.CarStore:
            return carStore;
        case Icons.SecurePayment:
            return securePayent;
        case Icons.Correct:
            return correct;
        case Icons.Folder:
            return Folder;
        case Icons.LoadingSuccess:
            return Successicon;
        case Icons.SquarePlus:
            return squarePlus;
        case Icons.PoiCommercialBuilding:
            return PoiCommercialBuilding;
        case Icons.PlusIcon:
            return PlusIcon;
        default:
            return '';
    }
};
