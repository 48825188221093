import { FC } from 'react';
import { useTranslations } from '@hooks/useTranslations';
import { publicAssets } from '@utils/url.utils';
import { MaintenanceProps } from './index';
import { isBrandAC, isBrandAP, isBrandDS, isBrandOV } from '../../constants/main';

export const MaintenanceTemplate: FC<MaintenanceProps> = ({ className }) => {
    const { t } = useTranslations();

    return (
        <div className={className}>
            {isBrandAC && (
                <>
                    <div className="background" />
                    <div className="content">
                        <h1 className="title">{t('maintenance.title')}</h1>
                        <p className="description">{t('maintenance.description')}</p>
                        <a
                            className="link"
                            rel="noopener noreferrer"
                            href={t('maintenance.redirectLink')}
                            target="_blank"
                        >
                            {t('maintenance.link')}
                        </a>
                    </div>
                </>
            )}

            {isBrandAP && (
                <div className="wrapper">
                    <div className="content">
                        <img className="image" src={publicAssets('/AP/ap_maintenance_bg.jpg')} alt="" />
                        <div className="description">
                            <h2>{t('maintenance.title')}</h2>
                            <p>{t('maintenance.description')}</p>
                            <button>
                                <a href={t('maintenance.redirectLink')}>
                                    <strong>{t('maintenance.link')}</strong>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isBrandDS && (
                <div className="dsMaintenanceWrapper">
                    <div className="dsMaintenanceBgImage" />
                    <div className="dsMaintenanceContent">
                        <h2>{t('maintenance.title')}</h2>
                        <div className="dsMaintenanceInnerContent">
                            <p>{t('maintenance.description')}</p>
                            <a href={t('maintenance.redirectLink')} target="_blank" rel="noreferrer">
                                {t('maintenance.link')}
                            </a>
                        </div>
                    </div>
                </div>
            )}

            {isBrandOV && (
                <>
                    <div className="background" />
                    <div className="content">
                        <h1 className="title">{t('maintenance.title')}</h1>
                        <p className="description">{t('maintenance.description')}</p>
                        <a
                            className="link"
                            rel="noopener noreferrer"
                            href={t('maintenance.redirectLink')}
                            target="_blank"
                        >
                            {t('maintenance.link')}
                        </a>
                    </div>
                </>
            )}
        </div>
    );
};
