import { useCallback } from 'react';

export const useObjectUtils = () => {
    const replaceStringValuesInObject = useCallback(
        (object: Record<string, any>, originalString: string = 'null', replacedString: string = '') =>
            object
                ? Object.assign(
                      {},
                      ...Object.keys(object).map((key) => {
                          let value = object[key];
                          value =
                              typeof value === 'string' && value.toLowerCase() === originalString
                                  ? replacedString
                                  : value;
                          return { [key]: value };
                      })
                  )
                : undefined,
        []
    );

    const removeFalsyProperties = <T extends Record<string, unknown>>(object: T): T =>
        Object.entries(object).reduce((result, [key, value]) => {
            return {
                ...result,
                ...(value && { [key]: value }),
            };
        }, {}) as T;

    return { replaceStringValuesInObject, removeFalsyProperties };
};
