import { AxiosPromise, AxiosResponse } from 'axios';
import coreApiService from '../core/coreApi.service';
import { SESSION_REQUEST_HEADER_NAME } from '../../constants/main';
import { IDeal } from '../deal/deal.types';
import { IFinanceQuote } from '../calculateSummary/calculateSummary.types';
import { IConfigurableCar } from '../../interfaces/Car';
import DealService from '../deal/deal.service';

const DEALERS_DEALER: string = 'dealer/api/v1/dealers/';
const DEALER_UPDATE_MOP: string = 'digital-api/api/update-mop-dealer/';
const DEALER_UPDATE_DEAL: string = 'digital-api/api/update-dealer/';

const RRDI7_LENGTH = 7;

export const dealerApi = {
    getDealer: (dealerId: string): AxiosPromise => {
        if (dealerId.length === RRDI7_LENGTH) {
            return coreApiService.get(`${DEALERS_DEALER}rrdi7/${dealerId}`);
        }

        return coreApiService.get(`${DEALERS_DEALER}${dealerId}`);
    },
    getAllDealers: (lat: string, lng: string, codeActorAddressVN?: string): AxiosPromise =>
        coreApiService.get(`${DEALERS_DEALER}`, {
            latitude: lat,
            longitude: lng,
            ...(codeActorAddressVN && { codeActorAddressVN }),
        }),
    updateDeal: async (dealer_id: string, token: string): Promise<AxiosResponse<any>> => {
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        const deal = await coreApiService.patch(`${DEALERS_DEALER}deal/update`, { dealer_id }, headers);
        await coreApiService.post(`${DEALER_UPDATE_MOP}${dealer_id}`, {}, headers);
        return deal;
    },
    updateDealer: async (
        dealer_id: string,
        token: string
    ): Promise<AxiosResponse<{ deal: IDeal<IConfigurableCar>; financeQuote?: IFinanceQuote }>> => {
        await DealService.lock;
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        const request = coreApiService.post(`${DEALER_UPDATE_DEAL}${dealer_id}`, {}, headers);
        DealService.pushLock(request);
        return await request;
    },
};
