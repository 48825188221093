import { IOperatorTypes, TBudgetType } from '../../interfaces/IFilters';
import { StringMapping } from '../../interfaces/StringMapping';
import { CarJourneyType } from '../carList/carList.types';
import { ConfigurableParentNames, StockParentNames } from '../../redux/filters/filter.duck.interface';

// Price types
export const EMPLOYEE_PRICE_TYPE: string = 'Employee';
export const CATALOGUE_PRICE_TYPE: string = 'Catalog';
export const MRIP_PRICE_TYPE: string = 'Mrip';
export const PRICE_TYPE_LCDV_MRIP: string = 'LCDV_Mrip';
export const PRICE_TYPE_LCDV_CATALOG: string = 'LCDV_Catalog';

export const CASH: TBudgetType = 'cash';
export const FINANCE: TBudgetType = 'finance';

export const STOCK = CarJourneyType.STOCK;
export const CONFIGURABLE = CarJourneyType.CONFIGURABLE;

// sdk fields
export const BASE_PRICE: string = 'prices.basePrice';
export const MONTHLY_PRICES: string = 'prices.monthlyPrices.amount';
export const BODY_STYLE_ID: string = 'bodyStyle.id';
export const NAMEPLATE_BODYSTYLE_SLUG: string = 'nameplateBodyStyleSlug';
export const ENGINE_GEARBOX_FUEL_SLUG: string = 'engineGearboxFuelSlug';
export const PRICES_TYPE: string = 'prices.type';
export const PRICES_FORMAT: string = 'prices.priceFormat';
export const CURRENCY: string = 'prices.currency';
export const MAX_PRICE: string = 'maxBudget';
export const MIN_PRICE: string = 'minBudget';
export const FROM: string = 'from';
export const TO: string = 'to';
export const FUEL: string = 'fuel';
export const PRICES: ConfigurableParentNames | StockParentNames = 'prices';
export const SORT: StockParentNames = 'sort';
export const NATIONAL = 'national';
export const COLOR_GROUPS: StockParentNames = 'colorGroups';
export const MODEL: string = 'model.id';
export const EXTERIOR_COLOUR_FIELD: string = 'exteriorColour.id';
export const LUXURY_LEVEL_FIELD: string = 'specPack.title';
export const TRANSMISSION_FIELD: string = 'gearbox.specs.label';
export const TRANSMISSION_EXTRA_NESTING: string = 'gearbox.specs.key';
export const TRANSMISSION_EXTRA_VALUE: string = 'type';

// filter categories
export const BUDGET: string = 'paymentJourney';
export const ENGINE: string = 'engine';
export const TRANSMISSION: string = 'transmission';
export const AVAILABILITY: string = 'availability';
export const LUXURY_LEVEL: string = 'luxuryLevel';
export const EXTERIOR_COLOUR: string = 'exteriorColour';
export const GLOBAL: string = 'global';
export const TRANSMISSION_TR: string = 'tr';
export const TOTAL_RESULTS: string = 'totalResults';

export const TRANSLATION_FILTER_FIELDS: string[] = [TOTAL_RESULTS, BUDGET, EXTERIOR_COLOUR];
export const FIRST_ROW_FIELDS: string[] = [ENGINE, TRANSMISSION];
export const SECOND_ROW_FIELDS: string[] = [LUXURY_LEVEL, EXTERIOR_COLOUR];

export const MULTIPLE_VALUE_FILTERS: string[] = [ENGINE, TRANSMISSION, EXTERIOR_COLOUR, LUXURY_LEVEL];
export const SINGLE_VALUE_FILTERS: string[] = [AVAILABILITY];

export const FILTER_TYPE_NAMES: StringMapping<string> = {
    [BUDGET]: `${BASE_PRICE}.${GLOBAL}`,
    [FINANCE]: `${MONTHLY_PRICES}.${GLOBAL}`,
    [ENGINE]: FUEL,
    [TRANSMISSION]: `${TRANSMISSION_FIELD}.${TRANSMISSION_TR}`,
    [EXTERIOR_COLOUR]: EXTERIOR_COLOUR_FIELD,
    [LUXURY_LEVEL]: LUXURY_LEVEL_FIELD,
};

export const FILTER_TYPE_NESTING: StringMapping<string> = {
    [BUDGET]: BASE_PRICE,
    [FINANCE]: MONTHLY_PRICES,
    [ENGINE]: FUEL,
    [TRANSMISSION]: TRANSMISSION_FIELD,
    [AVAILABILITY]: STOCK,
    [EXTERIOR_COLOUR]: EXTERIOR_COLOUR_FIELD,
    [LUXURY_LEVEL]: LUXURY_LEVEL_FIELD,
};

export const FILTER_TYPE_PARENT: StringMapping<string | null> = {
    [BUDGET]: BASE_PRICE,
    [FINANCE]: MONTHLY_PRICES,
    [ENGINE]: null,
    [TRANSMISSION]: TRANSMISSION_FIELD,
    [EXTERIOR_COLOUR]: null,
    [LUXURY_LEVEL]: null,
};

export const OperatorTypes: IOperatorTypes = {
    IN: 'IN',
    EQUALS: 'EQUALS',
    BETWEEN: 'BETWEEN',
    NOTIN: 'NOT IN',
    IS_EMPTY: 'IS_EMPTY',
};

export const PRICE_TYPE_EMPLOYEE: string = EMPLOYEE_PRICE_TYPE;

export const THROTTLE_TIME: number = 1000;

export const QUERY_PARAMS_REGEX: RegExp = /[a-zA-Z]+=[^&]+/g;

export const LEVEL_AGGREGATION_FILTERS: string[] = [PRICES_FORMAT, CURRENCY, FUEL, TRANSMISSION_FIELD];
export const STOCK_LEVEL_AGGREGATION_FILTERS: string[] = [LUXURY_LEVEL_FIELD, ENGINE, EXTERIOR_COLOUR_FIELD];

export const RANGE_STEP: number = 1;
export const RANGE_MINIMUM: number = 500;
export const NAMEPLATE_LIST_PRICE: string = 'prices.monthlyPrice';
export const RANGE_MINIMUM_FINANCE: number = 1;
export const RANGE_DISABLE_MIN_VALUE: string = 'disabledMinValue';

export const MONTHLY_PRICES_GLOBAL: string = 'prices.monthlyPrices.amount.global';
