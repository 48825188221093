import { TBudgetType } from '../../interfaces/IFilters';
import { CASH, FINANCE } from '../filters/filters';
import { StringMapping } from '../../interfaces/StringMapping';

export const MODAL_SUMMARY: string = 'summary';
export const MODAL_THREESIXTY: string = 'threeSixty';
export const MODAL_FINANCE_SUMMARY: string = 'finance';
export const MODAL_OFFER: string = 'offer';
export const PROPERTY_OPTION_FUEL: string = 'fuel';

// Features
export const ENGINE_SLIDE_TYPE: string = 'engine';
export const DISPLAY_TYPE_SLIDER: string = 'slider';
export const DISPLAY_TYPE_DEFAULT: string = 'default';
export const INTERIOR_SLIDE_TYPE: string = 'interiorColour';
export const EXTERIOR_SLIDE_TYPE: string = 'exteriorColour';
export const EXTRA_OPTIONS_SLIDE_TYPE: string = 'options';
export const AVAILABLE_FEATURES: string[] = [ENGINE_SLIDE_TYPE, EXTERIOR_SLIDE_TYPE, INTERIOR_SLIDE_TYPE];
export const ALL_FEATURES: string[] = [
    ENGINE_SLIDE_TYPE,
    EXTERIOR_SLIDE_TYPE,
    INTERIOR_SLIDE_TYPE,
    EXTRA_OPTIONS_SLIDE_TYPE,
];
export const FEATURES_EXCEPT_EXTRA_OPTIONS: string[] = [ENGINE_SLIDE_TYPE, EXTERIOR_SLIDE_TYPE, INTERIOR_SLIDE_TYPE];
export const FEATURE_TYPE_COLOUR: string = 'colour';

// Extra options features
export const EXTRA_OPTIONS_CATEGORY_ALL: string = 'all';

// Orders
export const WEEKS: string = 'weeks';

// Price
export const DEFAULT_DCM_COUNT: number = 0;
export const ASC_PRICE: string = 'asc';
export const PRICE_TYPE_SORT: string = 'prices.vcgPrice';

// Monthly price
export const MONTHLY_PRICE_AMOUNT: string = 'amount';
export const PAYMENT_JOURNEY_LIST: TBudgetType[] = [CASH, FINANCE];
export const INITIAL_DECIMAL_COUNT: number = 0;
export const PER_MONTH: string = 'mois';

// Car image view
export const INTERIOR_VIEW: string = 'interiorView';

// Performance mark
export const IMAGE_LOADED: string = 'imageLoaded';
export const PRICE_LOADED: string = 'priceLoaded';
export const FINANCE_WIDGET: string = 'financeWidget';
export const PERFORMANCE_MARK: StringMapping<any> = {
    nameplateCash: 'VCG_MTO_HP_CASH',
    nameplateFinance: 'VCG_MTO_HP_FINANCE',
    financeWidgetCash: 'SLA_CustomFMP',
    financeWidgetFinance: 'VCG_MTO_CFG_FINANCE',
    extraOptionsCash: 'VCG_MTO_OPTION_CASH',
    extraOptionsFinance: 'VCG_MTO_OPTION_FINANCE',
};

export const NEED_HELP_PHONE_DS: string = '0 969 32 19 55';
export const NEED_HELP_PHONE_AP: string = '0 970 809 120';

export const FINANCE_TEMPORARY_PRICE: number = 9999;

export const PEUGEOT: string = 'Peugeot';
export const CITROEN: string = 'Citroen';
