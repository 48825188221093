import { createActionTypes } from '../createActionTypes';
import { IAction, Redux } from '../redux.interface';
import { ICarDetailsDuck, IduckState, ICarDetail, IEngineGearboxes } from './carDetails.duck.interface';
import { DefaultRootState } from 'react-redux';
import get from 'lodash/get';
import { IBaseCarOffer, IConfigurableCar, IDeal, IStockCar } from '../../interfaces/Car';
import { parseDealDataToConfigurableCarDetail } from '@utils/Car.utils';
import { UNAVAILABLE_CAR_IMAGE } from 'src/constants/images';

export const CarDetailsDuck: any = {
    name: 'CarDetails',
};

const initialState: IduckState = {
    currentCarDetails: {},
    allCarDetails: [],
    engineSlugs: {},
    exteriorColorSlugs: new Map(),
    interiorColorSlugs: new Map(),
    engineGearboxes: {},
    engineFuels: new Map(),
    enginePrices: new Map(),
    interiorImages: new Map(),
    currentDeal: undefined,
};
const actionTypes: any = createActionTypes(
    {
        set_current_car_details: 'set_current_car_details',
        set_all_car_details: 'set_all_car_details',
        set_assisted_finance: 'set_assisted_finance',
        set_current_deal: 'set_current_deal',
        set_initial_car_details: 'set_initial_car_details',
        set_current_deal_and_car_detail: 'set_current_deal_and_car_detail',
    },
    CarDetailsDuck.name
);

CarDetailsDuck.reducer = (state: IduckState = initialState, action: IAction): IduckState => {
    switch (action.type) {
        case actionTypes.set_initial_car_details:
            return initialState;
        case actionTypes.set_current_car_details:
            return {
                ...state,
                currentCarDetails: action.payload,
            };
        case actionTypes.set_all_car_details:
            const allCarDetails = action.payload;
            let engineSlugs = {};
            const exteriorColorSlugs = new Map();
            const interiorColorSlugs = new Map();
            let engineGearboxes: IEngineGearboxes = {};
            const engineFuels = new Map();
            const enginePrices = new Map();
            const interiorImages = new Map();

            allCarDetails.forEach((car: any) => {
                engineSlugs = {
                    ...engineSlugs,
                    [car.engineGearboxFuelSlug]: {
                        gearbox: car.gearbox.id,
                        engine: car.engine.id,
                    },
                };
                exteriorColorSlugs.set(car.exteriorColour.id, car.exteriorColourSlug);
                interiorColorSlugs.set(car.interiorColour.id, car.interiorColourSlug);
                engineGearboxes = {
                    ...engineGearboxes,
                    [car.engine.id]: {
                        ...(engineGearboxes[car.engine.id] ?? {}),
                        [car.gearbox.id]: car.gearbox,
                    },
                };
                engineFuels.set(car.engine.id, car.fuel);
                // FIXME:
                enginePrices.set(car.engine.id, car.prices);
                interiorImages.set(
                    car.interiorColour.id,
                    get(car, 'images', []).filter((img: any) => img.type === '360') || [{ url: UNAVAILABLE_CAR_IMAGE }]
                );
            });

            return {
                ...state,
                allCarDetails,
                engineSlugs,
                exteriorColorSlugs,
                interiorColorSlugs,
                engineGearboxes,
                engineFuels,
                enginePrices,
                interiorImages,
            };
        case actionTypes.set_current_deal:
            return {
                ...state,
                currentDeal: action.payload,
            };
        case actionTypes.set_assisted_finance:
            return {
                ...state,
                currentDeal: {
                    ...state.currentDeal,
                    extraFields: { ...state.currentDeal.extraFields, assistedFinance: action.payload },
                },
            };
        case actionTypes.set_current_deal_and_car_detail:
            const parsedCarDetail = parseDealDataToConfigurableCarDetail(action.payload);

            return {
                ...state,
                currentDeal: action.payload,
                currentCarDetails: parsedCarDetail,
            };
        default:
            return state;
    }
};

CarDetailsDuck.resetCarDetails = (): IAction => {
    return {
        type: actionTypes.set_initial_car_details,
    };
};

CarDetailsDuck.setAllCarDetails = (carDetails: any): IAction => {
    return {
        type: actionTypes.set_all_car_details,
        payload: carDetails,
    };
};

CarDetailsDuck.setCurrentCarDetails = (carDetails: IConfigurableCar | IStockCar): IAction => {
    return {
        type: actionTypes.set_current_car_details,
        payload: carDetails,
    };
};

CarDetailsDuck.getOwnState = (state: any): IduckState => state[CarDetailsDuck.name] || initialState;
CarDetailsDuck.getCurrentCarDetails = (state: IConfigurableCar | IStockCar): any =>
    CarDetailsDuck.getOwnState(state).currentCarDetails;
CarDetailsDuck.getAllCarDetails = (state: DefaultRootState): ICarDetail[] =>
    CarDetailsDuck.getOwnState(state).allCarDetails;

CarDetailsDuck.getMotorization =
    (state: any) =>
    (
        engineId: string,
        exteriorColourSlug: string,
        interiorColourSlug: string,
        gearboxId?: string,
        externalId?: string
    ) => {
        const allCarDetails = CarDetailsDuck.getOwnState(state).allCarDetails;

        return allCarDetails.find(
            (carDetail: any) =>
                carDetail.engine.id === engineId &&
                carDetail.exteriorColourSlug === exteriorColourSlug &&
                carDetail.interiorColourSlug === interiorColourSlug &&
                (!gearboxId || carDetail.gearbox.id === gearboxId) &&
                (!externalId || carDetail._id === externalId)
        );
    };

CarDetailsDuck.getCarConfigurations =
    (state: Redux) => (engineId: string, exteriorColourSlug: string, interiorColourSlug: string) => {
        const allCarDetails = CarDetailsDuck.getOwnState(state).allCarDetails;

        const sameColorConfigurations = allCarDetails.filter(
            (carDetail: ICarDetail) =>
                carDetail.engine.id === engineId &&
                carDetail.exteriorColourSlug === exteriorColourSlug &&
                carDetail.interiorColourSlug === interiorColourSlug &&
                !carDetail.options.length
        );

        // @NOTE if not found in the proper colors, take the first configuration available
        if (!sameColorConfigurations?.length) {
            const firstEngineMatch = allCarDetails.find((carDetail: ICarDetail) => {
                return carDetail.engine.id === engineId;
            });

            return firstEngineMatch ? [firstEngineMatch] : [];
        }

        return sameColorConfigurations;
    };

CarDetailsDuck.getExteriorColor =
    (state: any) => (exteriorColourId: string, engineGearboxFuelSlug: string, interiorColourSlug: string) => {
        const allCarDetails = CarDetailsDuck.getOwnState(state).allCarDetails;
        return allCarDetails.find(
            (carDetail: any) =>
                carDetail.exteriorColour.id === exteriorColourId &&
                carDetail.engineGearboxFuelSlug === engineGearboxFuelSlug &&
                carDetail.interiorColourSlug === interiorColourSlug
        );
    };

CarDetailsDuck.getInteriorColor =
    (state: any) => (interiorColourId: string, engineGearboxFuelSlug: string, exteriorColourSlug: string) => {
        const allCarDetails = CarDetailsDuck.getOwnState(state).allCarDetails;
        return allCarDetails.find(
            (carDetail: any) =>
                carDetail.interiorColour.id === interiorColourId &&
                carDetail.engineGearboxFuelSlug === engineGearboxFuelSlug &&
                carDetail.exteriorColourSlug === exteriorColourSlug
        );
    };

CarDetailsDuck.setCurrentDeal = (deal: IDeal): IAction => {
    return {
        type: actionTypes.set_current_deal,
        payload: deal,
    };
};

CarDetailsDuck.setAssistedFinance = (value: boolean): IAction => {
    return {
        type: actionTypes.set_assisted_finance,
        payload: value,
    };
};

CarDetailsDuck.setCurrentDealAndCarDetail = (deal: IDeal): IAction => {
    return {
        type: actionTypes.set_current_deal_and_car_detail,
        payload: deal,
    };
};

CarDetailsDuck.getCurrentDeal = (state: any): IDeal | undefined => CarDetailsDuck.getOwnState(state).currentDeal;

CarDetailsDuck.getCurrentDealDealerLayerEnabled = (state: Redux): boolean => {
    const currentDeal: IDeal | undefined = CarDetailsDuck.getCurrentDeal(state);
    let offers = currentDeal?.fullProductConfiguration?.offers;
    if (typeof offers === 'undefined') {
        offers = CarDetailsDuck.getCurrentCarDetails(state)?.offers;
    }
    return !!(offers as Array<IBaseCarOffer>)?.find(({ dealerLayerEnabled }) => dealerLayerEnabled);
};

export default CarDetailsDuck as ICarDetailsDuck;
