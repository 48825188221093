import { DependencyType, IAssetDependency, STYLESHEET } from './dependencies';

type ResourceElement = HTMLScriptElement | HTMLLinkElement;

/**
 * Class used to add to host body a script or a style tag with a given url
 */
export default class AssetManager {
    // tslint:disable-next-line: variable-name
    private static _instance: AssetManager;

    public static get instance(): AssetManager {
        if (!this._instance) {
            this._instance = new AssetManager();
        }
        return this._instance;
    }
    protected assets: IAssetDependency[];
    protected loadPromises: Array<Promise<void>>;

    constructor() {
        this.loadPromises = [];
        this.assets = [];
    }

    private createOrGetElementFromDOM(
        type: DependencyType,
        url: string
    ): { scriptInDom?: ResourceElement; newScript?: ResourceElement } {
        const { Style } = DependencyType;
        const scriptInDom: ResourceElement | null = document.body.querySelector(
            `${type}[${type === Style ? 'href' : 'src'}="${url}"]`
        );
        if (scriptInDom) {
            return { scriptInDom };
        }
        const newScript: ResourceElement = document.createElement(type);
        if (type === Style) {
            (newScript as HTMLLinkElement).href = url;
            (newScript as HTMLLinkElement).rel = STYLESHEET;
            document.head.appendChild(newScript);
        } else {
            (newScript as HTMLScriptElement).src = url;
            document.body.appendChild(newScript);
        }
        return { newScript };
    }

    private loadScript(): Promise<void[]> {
        const { Style } = DependencyType;
        // reduce is used here for chaining promises so every resource awaits the resource before it
        const loadPromise: Promise<void> = this.assets.reduce(
            (promiseChain: Promise<void>, { url, type }: IAssetDependency) =>
                promiseChain.then<void>(() => {
                    const { scriptInDom, newScript } = this.createOrGetElementFromDOM(type, url);
                    if (scriptInDom) {
                        return Promise.resolve();
                    } else if (newScript) {
                        return new Promise((resolve: () => void) => {
                            if (type === Style) {
                                resolve();
                            } else {
                                newScript.onload = () => resolve();
                            }
                        });
                    }
                }),
            Promise.resolve()
        );
        this.loadPromises.push(loadPromise);
        return Promise.all(this.loadPromises);
    }

    private loadScriptAndSuppressErrors(): Promise<void[]> {
        const { Style } = DependencyType;
        // reduce is used here for chaining promises so every resource awaits the resource before it
        const loadPromise: Promise<void> = this.assets.reduce(
            (promiseChain: Promise<void>, { url, type }: IAssetDependency) =>
                promiseChain.then<void>(() => {
                    const { scriptInDom, newScript } = this.createOrGetElementFromDOM(type, url);
                    if (scriptInDom) {
                        return Promise.resolve();
                    } else if (newScript) {
                        return new Promise((resolve: () => void) => {
                            if (type === Style) {
                                resolve();
                            } else {
                                newScript.onload = () => resolve();
                                const scriptTagErrorWrap: HTMLElement = document.createElement(DependencyType.Script);
                                (scriptTagErrorWrap as HTMLScriptElement).text =
                                    'window.onerror = function(msg, url, lineNo, columnNo, error) {return true;}';
                                document.body.appendChild(scriptTagErrorWrap);
                            }
                        });
                    }
                }),
            Promise.resolve()
        );
        this.loadPromises.push(loadPromise);
        return Promise.all(this.loadPromises);
    }

    public removeScript(url: string): void {
        const scriptInDom: ResourceElement | null = document.body.querySelector(
            `${DependencyType.Script}[${'src'}="${url}"]`
        );
        if (scriptInDom) document.body.removeChild(scriptInDom);
    }

    public addScripts(scripts: IAssetDependency[]): Promise<void[]> {
        this.assets.push(...scripts);
        return this.loadScript();
    }

    public addScriptsAndSuppressErrors(scripts: IAssetDependency[]): Promise<void[]> {
        this.assets.push(...scripts);
        return this.loadScriptAndSuppressErrors();
    }

    public then(func: () => void): Promise<void> {
        return Promise.all(this.loadPromises).then(func);
    }
}
