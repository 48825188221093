import { IAction, Redux } from '../redux.interface';

export enum BannerJourneyType {
    STOCK = 'stock',
    CONFIGURABLE = 'offers',
}

export interface IDestination {
    type: 'href' | 'link';
    to: string;
}

export interface IContentState {
    faq: IFAQ[];
    banner: IBannerItem[];
    footer: IFooterItem[];
    header: IHeaderItem[];
    translations: ITranslation;
    settings: ISetting[];
}

export interface IContentDuck {
    name: string;
    reducer(state: IContentState, action: IAction): void;
    getOwnState(state: any): IContentState;
    getContent(state: any): any;
    setContent(content: any, lang?: string, i18?: any): IAction;
    getFaq(state: any): IFAQ[];
    getBanner(state: Redux): IBannerItem[];
    getStockBanners(state: Redux): IBannerItem[];
    getConfigurableBanners(state: Redux): IBannerItem[];
    getFooter(state: any): IFooterItem[];
    getHeader(state: any): IHeaderItem[];
    getTranslations(state: any): ITranslation;
    loadContent(lang: string): any;
}

export interface IContentItem {
    id: number;
    position: number;
    createdAt: string;
}

export interface IFAQ extends IContentItem {
    title: string;
    content: string;
    items: IFAQItem[];
}

export interface IFooterItem extends IContentItem {
    level: string;
    label: ILabel;
}

export interface IHeaderItem extends IContentItem {
    level: string;
    label: ILabel;
}

export interface IBannerItem extends IContentItem {
    title: string;
    htmlText: string;
    redirectType: string;
    redirectUrl: string;
    extraSmallImage: string;
    smallImage: string;
    mediumImage: string;
    largeImage: string;
    status: string;
    validFrom: string;
    validTo: string;
    ctaLabel: string;
    journeyType: BannerJourneyType;
}
export interface IFAQItem {
    title: string;
    content: string;
    position: number;
}

export interface ILabel {
    destination: IDestination;
    language: string;
    type: string;
    title: string;
    href: string;
    file: any;
}

export interface ITranslation {
    [key: string]: string;
}

export interface ISetting {
    id: string;
    key: string;
    name: string;
    area: string;
    type: string;
    value: any;
    availableValues: any[];
}
