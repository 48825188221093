import { css, theme } from '../theme';
import { withPublicAssets, IpublicAssets } from '@utils/url.utils';

const getFonts = ({ publicAssets }: { publicAssets: IpublicAssets }) => css`
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSAutomobiles-Light.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontBase};
        font-weight: ${theme.fontWeights.textLight};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSAutomobiles-LightItalic.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontBase};
        font-weight: ${theme.fontWeights.textLight};
        font-style: italic;
    }
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSAutomobiles-Regular.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontBase};
        font-weight: ${theme.fontWeights.textRegular};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSAutomobiles-Bold.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontBase};
        font-weight: ${theme.fontWeights.textBold};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSAutomobiles-BoldItalic.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontBase};
        font-weight: ${theme.fontWeights.textBold};
        font-style: italic;
    }
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSTitleSmall-Light.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontEmphasized};
        font-weight: ${theme.fontWeights.textLight};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSTitleSmall-LightItalic.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontEmphasized};
        font-weight: ${theme.fontWeights.textLight};
        font-style: italic;
    }
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSTitleSmall-Regular.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontEmphasized};
        font-weight: ${theme.fontWeights.textRegular};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSTitleSmall-Italic.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontEmphasized};
        font-weight: ${theme.fontWeights.textRegular};
        font-style: italic;
    }
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSTitleSmall-Bold.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontEmphasized};
        font-weight: ${theme.fontWeights.textBold};
        font-style: normal;
    }
    @font-face {
        src: url(${publicAssets('/fonts/DS/DSTitleSmall-BoldItalic.ttf')}) format('truetype');
        font-family: ${theme.fonts.fontEmphasized};
        font-weight: ${theme.fontWeights.textBold};
        font-style: italic;
    }
`;

export default withPublicAssets(getFonts)();
