import React from 'react';
import { BRAND, isMarketGB } from '../../constants/main';
import { MaintenanceStyled } from './MaintenanceStyled';
import { MaintenanceStyledAC } from './AC/MaintenanceStyled.AC';
import { MaintenanceStyledOV } from './OV/MaintenanceStyled.OV';
import { MaintenanceStyledOV as MaintenanceStyledOVGB } from './OV/GB/MaintenanceStyled.OV';
import { MaintenanceStyledAP } from './AP/MaintenanceStyled.AP';
import { MaintenanceStyledDS } from './DS/MaintenanceStyled.DS';
import { SC } from '../../styles/theme';

export interface MaintenanceProps extends SC {

}

const Maintenance = (props: MaintenanceProps) => {
    if (BRAND === 'AC') return <MaintenanceStyledAC {...props} />;
    if (BRAND === 'OV') return isMarketGB ? <MaintenanceStyledOVGB {...props} /> : <MaintenanceStyledOV {...props} />;
    if (BRAND === 'AP') return <MaintenanceStyledAP {...props} />;
    if (BRAND === 'DS') return <MaintenanceStyledDS {...props} />;
    return <MaintenanceStyled {...props} />;
};

export default Maintenance;
