import { useFeatureSwitchEnabled } from '@hooks/useFeatureSwitchEnabled';
import { usePosthog } from '@hooks/usePosthog';
import { FEATURES_LIST } from 'src/context/featureSwitchApp';
import { useObjectUtils } from '@hooks/useObjectUtils';
import { BRAND, CONTEXT, LANGUAGE, MARKET } from 'src/constants/main';
import { FC } from 'react';

interface PosthogProps {
    sessionId: string | null;
}

const Posthog: FC<PosthogProps> = ({ sessionId }): null => {
    const { removeFalsyProperties } = useObjectUtils();

    usePosthog(
        sessionId,
        removeFalsyProperties({
            brand: BRAND,
            market: MARKET,
            locale: LANGUAGE,
            channel: CONTEXT,
        })
    );

    return null;
};

export default Posthog;
