import styled from 'styled-components';
import { MaintenanceStyled } from '../MaintenanceStyled';
import { rem } from 'polished';

export const MaintenanceStyledOV = styled(MaintenanceStyled)`
    .content {
        background-color: ${({ theme }) => theme.colors.white};
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 30vh;
    }
    
    .background {
        height: 70vh;
        width: 100%;
        background: url('/public/OV/ov_maintainance_bg.jpg') center center no-repeat;
        background-size: cover;
    }
    
    .content > .title {
        font-size: ${rem(34)};
        font-weight: ${({ theme }) => theme.fontWeights.textExtraBold};
        color: ${({ theme }) => theme.colors.black};
        margin: unset;
        text-align: center;
    }
    
    .content > .description {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        color: ${({ theme }) => theme.colors.black};
        line-height: 1.4;
        text-align: center;
    }
    
    .content > a {
        font-size: ${({ theme }) => theme.fontSizes.h5};
        background-color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.black};
        padding: ${rem(14)} ${rem(24)} ${rem(15)};
        height: ${rem(48)};
        text-decoration: none;
        text-transform: uppercase;
        
        &:hover {
            background-color: ${({ theme }) => theme.colors.black};
            border-color: ${({ theme }) => theme.colors.black};
            color: ${({ theme }) => theme.colors.white};
        }
    }
    
    @media only screen and (max-width: 580px) {
        .background {
            height: 60vh;
        }
        .content {
            height: 40vh;
        }
        .content > .description {
            max-width: 95%;
        }
    }
`;
