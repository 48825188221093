import { TBudgetType } from '../core/sdk/constants/sdkFields';
import { PriceRounding } from '../../types/priceRounding';
import { ITechnicalSpecifications } from '@utils/Characteristics.utils';

export interface IFeatureParameters {
    nameplateBodyStyle: string;
    speckPackId?: string;
    engineId?: string;
    filters?: IFilterWithType[];
    paymentJourneyType: TBudgetType;
    hash?: string;
}

export interface IFeaturesResponse {
    statusCode: number;
    items: ICarFeatures;
}

export interface IFilterWithType {
    title: string;
    type: string;
}

export interface IFuelAndGearboxFiltersTitle {
    fuelFilters: string[];
    gearboxFilters: string[];
}

export interface IFuelAndGearboxFilters {
    fuelFilters: IFilterWithType[];
    gearboxFilters: IFilterWithType[];
}

interface IOperatorTypes {
    IN: string;
    EQUALS: string;
    BETWEEN: string;
    NOTIN: string;
}

export const OperatorTypes: IOperatorTypes = {
    IN: 'IN',
    EQUALS: 'EQUALS',
    BETWEEN: 'BETWEEN',
    NOTIN: 'NOT IN',
};

export const FALSE: string = 'false';
export const TRUE: string = 'true';

export interface ILeadTime {
    leadTime: number;
}

export interface IOptionsFeature extends IFeature, ILeadTime {
    category: IExtraOptionCategory;
    prices: IPriceWithMonthlyPricesArray[];
}

export interface IPossibleOption {
    category: IExtraOptionCategory;
    description: string;
    id: string;
    images: IImage[];
    leadTime: number;
    prices: IPriceWithMonthlyPricesArray[];
    pricesV2: IPriceV2[];
    status: number;
    title: string;
}

export interface IExtraOptionCategory {
    category: string;
    order: number;
}

export const initialCarFeatures: ICarFeatures = {
    engine: [],
    exteriorColour: [],
    interiorColour: [],
    options: [],
};

// Features interfaces
export interface IFeature extends IDefaultFields {
    images: IImage[];
    prices: IPrice[];
    isAvailable?: boolean;
    pricesV2: IPriceV2[];
    extraFields: IFeatureExtraFields;
    includedOptionIds?: string[];
    exteriorColourSlug?: string;
    interiorColourSlug?: string;
    engineGearboxFuelSlug?: string;
    engineTitle?: string;
    gearboxTitle?: string;
    fuel?: string;
    characteristics?: {
        value: string;
        key: string;
    }[];
    externalId?: string;
    technicalSpecifications?: ITechnicalSpecifications[];
    imageURL?: string;
    type?: string;
    key?: string;
}

export interface IPriceExtras {
    employeeDiscount: string;
    salesDiscount: number;
    extraDiscount: string;
}

export interface IPriceBreakdown {
    deliveryInclTax: number;
    vehicleExciseDuty: number;
    registrationFee: number;
    finalCustomerPriceOnTheRoad: number;
    finalCustomerCashPriceOnTheRoad?: number;
}

export interface IPriceBaseMonthly {
    type: string;
    monthlyPrices?: IMonthlyPrice[];
}

export interface IPrice {
    currency: string;
    priceFormat: string;
    type: string;
    basePrice: number;
    margin: string;
    extras: IPriceExtras;
    bonus: string;
    malus: string;
    vat: string;
    monthlyPrices: IMonthlyPrice[];
    startDate?: string;
    endDate?: string;
    breakdown?: IPriceBreakdown;
    finalPriceInclTax?: number;
}

export enum IPriceV2Types {
    TOTAL_B2C_FINANCE = 'Total_B2C_Finance',
    TOTAL_B2C_CASH = 'Total_B2C_Cash',
    B2C_CASH = 'B2C_Cash',
    B2C_FINANCE = 'B2C_Finance',
    CATALOG_CASH = 'Catalog_Cash',
    TOTAL_CATALOG_CASH = 'Total_Catalog_Cash',
    CATALOG_FINANCE = 'Catalog_Finance',
    TOTAL_CATALOG_FINANCE = 'Total_Catalog_Finance',
}

export interface ITotalPrice {
    type: IPriceV2Types;
    totalLocalNetPriceInclTax: number;
    totalAccessoriesPriceInclTax: number;
    totalAmount: number;
}

export interface IPriceV2 {
    [x: string]: any;
    inputPriceInclTax: number;
    localNetPriceInclTax: number;
    basePriceExclTax: number;
    basePriceInclTax: number;
    basePriceTax: number;
    currency: string;
    discounts?: {
        key: string;
        amount: number;
        absoluteAmount?: number;
    }[];
    breakdown?: IPriceBreakdown;
    finalPriceExclTax: number;
    finalPriceInclTax: number;
    finalPriceTax: number;
    priceFormat: string;
    type: IPriceV2Types;
    vat: number;
}

export type IPriceWithMonthlyPricesArray = IPrice;

export interface IFeatureExtraFields {
    prices: IPrice[];
    pricesV2: IPriceV2[];
    specs: IFeatureSpecKey[];
}

export interface IImage {
    type: string;
    url: string;
}

export interface IFeatureSpec {
    value: string;
    label: string;
}

export interface IFeatureSpecKey extends IFeatureSpec {
    key: string;
}

export interface IMonthlyPrice {
    priceFormat?: string;
    amountRounding: PriceRounding;
    financeProductId: string;
    lengthOfTerm: number;
    deposit: number;
    apr?: number;
    amount: number;
    mileage?: number;
    depositAmount?: number;
    tin?: number;
    finalPayment?: number;
}

export interface IDefaultFields {
    _id: string;
    id: string;
    description: string;
    title: string;
    urls?: string[];
    label?: string;
    slug?: string;
}

export interface IWheels extends IDefaultFields {
    price: IPriceWithMonthlyPricesArray;
    images: IImage[];
}

export interface ICarFeatures {
    engine?: IFeature[];
    exteriorColour?: IFeature[];
    interiorColour?: IFeature[];
    options?: IOptionsFeature[];
}

export const PROMO_CODE_DISCOUNT_KEYS = [
    'promo_code',
    'promo_code_percentage_catalog',
    'promo_code_percentage_internet',
];
