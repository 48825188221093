import { IOfferItem, IOfferItemBase } from '../../interfaces/IOfferItem';
import UIDuck from '../commonDucks/ui.duck';
import { createActionTypes } from '../createActionTypes';
import { IAction, Redux, ThunkResult } from '../redux.interface';
import { IduckState, IOfferListDuck } from './offerlist.duck.interface';
import { transformStockOfferItem } from '@utils/Offers.utils';

const OffersListDuck: any = {
    name: 'OfferList',
};

const initialState: IduckState = {
    stock: {
        offers: [],
        filteredOffers: [],
        matchingVehiclesCount: null,
        totalVehiclesCount: null,
    },
    configurable: {
        offers: [],
        filteredOffers: [],
    },
};
const actionTypes: any = createActionTypes(
    {
        set_stock_offers: 'set_stock_offers',
        set_configurable_offers: 'set_configurable_offers',
        set_configurable_filtered_offers: 'set_configurable_filtered_offers',
        set_stock_filtered_offers: 'set_stock_filtered_offers',
    },
    OffersListDuck.name
);

OffersListDuck.reducer = (state: IduckState = initialState, action: IAction): IduckState => {
    switch (action.type) {
        case actionTypes.set_configurable_offers:
            return {
                ...state,
                configurable: { ...state.configurable, offers: [...action.payload] },
            };

        case actionTypes.set_configurable_filtered_offers:
            return {
                ...state,
                configurable: { ...state.configurable, filteredOffers: [...action.payload] },
            };

        case actionTypes.set_stock_offers:
            return {
                ...state,
                stock: {
                    ...state.stock,
                    offers: [...action.payload.offers],
                    totalVehiclesCount: action.payload.vehiclesCount,
                },
            };
        case actionTypes.set_stock_filtered_offers:
            return {
                ...state,
                stock: {
                    ...state.stock,
                    filteredOffers: [...action.payload.offers],
                    matchingVehiclesCount: action.payload.vehiclesCount,
                },
            };

        default:
            return state;
    }
};

OffersListDuck.setOffers = (offers: IOfferItem[] = []): IAction => ({
    type: actionTypes.set_configurable_offers,
    payload: offers,
});
OffersListDuck.setFilteredOffers = (offers: IOfferItem[] = []): IAction => ({
    type: actionTypes.set_configurable_filtered_offers,
    payload: offers,
});

OffersListDuck.setStockOffers = (offers: IOfferItemBase[], vehiclesCount: number): IAction => ({
    type: actionTypes.set_stock_offers,
    payload: { offers, vehiclesCount },
});

OffersListDuck.setStockFilteredOffers = (offers: IOfferItemBase[], vehiclesCount: number): IAction => ({
    type: actionTypes.set_stock_filtered_offers,
    payload: { offers, vehiclesCount },
});

const offerLoadingWeight: number = 5;
OffersListDuck.fetchOffers = (): ThunkResult<any> => () => {
    UIDuck.setLoading(offerLoadingWeight);
};

OffersListDuck.getOwnState = (state: any): IduckState => state[OffersListDuck.name] || initialState;
OffersListDuck.getFilteredOffers = (state: any): IOfferItem[] =>
    OffersListDuck.getOwnState(state).configurable.filteredOffers;
OffersListDuck.getFilteredOutOffers = (state: any): IOfferItem[] => {
    const offers = OffersListDuck.getOwnState(state).configurable.offers;
    const filteredOffers = OffersListDuck.getOwnState(state).configurable.filteredOffers;

    return offers.filter((offer: IOfferItem) => {
        return !filteredOffers.find(
            (filteredOffer: IOfferItem) => offer.nameplateBodyStyleSlug === filteredOffer.nameplateBodyStyleSlug
        );
    });
};
OffersListDuck.getStockOffers = (state: IduckState): IOfferItemBase[] => OffersListDuck.getOwnState(state).stock.offers;

OffersListDuck.getFilteredStockOffers = (state: IduckState): IOfferItemBase[] =>
    OffersListDuck.getOwnState(state).stock.filteredOffers;

OffersListDuck.getStockMatchingVehiclesCount = (state: Redux): number =>
    OffersListDuck.getOwnState(state).stock.matchingVehiclesCount;

OffersListDuck.getFilteredOutStockOffers = (state: IduckState): IOfferItemBase[] => {
    const offers = OffersListDuck.getOwnState(state).stock.offers.map((offer: IOfferItemBase) =>
        transformStockOfferItem(offer)
    );

    const filteredOffers = OffersListDuck.getFilteredStockOffers(state);

    return offers.filter((offer: IOfferItem) => {
        return !filteredOffers.find(
            (filteredOffer: IOfferItem) => offer.nameplateBodyStyleSlug === filteredOffer.nameplateBodyStyleSlug
        );
    });
};

const OfferListDuck: IOfferListDuck = OffersListDuck;
export default OfferListDuck;
