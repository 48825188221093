export const EXTRA_OPTIONS: string = 'extraOptions';
export const BASE_PRICE: string = 'basePrice';
export const BRAND: string = 'brand';
export const DETAILS_AGGREGATED: string = 'detailsAggregated';
export const DETAILS_OPTIONS_AGGREGATED: string = 'detailsOptionsAggregated';
export const ENGINE: string = 'engine';
export const EXTERIOR_COLOUR: string = 'exteriorColour';
export const EXTERIOR_COLOUR_SLUG: string = 'exteriorColourSlug';
export const EXTERNAL_ID: string = 'externalId';
export const FUEL: string = 'fuel';
export const GEARBOX: string = 'gearbox';
export const GEARBOX_TYPES: string = 'gearboxTypes';
export const ID: string = 'id';
export const IMAGES: string = 'images';
export const MARKET: string = 'market';
export const INTERIOR_COLOUR: string = 'interiorColour';
export const INTERIOR_COLOUR_SLUG: string = 'interiorColourSlug';
export const LCDV16: string = 'lcdv16';
export const LANGUAGE: string = 'language';
export const LEAD_TIME: string = 'leadTime';
export const OPTIONS: string = 'options';
export const OPTION_IDS: string = 'optionIds';
export const MODEL: string = 'model';
export const MONTHLY_PRICES: string = 'monthlyPrices';
export const NAMEPLATE: string = 'nameplate';
export const PRICES: string = 'prices';
export const SPECK_PACK: string = 'specPack';
export const SPECK_PACK_SLUG: string = 'specPackSlug';
export const ENGINE_GEARBOX_FUEL_SLUG: string = 'engineGearboxFuelSlug';
export const STOCK: string = 'stock';
export const TITLE: string = 'title';
export const SLUG: string = 'slug';
export const TYPE: string = 'type';
export const BODYSTYLE: string = 'bodyStyle';
export const ENERGY: string = 'energy';
export const ENERGIES: string = 'energies';
export const COLOUR: string = 'colour';
export const INTERIOR_TRIM: string = 'interior trim';
export const NAMEPLATEBODYSTYLE_SLUG: string = 'nameplateBodyStyleSlug';
export const TECHNICAL_SPECIFICATIONS: string = 'technicalSpecifications';
export const JOURNEY: string = 'journey';
export const ORDERS: string = 'orders';
export const OFFERS: string = 'offers';
export const DEFAULT_CONFIGURATION: string = 'defaultConfiguration';
export const DISCLAIMER: string = 'disclaimer';
export const IS_WITH_OPTIONS: string = 'isWithOptions';
export const IMPORT_INPUTS: string = 'importInputs';

export const AMOUNT: string = 'amount';

export const DESCRIPTION: string = 'description';
export const SPECS: string = 'specs';

export type SORT_TYPE = 'asc' | 'desc' | 'recommended';
export const SORT_ASC: SORT_TYPE = 'asc';
export const SORT_RECOMMENDED: SORT_TYPE = 'recommended';

export const PROMOTIONAL_TEXT: string = 'promotionalText';
export const REGULAR_PAYMENT_CONDITIONS: string = 'regularPaymentConditions';
export const NO_DEPOSIT: string = 'noDeposit';

export const NAMEPLATEBODYSTYLE: string = 'nameplateBodyStyle';
const GRBODYSTYLE = 'grBodyStyle';
const PRICES_V2 = 'pricesV2';

// EXTRA FIELDS (note that extra fields should not be nested in relevancyAggregations!)
const GRGEARBOX = 'grGearbox';
const IS_DEFAULT_CONFIGURATION = 'isDefaultConfiguration';
const SHOW_GR_BODY_STYLES = 'displayGrBodyStyleLabel';
const LEAD_TIME_DATE = 'leadTimeDate';
const LEAD_TIME_PRODUCTION_YEAR = 'leadTimeProductionYear';
const MAX_LEAD_TIME_DATE: string = 'maxLeadTimeDate';
const MAX_LEAD_TIME_PRODUCTION_YEAR = 'maxLeadTimeProductionYear';
const POSSIBLE_OPTIONS = 'possibleOptions';
const SERIES_EQUIPMENT = 'seriesEquipment';
const TARIFF_CODE = 'tariffCode';
const TARIFF_START_DATE = 'tariffStartDate';
const VEHICLE_USE_ID = 'vehicleUseId';
const VISUAL_CODE = 'visualCode';

// !!! This string is used on the nameplate api call
// It is added on the filter name, to be different than the name
// for the relevancy aggregation!!!
export const GLOBAL: string = 'global';

// NOTE: Please try to specify exactly the value that needs to be used.
export const CAR_DETAIL_FIELDS = [
    ID,
    `${BODYSTYLE}.${ID}`,
    `${BODYSTYLE}.${DESCRIPTION}`,
    `${ENGINE}.${ID}`,
    `${ENGINE}.${SPECS}`,
    `${ENGINE}.${TITLE}`,
    `${ENGINE_GEARBOX_FUEL_SLUG}`,
    `${EXTERIOR_COLOUR}.${ID}`,
    `${EXTERIOR_COLOUR}.${PRICES}`,
    `${EXTERIOR_COLOUR}.${PRICES_V2}`,
    `${EXTERIOR_COLOUR_SLUG}`,
    `${FUEL}`,
    `${GEARBOX}.${ID}`,
    `${GEARBOX}.${TITLE}`,
    `${GEARBOX}.${SPECS}`,
    `${IMAGES}`,
    `${INTERIOR_COLOUR}.${ID}`,
    `${INTERIOR_COLOUR}.${PRICES}`,
    `${INTERIOR_COLOUR}.${PRICES_V2}`,
    `${INTERIOR_COLOUR_SLUG}`,
    `${LANGUAGE}`,
    `${LCDV16}`,
    `${LEAD_TIME}`,
    `${MARKET}`,
    `${MODEL}.${ID}`,
    `${MODEL}.${TITLE}`,
    `${MODEL}.${DESCRIPTION}`,
    `${OFFERS}`,
    `${OPTIONS}`,
    `${PRICES}`,
    `${PROMOTIONAL_TEXT}`,
    `${SPECK_PACK}.${ID}`,
    `${SPECK_PACK}.${TITLE}`,

    `${MODEL}.${ID}`,
    `${MODEL}.${TITLE}`,
    `${NAMEPLATEBODYSTYLE_SLUG}`,
    `${EXTERIOR_COLOUR_SLUG}`,
    `${INTERIOR_COLOUR_SLUG}`,
    `${SPECK_PACK_SLUG}`,
    `${ENGINE_GEARBOX_FUEL_SLUG}`,
    OFFERS,

    // EXTRA FIELDS (note that extra fields should not be nested in relevancyAggregations!)
    `${GRBODYSTYLE}.${TITLE}`,
    `${GRBODYSTYLE}.${DESCRIPTION}`,
    `${GRGEARBOX}.${ID}`,
    `${GRGEARBOX}.${DESCRIPTION}`,
    IS_DEFAULT_CONFIGURATION,
    SHOW_GR_BODY_STYLES,
    LEAD_TIME_DATE,
    LEAD_TIME_PRODUCTION_YEAR,
    MAX_LEAD_TIME_DATE,
    MAX_LEAD_TIME_PRODUCTION_YEAR,
    NAMEPLATEBODYSTYLE,
    POSSIBLE_OPTIONS,
    PRICES_V2,
    SERIES_EQUIPMENT,
    TARIFF_CODE,
    TARIFF_START_DATE,
    VEHICLE_USE_ID,
    VISUAL_CODE,
    DEFAULT_CONFIGURATION,
    DISCLAIMER,
];

export const CAR_NAMEPLATE_FIELDS: string[] = [
    ID,
    MODEL,
    PRICES,
    IMAGES,
    BODYSTYLE,
    GRBODYSTYLE,
    PROMOTIONAL_TEXT,
    PRICES_V2,
    REGULAR_PAYMENT_CONDITIONS,
    NO_DEPOSIT,
    OFFERS,
    NAMEPLATE,
    NAMEPLATEBODYSTYLE,
    NAMEPLATEBODYSTYLE_SLUG,
    INTERIOR_COLOUR,
    EXTERIOR_COLOUR,
    ENGINE,
    GEARBOX,
    IS_DEFAULT_CONFIGURATION,
    DEFAULT_CONFIGURATION,
    SHOW_GR_BODY_STYLES,
    TITLE,
    DISCLAIMER,
    `${IMPORT_INPUTS}.offerValidityDate`,
];

export interface IStringObject {
    [key: string]: string;
}

export type TBudgetType = null | 'finance' | 'cash';

export const BUDGET_TYPE_CASH = 'cash';
export const BUDGET_TYPE_FINANCE = 'finance';
export const BUDGET_TYPE_RENT = 'rent';

// filter categories
export const BUDGET: string = 'budget';
export const TRANSMISSION: string = 'transmission';
export const AVAILABILITY: string = 'availability';
export const LUXURY_LEVEL: string = 'luxuryLevel';
export const TOTAL_RESULTS: string = 'totalResults';
export const FINANCE: TBudgetType = 'finance';
export const CASH: TBudgetType = 'cash';

export const TRANSLATION_FILTER_FIELDS: string[] = [TOTAL_RESULTS, BUDGET, EXTERIOR_COLOUR];
export const FIRST_ROW_FIELDS: string[] = [ENGINE, TRANSMISSION];

export const MULTIPLE_VALUE_FILTERS: string[] = [ENGINE, TRANSMISSION, EXTERIOR_COLOUR, LUXURY_LEVEL];
export const SINGLE_VALUE_FILTERS: string[] = [AVAILABILITY];

// sdk fields
export const NAMEPLATE_BODYSTYLE_SLUG: string = 'nameplateBodyStyleSlug';
export const PRICES_TYPE: string = 'prices.type';
export const CURRENCY: string = 'prices.currency';
export const FROM: string = 'from';
export const TO: string = 'to';
export const EXTERIOR_COLOUR_FIELD: string = 'exteriorColour.id';
export const LUXURY_LEVEL_FIELD: string = 'specPack.title';
export const TRANSMISSION_FIELD: string = 'gearbox.specs.label';
export const TRANSMISSION_EXTRA_NESTING: string = 'gearbox.specs.key';

export const FILTER_TYPE_NESTING: IStringObject = {
    [BUDGET]: BASE_PRICE,
    [FINANCE]: MONTHLY_PRICES,
    [ENGINE]: FUEL,
    [TRANSMISSION]: TRANSMISSION_FIELD,
    [AVAILABILITY]: STOCK,
    [EXTERIOR_COLOUR]: EXTERIOR_COLOUR_FIELD,
    [LUXURY_LEVEL]: LUXURY_LEVEL_FIELD,
};

export const FEATURE_LIST: string[] = [ENGINE, INTERIOR_COLOUR, EXTERIOR_COLOUR];

export const FEATURE_LIST_EXTENDED: string[] = [INTERIOR_COLOUR, EXTERIOR_COLOUR];
