const Sentry = require('@sentry/node');
const SentryIntegrations = require('@sentry/integrations');
const Cookie = require('js-cookie');

module.exports = () => {
    // disable in JEST
    if (process.env.JEST_WORKER_ID) {
        return { Sentry: {}, captureException: (...args) => null };
    }
    const BRAND = process.env.NEXT_PUBLIC_BRAND;
    const ENV = process.env.NEXT_PUBLIC_ENV;
    const SESSION_COOKIE_NAME = process.env.NEXT_PUBLIC_SESSION_COOKIE_NAME;

    const shouldInit = !Sentry.getCurrentHub() || !Sentry.getCurrentHub().getClient();
    if (shouldInit) {
        const sentryOptions = {
            dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
            debug: process.env.NODE_ENV !== 'production',
            serverName: `${BRAND}_${ENV}`,
            release: process.env.SENTRY_RELEASE,
            maxBreadcrumbs: 50,
            attachStacktrace: true,
            environment: `${BRAND}_${ENV}`,
        };

        // When we're developing locally
        if (process.env.NODE_ENV !== 'production') {
            // Don't actually send the errors to Sentry
            sentryOptions.beforeSend = () => null;

            // Instead, dump the errors to the console
            sentryOptions.integrations = [
                new SentryIntegrations.Debug({
                    // Trigger DevTools debugger instead of using console.log
                    debugger: false,
                }),
            ];
        }

        Sentry.init(sentryOptions);

        const handleProcessErrors = (err) => {
            Sentry.configureScope((scope) => {
                scope.setTag('brand', BRAND);
                scope.setTag('env', ENV);
                Sentry.captureException(err);
            });
        };
        // handle epic fails
        process.on('unhandledRejection', handleProcessErrors);
        process.on('uncaughtException', handleProcessErrors);
    }

    return {
        Sentry,
        captureException: (err, ctx) => {
            Sentry.configureScope((scope) => {
                scope.setTag('brand', BRAND);
                scope.setTag('env', ENV);
                if (err.message) {
                    // De-duplication currently doesn't work correctly for SSR / browser errors
                    // so we force deduplication by error message if it is present
                    scope.setFingerprint([err.message]);
                }

                if (err.statusCode) {
                    scope.setExtra('statusCode', err.statusCode);
                }

                if (ctx) {
                    const { req, res, errorInfo, query, pathname } = ctx;

                    if (res && res.statusCode) {
                        scope.setExtra('statusCode', res.statusCode);
                    }

                    if (typeof window !== 'undefined') {
                        scope.setTag('ssr', false);
                        scope.setExtra('query', query);
                        scope.setExtra('pathname', pathname);

                        // On client-side we use js-cookie package to fetch it
                        const sessionId = Cookie.get(SESSION_COOKIE_NAME);
                        if (sessionId) {
                            scope.setUser({ id: sessionId });
                        }
                    } else {
                        scope.setTag('ssr', true);
                        scope.setExtra('url', req.url);
                        scope.setExtra('method', req.method);
                        scope.setExtra('headers', req.headers);
                        scope.setExtra('params', req.params);
                        scope.setExtra('query', req.query);

                        // On server-side we take session cookie directly from request
                        if (req.cookies[SESSION_COOKIE_NAME]) {
                            scope.setUser({ id: req.cookies[SESSION_COOKIE_NAME] });
                        }
                    }

                    if (errorInfo) {
                        Object.keys(errorInfo).forEach((key) => scope.setExtra(key, errorInfo[key]));
                    }
                }
            });

            return Sentry.captureException(err);
        },
    };
};
