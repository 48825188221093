import { ICarTrimConfigurationsResponse, ICarTrimsResponse } from './trimList.types';

/**
 * Format response and return only required data
 * @param serverResponse
 */
export const formatCarTrimsResponse: (serverResponse: any) => ICarTrimsResponse = (serverResponse: any) => {
    const items = serverResponse._data._details
        .get('specPack.id')
        ['_items'].map(({ _items: { _items } }: any) => _items[0]);
    return {
        statusCode: serverResponse._statusCode,
        items,
    };
};

/**
 * Format response and return only required data
 * @param serverResponse
 */
export const formatCarTrimConfigurationsResponse: (serverResponse: any) => ICarTrimConfigurationsResponse = (
    serverResponse: any
) => {
    const items = serverResponse._data._details
        .get('detailsAggregated')
        ['_items'].map(({ _items: { _items } }: any) => _items[0]);

    return {
        statusCode: serverResponse._statusCode,
        items,
    };
};
