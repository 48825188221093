import { isVauxhall } from './../../constants/main';
import { createActionTypes } from '../createActionTypes';
import {
    IAction,
    NETWORK_STATUS_START,
    NETWORK_STATUS_ERROR,
    NETWORK_STATUS_DONE,
    IResponseError,
    IgetGlobalState,
    IGlobalState,
} from '../redux.interface';
import { IduckState, IUserDuck, MySavedDealJourneyType } from './user.duck.interface';
import { IUserAddress, IUser, IUserIsLoggedIn, IUserAuthResponse, EUserLoginType } from '../../interfaces/User';
import UserServices, { ACCESS_DENIED_ERROR_MESSAGE } from '../../services/user/user.service';
import AuthService from '../../services/authService/auth.service';
import { getSessionIdCookie } from '@utils/Session.utils';
import { Dispatch } from 'redux';
import { publicAssets } from '@utils/url.utils';
import { getBrand } from '../../constants';
import DealService from '../../services/deal/deal.service';
import CarDetailsDuck from '../carDetails/carDetails.duck';
import { createTimeoutPromise } from '@utils/timeoutPromise';
import { isBrowser } from '@utils/runtime';
import { batch } from 'react-redux';
import FinanceQuoteService from '../../services/financeQuote/financePrices.service';
import FilterDuck from '../filters/filter.duck';
import { CASH, FINANCE } from '../../services';

const UserDuck: any = {
    name: 'User',
};

const initialState: IduckState = {
    userAuth: {
        status: null,
        isLoggedIn: false,
        loginType: null,
        error: null,
        detail: null,
    },
    userDetails: undefined,
    userAddress: undefined,
    myAccountSummary: {
        status: null,
        error: null,
        myOrders: { total: null, img: null, grBodyStyle: {} as any, nameplate: {} as any },
        mySaves: { total: null, img: null, grBodyStyle: {} as any, nameplate: {} as any },
    },
    myOrders: {
        status: null,
        items: null,
        page: null,
        perPage: 10,
        error: null,
    },
    mySaves: {
        status: null,
        items: null,
        page: null,
        perPage: 10,
        error: null,
    },
    setActiveDeal: {
        status: null,
        id: null,
        error: null,
    },
    deleteDeal: {
        status: null,
        id: null,
        error: null,
    },
    isLoggingOut: false,
};

const actionTypes: any = createActionTypes(
    {
        SET_USER_DETAILS: 'SET_USER_DETAILS',
        SET_USER_ADDRESS: 'SET_USER_ADDRESS',
        SET_IS_LOGGING_OUT: 'SET_IS_LOGGING_OUT',
        ACTION_FETCH_MY_ACCOUNT_SUMMARY_START: 'ACTION_FETCH_MY_ACCOUNT_SUMMARY_START',
        ACTION_FETCH_MY_ACCOUNT_SUMMARY_FAILED: 'ACTION_FETCH_MY_ACCOUNT_SUMMARY_FAILED',
        ACTION_FETCH_MY_ACCOUNT_SUMMARY_DONE: 'ACTION_FETCH_MY_ACCOUNT_SUMMARY_DONE',
        ACTION_FETCH_MY_SAVES_START: 'ACTION_FETCH_MY_SAVES_START',
        ACTION_FETCH_MY_SAVE_START: 'ACTION_FETCH_MY_SAVE_START',
        ACTION_FETCH_MY_SAVES_FAILED: 'ACTION_FETCH_MY_SAVES_FAILED',
        ACTION_FETCH_MY_SAVES_DONE: 'ACTION_FETCH_MY_SAVES_DONE',
        ACTION_FETCH_MY_SAVE_DONE: 'ACTION_FETCH_MY_SAVE_DONE',
        ACTION_FETCH_MY_ORDERS_START: 'ACTION_FETCH_MY_ORDERS_START',
        ACTION_FETCH_MY_ORDERS_FAILED: 'ACTION_FETCH_MY_ORDERS_FAILED',
        ACTION_FETCH_MY_ORDERS_DONE: 'ACTION_FETCH_MY_ORDERS_DONE',
        ACTION_SET_ACTIVE_DEAL_START: 'ACTION_SET_ACTIVE_DEAL_START',
        ACTION_SET_ACTIVE_DEAL_FAILED: 'ACTION_SET_ACTIVE_DEAL_FAILED',
        ACTION_SET_ACTIVE_DEAL_DONE: 'ACTION_SET_ACTIVE_DEAL_DONE',
        ACTION_SET_USER_LOGGED_START: 'ACTION_SET_USER_LOGGED_START',
        ACTION_SET_USER_LOGGED_FAILED: 'ACTION_SET_USER_LOGGED_FAILED',
        ACTION_SET_USER_LOGGED_DONE: 'ACTION_SET_USER_LOGGED_DONE',
        ACTION_SET_ACTIVE_DEAL_CLEAR: 'ACTION_SET_ACTIVE_DEAL_CLEAR',
        ACTION_DELETE_DEAL_START: 'ACTION_DELETE_DEAL_START',
        ACTION_DELETE_DEAL_FAILED: 'ACTION_DELETE_DEAL_FAILED',
        ACTION_DELETE_DEAL_DONE: 'ACTION_DELETE_DEAL_DONE',
    },
    UserDuck.name
);

UserDuck.reducer = (state: IduckState = initialState, action: IAction): IduckState => {
    switch (action.type) {
        case actionTypes.SET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
            };
        case actionTypes.SET_IS_LOGGING_OUT:
            return {
                ...state,
                isLoggingOut: action.payload,
            };
        case actionTypes.SET_USER_ADDRESS:
            return {
                ...state,
                userAddress: action.payload,
            };
        /**************************************  REDUCER MY ACCOUNT SUMMARY ******************************************/
        case actionTypes.ACTION_FETCH_MY_ACCOUNT_SUMMARY_START:
            return {
                ...state,
                myAccountSummary: {
                    ...state.myAccountSummary,
                    status: NETWORK_STATUS_START,
                },
            };
        case actionTypes.ACTION_FETCH_MY_ACCOUNT_SUMMARY_DONE: {
            return {
                ...state,
                myAccountSummary: {
                    status: NETWORK_STATUS_DONE,
                    error: null,
                    myOrders: {
                        grBodyStyle: action.payload.orders?.latest_saved?.grBodyStyle,
                        nameplate: action.payload.orders?.latest_saved?.nameplate,
                        total: action.payload.orders.total,
                        img: action.payload.orders.latest_saved
                            ? action.payload.orders.latest_saved.image_url
                            : publicAssets(`/${getBrand()}/no-orders${isVauxhall ? '-vauxhall' : ''}.png`),
                    },
                    mySaves: {
                        grBodyStyle: action.payload.mySaves?.latest_saved?.grBodyStyle,
                        nameplate: action.payload.mySaves?.latest_saved?.nameplate,
                        total: action.payload.mySaves.total,
                        img: action.payload.mySaves.latest_saved
                            ? action.payload.mySaves.latest_saved.image_url
                            : publicAssets(`/${getBrand()}/no-orders${isVauxhall ? '-vauxhall' : ''}.png`),
                    },
                },
            };
        }
        case actionTypes.ACTION_FETCH_MY_ACCOUNT_SUMMARY_FAILED:
            return {
                ...state,
                myAccountSummary: {
                    ...state.myAccountSummary,
                    status: NETWORK_STATUS_ERROR,
                    error: action.payload.error,
                },
            };
        /**************************************  REDUCER MY ACCOUNT - MY SAVES ******************************************/
        case actionTypes.ACTION_FETCH_MY_SAVES_START:
            return {
                ...state,
                mySaves: {
                    ...state.mySaves,
                    status: NETWORK_STATUS_START,
                    page: action.payload.page,
                },
            };
        case actionTypes.ACTION_FETCH_MY_SAVE_START:
            return {
                ...state,
                mySaves: {
                    ...state.mySaves,
                    status: NETWORK_STATUS_START,
                },
            };
        case actionTypes.ACTION_FETCH_MY_SAVES_DONE:
            return {
                ...state,
                mySaves: {
                    ...state.mySaves,
                    status: NETWORK_STATUS_DONE,
                    items: action.payload.mySaves,
                    page: action.payload.page,
                },
            };
        case actionTypes.ACTION_FETCH_MY_SAVE_DONE:
            return {
                ...state,
                mySaves: {
                    ...state.mySaves,
                    status: NETWORK_STATUS_DONE,
                    items: action.payload.mySaves,
                    page: action.payload.page,
                },
            };
        case actionTypes.ACTION_FETCH_MY_SAVES_FAILED:
            return {
                ...state,
                mySaves: {
                    ...state.mySaves,
                    status: NETWORK_STATUS_ERROR,
                    error: action.payload.error,
                    page: action.payload.page,
                },
            };
        case actionTypes.ACTION_FETCH_MY_SAVE_FAILED:
            return {
                ...state,
                mySaves: {
                    ...state.mySaves,
                    status: NETWORK_STATUS_ERROR,
                    error: action.payload.error,
                },
            };
        /**************************************  REDUCER MY ACCOUNT - MY ORDERS ******************************************/
        case actionTypes.ACTION_FETCH_MY_ORDERS_START:
            return {
                ...state,
                myOrders: {
                    ...state.myOrders,
                    status: NETWORK_STATUS_START,
                    page: action.payload.page,
                },
            };
        case actionTypes.ACTION_FETCH_MY_ORDERS_DONE:
            return {
                ...state,
                myOrders: {
                    ...state.myOrders,
                    status: NETWORK_STATUS_DONE,
                    items: action.payload.myOrders,
                    page: action.payload.page,
                },
            };
        case actionTypes.ACTION_FETCH_MY_ORDERS_FAILED:
            return {
                ...state,
                myOrders: {
                    ...state.myOrders,
                    status: NETWORK_STATUS_ERROR,
                    error: action.payload.error,
                    page: action.payload.page,
                },
            };
        /**************************************  REDUCER SET ACTIVE DEAL ******************************************/
        case actionTypes.ACTION_SET_ACTIVE_DEAL_START:
            return {
                ...state,
                setActiveDeal: {
                    status: NETWORK_STATUS_START,
                    id: action.payload.dealId,
                    error: null,
                },
            };
        case actionTypes.ACTION_SET_ACTIVE_DEAL_FAILED:
            return {
                ...state,
                setActiveDeal: {
                    status: NETWORK_STATUS_ERROR,
                    id: action.payload.dealId,
                    error: action.payload.error,
                },
            };
        case actionTypes.ACTION_SET_ACTIVE_DEAL_DONE:
            return {
                ...state,
                setActiveDeal: {
                    status: NETWORK_STATUS_DONE,
                    id: action.payload.dealId,
                    error: null,
                },
            };
        case actionTypes.ACTION_SET_ACTIVE_DEAL_CLEAR:
            return {
                ...state,
                setActiveDeal: {
                    status: null,
                    id: null,
                    error: null,
                },
            };
        /**************************************  REDUCER DELETE DEAL ******************************************/
        case actionTypes.ACTION_DELETE_DEAL_START:
            return {
                ...state,
                deleteDeal: {
                    status: NETWORK_STATUS_START,
                    id: action.payload.dealId,
                    error: null,
                },
            };
        case actionTypes.ACTION_DELETE_DEAL_FAILED:
            return {
                ...state,
                deleteDeal: {
                    status: NETWORK_STATUS_ERROR,
                    id: action.payload.dealId,
                    error: action.payload.error,
                },
            };
        case actionTypes.ACTION_DELETE_DEAL_DONE:
            return {
                ...state,
                deleteDeal: {
                    status: NETWORK_STATUS_DONE,
                    id: action.payload.dealId,
                    error: null,
                },
            };
        /**************************************  REDUCER USER AUTH ******************************************/
        case actionTypes.ACTION_SET_USER_LOGGED_START:
            return {
                ...state,
                userAuth: {
                    status: NETWORK_STATUS_START,
                    isLoggedIn: null,
                    loginType: null,
                    error: null,
                },
            };
        case actionTypes.ACTION_SET_USER_LOGGED_FAILED:
            return {
                ...state,
                userAuth: {
                    status: NETWORK_STATUS_ERROR,
                    isLoggedIn: null,
                    loginType: null,
                    error: action.payload.error,
                },
            };
        case actionTypes.ACTION_SET_USER_LOGGED_DONE:
            return {
                ...state,
                userAuth: {
                    ...state.userAuth,
                    ...action.payload,
                    status: NETWORK_STATUS_DONE,
                    error: null,
                },
            };
        default:
            return state;
    }
};

UserDuck.setUserDetails = (userDetails: IUser): IAction => ({
    type: actionTypes.SET_USER_DETAILS,
    payload: userDetails,
});

UserDuck.setIsLoggingOut = (isLoggingOut: boolean): IAction => ({
    type: actionTypes.SET_IS_LOGGING_OUT,
    payload: isLoggingOut,
});

UserDuck.setUserAddress = (userAddress: IUserAddress): IAction => ({
    type: actionTypes.SET_USER_ADDRESS,
    payload: userAddress,
});

/**************************************  ACTIONS MY ACCOUNT SUMMARY ******************************************/

const actionFetchMyAccountSummaryStart = () => {
    return {
        type: actionTypes.ACTION_FETCH_MY_ACCOUNT_SUMMARY_START,
    };
};

const actionFetchMyAccountSummaryDone = (orders: any, mySaves: any) => {
    return {
        type: actionTypes.ACTION_FETCH_MY_ACCOUNT_SUMMARY_DONE,
        payload: { orders, mySaves },
    };
};

const actionFetchMyAccountSummaryFailed = (error: IResponseError) => {
    return {
        type: actionTypes.ACTION_FETCH_MY_ACCOUNT_SUMMARY_FAILED,
        payload: { error },
    };
};

UserDuck.actionFetchMyAccountSummary = () => {
    return async (dispatch: Dispatch, getState: IgetGlobalState) => {
        const reduxState: IGlobalState = getState();
        if (UserDuck.getOwnState(reduxState).myAccountSummary.status === NETWORK_STATUS_START) {
            return;
        }
        dispatch(actionFetchMyAccountSummaryStart());
        try {
            const ordersPromise = UserServices.fetchMyOrdersTotal(getSessionIdCookie());
            const mySavesPromise = UserServices.fetchMySavesTotal(getSessionIdCookie());

            const [ordersResult, mySavesResult] = [await ordersPromise, await mySavesPromise];

            if (
                ordersResult.response?.data?.message === ACCESS_DENIED_ERROR_MESSAGE ||
                mySavesResult.response?.data?.message === ACCESS_DENIED_ERROR_MESSAGE
            ) {
                dispatch(
                    actionFetchMyAccountSummaryFailed({
                        code: 403,
                        data: ordersResult.response?.data?.message || mySavesResult.response?.data?.message,
                        err: ordersResult || mySavesResult,
                    })
                );
            } else {
                dispatch(actionFetchMyAccountSummaryDone(ordersResult.data, mySavesResult.data));
            }
        } catch (err: any) {
            dispatch(
                actionFetchMyAccountSummaryFailed({
                    code: err?.response?.status,
                    data: err?.response?.data,
                    err,
                })
            );
        }
    };
};

/**************************************  ACTIONS MY ACCOUNT MY SAVES ******************************************/

const actionFetchMySavesStart = (page: number) => {
    return {
        type: actionTypes.ACTION_FETCH_MY_SAVES_START,
        payload: { page },
    };
};

const actionFetchMySaveStart = () => {
    return {
        type: actionTypes.ACTION_FETCH_MY_SAVE_START,
    };
};

const actionFetchMySavesDone = (mySaves: any, page: number, perPage: number) => {
    return {
        type: actionTypes.ACTION_FETCH_MY_SAVES_DONE,
        payload: { mySaves, page, perPage },
    };
};

const actionFetchMySaveDone = (mySaves: any) => {
    return {
        type: actionTypes.ACTION_FETCH_MY_SAVE_DONE,
        payload: { mySaves },
    };
};

const actionFetchMySavesFailed = (error: IResponseError, page: number) => {
    return {
        type: actionTypes.ACTION_FETCH_MY_SAVES_FAILED,
        payload: { error, page },
    };
};

const actionFetchMySaveFailed = (error: IResponseError) => {
    return {
        type: actionTypes.ACTION_FETCH_MY_SAVES_FAILED,
        payload: { error },
    };
};

UserDuck.actionFetchMySaves = (page: number, perPage: number = 10) => {
    return async (dispatch: Dispatch) => {
        dispatch(actionFetchMySavesStart(page));
        try {
            const { data } = await UserServices.fetchMySaves(getSessionIdCookie(), page, perPage);

            for (const deal of data) {
                if (deal?.children?.length > 0) {
                    for (const offer of deal.children) {
                        if (offer?.financeQuoteId) {
                            const financeQuote = await FinanceQuoteService.getFinanceQuoteById(
                                getSessionIdCookie(),
                                offer.financeQuoteId
                            );

                            offer.monthlyPayment = financeQuote.data.monthlyPayment;
                        }
                    }
                }
            }

            dispatch(actionFetchMySavesDone(data, page, perPage));
        } catch (err: any) {
            dispatch(
                actionFetchMySavesFailed(
                    {
                        code: err?.response?.status,
                        data: err?.response?.data,
                        err,
                    },
                    page
                )
            );
        }
    };
};

UserDuck.actionFetchMySave = (token: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(actionFetchMySaveStart());
        try {
            const { data } = await UserServices.fetchMySave(getSessionIdCookie(), token);

            if (data?.children?.length > 0) {
                for (const offer of data.children) {
                    if (offer?.financeQuoteId) {
                        const financeQuote = await FinanceQuoteService.getFinanceQuoteById(
                            getSessionIdCookie(),
                            offer.financeQuoteId
                        );

                        offer.monthlyPayment = financeQuote.data.monthlyPayment;
                    }
                }
            }

            dispatch(actionFetchMySaveDone([data]));
        } catch (err: any) {
            dispatch(
                actionFetchMySaveFailed({
                    code: err?.response?.status,
                    data: err?.response?.data,
                    err,
                })
            );
        }
    };
};
/**************************************  ACTIONS MY ACCOUNT MY ORDERS ******************************************/
const actionFetchMyOrdersStart = (page: number) => {
    return {
        type: actionTypes.ACTION_FETCH_MY_ORDERS_START,
        payload: { page },
    };
};

const actionFetchMyOrdersDone = (myOrders: any, page: number, perPage: number) => {
    return {
        type: actionTypes.ACTION_FETCH_MY_ORDERS_DONE,
        payload: { myOrders, page, perPage },
    };
};

const actionFetchMyOrdersFailed = (error: IResponseError, page: number) => {
    return {
        type: actionTypes.ACTION_FETCH_MY_ORDERS_FAILED,
        payload: { error, page },
    };
};

UserDuck.actionFetchMyOrders = (page: number, perPage: number = 10) => {
    return async (dispatch: Dispatch) => {
        dispatch(actionFetchMyOrdersStart(page));
        try {
            const myOrders = await UserServices.fetchMyOrders(getSessionIdCookie(), page, perPage);
            dispatch(actionFetchMyOrdersDone(myOrders.data, page, perPage));
        } catch (err: any) {
            dispatch(
                actionFetchMyOrdersFailed(
                    {
                        code: err?.response?.status,
                        data: err?.response?.data,
                        err,
                    },
                    page
                )
            );
        }
    };
};

/**************************************  ACTIONS SET ACTIVE DEAL ******************************************/
const actionSetActiveDealStart = (dealId: string) => {
    return {
        type: actionTypes.ACTION_SET_ACTIVE_DEAL_START,
        payload: { dealId },
    };
};

const actionSetActiveDealDone = (dealId: string) => {
    return {
        type: actionTypes.ACTION_SET_ACTIVE_DEAL_DONE,
        payload: { dealId },
    };
};

const actionSetActiveDealFailed = (error: IResponseError, dealId: string) => {
    return {
        type: actionTypes.ACTION_SET_ACTIVE_DEAL_FAILED,
        payload: { error, dealId },
    };
};

UserDuck.actionSetActiveDeal = (dealId: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(actionSetActiveDealStart(dealId));
        try {
            await DealService.activateDeal(dealId, getSessionIdCookie());
            await createTimeoutPromise(3000);
            const currentDeal = await DealService.currentDeal(getSessionIdCookie(), true);
            if (dealId === currentDeal.data.token) {
                batch(() => {
                    dispatch(CarDetailsDuck.setCurrentDeal(currentDeal.data));
                    dispatch(CarDetailsDuck.setCurrentCarDetails(currentDeal.data.fullProductConfiguration));
                    dispatch(
                        FilterDuck.changeBudgetType(
                            currentDeal?.data?.journeyType === MySavedDealJourneyType.BUY_ONLINE_CASH ? CASH : FINANCE
                        )
                    );
                    dispatch(actionSetActiveDealDone(dealId));
                });
                return currentDeal;
            } else {
                console.error('Unable activate deal ' + dealId);
                if (isBrowser()) {
                    window.location.reload();
                }
            }
        } catch (err: any) {
            dispatch(
                actionSetActiveDealFailed(
                    {
                        code: err?.response?.status,
                        data: err?.response?.data,
                        err,
                    },
                    dealId
                )
            );
        }
    };
};

UserDuck.actionSetActiveDealClear = () => {
    return {
        type: actionTypes.ACTION_SET_ACTIVE_DEAL_CLEAR,
    };
};

/**************************************  ACTIONS DELETE DEAL ******************************************/
const actionDeleteDealStart = (dealId: string) => {
    return {
        type: actionTypes.ACTION_DELETE_DEAL_START,
        payload: { dealId },
    };
};

const actionDeleteDealDone = (dealId: string) => {
    return {
        type: actionTypes.ACTION_DELETE_DEAL_DONE,
        payload: { dealId },
    };
};

const actionDeleteDealFailed = (error: IResponseError, dealId: string) => {
    return {
        type: actionTypes.ACTION_DELETE_DEAL_FAILED,
        payload: { error, dealId },
    };
};

UserDuck.actionDeleteMySavedDeal = (dealId: string) => {
    return async (dispatch: Dispatch, getState: IgetGlobalState) => {
        dispatch(actionDeleteDealStart(dealId));
        try {
            await DealService.deleteDeal(dealId, getSessionIdCookie());

            batch(() => {
                dispatch(UserDuck.actionFetchMySaves(UserDuck.getOwnState(getState()).mySaves.page));
                dispatch(UserDuck.actionFetchMyAccountSummary(UserDuck.getOwnState(getState())));
                dispatch(actionDeleteDealDone(dealId));
            });
        } catch (err: any) {
            dispatch(
                actionDeleteDealFailed(
                    {
                        code: err?.response?.status,
                        data: err?.response?.data,
                        err,
                    },
                    dealId
                )
            );
        }
    };
};

/**************************************  ACTIONS USER AUTH ******************************************/
const actionSetUserLoggedStart = () => {
    return {
        type: actionTypes.ACTION_SET_USER_LOGGED_START,
    };
};

const actionSetUserLoggedDone = (data: IUserAuthResponse) => {
    return {
        type: actionTypes.ACTION_SET_USER_LOGGED_DONE,
        payload: data,
    };
};

const actionSetUserLoggedFailed = (error: IResponseError) => {
    return {
        type: actionTypes.ACTION_SET_USER_LOGGED_FAILED,
        payload: { error },
    };
};

UserDuck.actionSetUserLoggedDone = (data: IUserAuthResponse) => {
    return {
        type: actionTypes.ACTION_SET_USER_LOGGED_DONE,
        payload: data,
    };
};

UserDuck.actionSetUserLogged = (isLoggedIn?: IUserIsLoggedIn, accessToken?: string) => {
    return async (dispatch: Dispatch) => {
        dispatch(actionSetUserLoggedStart());
        try {
            if (isLoggedIn !== undefined) {
                dispatch(
                    actionSetUserLoggedDone({
                        isLoggedIn,
                    })
                );
            } else {
                const isLoggedIdResponse = await AuthService.isLoggedIn(accessToken || getSessionIdCookie());
                dispatch(
                    actionSetUserLoggedDone({
                        isLoggedIn: isLoggedIdResponse.data.logged_in,
                        loginType: isLoggedIdResponse.data.login_type,
                        detail: isLoggedIdResponse.data.bo_user,
                    })
                );
            }
        } catch (err: any) {
            dispatch(
                actionSetUserLoggedFailed({
                    code: err?.response?.status,
                    data: err?.response?.data,
                    err,
                })
            );
        }
    };
};

UserDuck.getOwnState = (state: any): IduckState => state[UserDuck.name] || initialState;
UserDuck.isLoggedIn = (state: any): boolean => UserDuck.getOwnState(state).userAuth.isLoggedIn;
UserDuck.isAgentLogged = (state: any): boolean => {
    const userAuth = UserDuck.getOwnState(state).userAuth;

    return (
        userAuth.isLoggedIn &&
        (userAuth.loginType === EUserLoginType.DEALER || userAuth.loginType === EUserLoginType.ESELLER)
    );
};

export default UserDuck as IUserDuck;
