import React, { FC } from 'react';
import { IconProps, Icons } from '.';
import { BRAND, isBrandOV, isMarketGB } from '../../constants/main';
import { iconAC } from './AC';
import { iconAP } from './AP';
import { iconsDS } from './DS';
import { iconsOV } from './OV';
import { iconsOV as iconsOV_GB } from './OV/GB';
import { iconsShared } from './shared';

const getIcon = (name: Icons, altText: string, altId: string, role: string): string => {
    let icon: string;

    if (isBrandOV) icon = isMarketGB ? iconsOV_GB(name) : iconsOV(name);
    if (BRAND === 'AC') icon = iconAC(name);
    if (BRAND === 'AP') icon = iconAP(name);
    if (BRAND === 'DS') icon = iconsDS(name);
    if (!icon) icon = iconsShared(name);

    if (role) {
        icon = icon.replace('<svg', `<svg role=${role}`);
    }

    const hasTitle = () => {
        const positionOfReplacement = icon.indexOf('>');
        const textOfReplacement = `><title id=${altId}>${altText}</title>`;

        icon = icon.substring(0, positionOfReplacement) + textOfReplacement + icon.substring(positionOfReplacement + 1);
        icon = icon.replace('<svg', `<svg aria-labelledby=${altId}`);

        return icon;
    };

    const hasNotTitle = () => {
        return icon.replace('<svg', `<svg aria-hidden=${true}`);
    };

    return altText && altId ? hasTitle() : hasNotTitle();
};

export const IconTemplate: FC<IconProps> = ({ name, className, onClick, dataTestId, altText, altId, role, ref }) => {
    return (
        <span
            ref={ref}
            className={`${className} icon-wrapper`}
            dangerouslySetInnerHTML={{ __html: getIcon(name, altText, altId, role) }}
            onClick={onClick}
            data-testid={dataTestId}
        />
    );
};
