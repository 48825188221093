import { SESSION_REQUEST_HEADER_NAME } from '../../constants/main';
import coreApiService from '../core/coreApi.service';

class AuthService {
    static isRunning: boolean = false;

    public static isLoggedIn = (token: string): Promise<any> => {
        if (this.isRunning) {
            return new Promise(() => ({}));
        }
        this.isRunning = true;
        const headers = { [SESSION_REQUEST_HEADER_NAME]: token || '' };
        const res = coreApiService.get(`digital-api/api/customers/is-logged`, { showLoginType: 1 }, headers);
        this.isRunning = false;
        return res;
    };
}

export default AuthService;
