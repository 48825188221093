import { createInput } from '../core/sdk/baseInput';
import { EXTERNAL_ID, OPTION_IDS, JOURNEY } from '../core/sdk/constants/sdkFields';
import { carExtraOptionsProvider } from '../core/sdk/providers';
import { IOptionsParameters } from './optionsList.types';

export const getOptionsInput = ({ configurationId, optionIds, paymentJourneyType }: IOptionsParameters) => {
    const input: any = createInput(carExtraOptionsProvider);

    input.addExtra(EXTERNAL_ID, configurationId);
    input.addExtra(JOURNEY, paymentJourneyType);

    if (optionIds) {
        input.addExtra(OPTION_IDS, optionIds);
    }

    return input;
};
