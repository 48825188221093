/*
 * tadle husta fce mi vytvari unikatni akce ve tvaru napr. UI_DUCK/ADD_LOADING
 * je to pak prehlednejsi a muzu mit stejne pojmenovany akce ve vicero reducerech
 */

export const createActionTypes = (actionTypes: any = {}, duckName = 'DUCK'): any => {
    return Object.keys(actionTypes).reduce(
        (res, key) => ({
            ...res,
            [key]: `${duckName.toUpperCase()}_DUCK/${actionTypes[key].toUpperCase()}`,
        }),
        {}
    );
};
