import { IContentState, IBannerItem } from '../../redux/content/content.duck.interface';
import { DEFAULT_BANNER_JOURNEY_TYPE } from '../../constants/main';

const defaultJourneyType = DEFAULT_BANNER_JOURNEY_TYPE;

const fillMissingJourneyTypeToBanners = (banners?: IBannerItem[]) =>
    banners?.map(banner => ({ journeyType: defaultJourneyType, ...banner })) ?? [];

export const processContentData = (content: IContentState) => {
    const processedContent = { ...content };
    processedContent.banner = fillMissingJourneyTypeToBanners(processedContent.banner);

    return processedContent;
};
