import { IAction } from '../redux.interface';
import { IFeature, IMonthlyPrice, IPrice, IPriceV2 } from '../../services/features/featureList.types';
import { IPxValuation } from '../../partExchange/interfaces/PxWidget';
import { GearboxSpecKeys, ICarPart } from '../../interfaces/Car';

/**
 * Specifies which part was selected last on configurator to decide which slugs to take during fetch of new car details
 */
export enum LastPartSelected {
    FULL_CONFIGURATION = 'FULL_CONFIGURATION',
    MOTORIZATION = 'MOTORIZATION',
    EXTERIOR_COLOR = 'EXTERIOR_COLOR',
    INTERIOR_COLOR = 'INTERIOR_COLOR',
    OPTIONS = 'OPTIONS',
}

export interface IConfiguratorState {
    exteriorColors: IFeature[];
    interiorColors: IFeature[];
    motorizations: IFeature[];
    options: any[];
    exteriorColor: IFeature | null;
    interiorColor: IFeature | null;
    selectedOptions: string[];
    availableOptions: string[];
    motorization: IFeature | null;
    gearbox: ICarPart<GearboxSpecKeys> | null;
    externalId?: string;
    pxState: IPxValuation | undefined;
    energies: any[];
    lastPartSelected: LastPartSelected;
}

export interface IConfiguratorDuck {
    name: string;
    reducer(state: IConfiguratorState, action: IAction): void;
    getOwnState(state: any): IConfiguratorState;
    resetConfigurator(): IAction;
    setExteriorColor(color: IFeature): any;
    setInteriorColor(color: IFeature): any;
    setMotorization(motorization: IFeature): any;
    setMotorizationAndGearbox(motorization: IFeature, gearbox: ICarPart<GearboxSpecKeys>): any;
    setExteriorColors(colors: IFeature[]): any;
    setInteriorColors(colors: IFeature[]): any;
    setMotorizations(motorizations: IFeature[]): any;
    setOptions(options: any[]): any;
    setSelectedOptions(options: any[]): any;
    setExternalId(externalId: string): any;
    setPxState(pxState: IPxValuation | undefined): any;
}

export interface IConfiguratorColor {
    id: number;
    parentId: number[];
    name: string;
    color: string;
    price: number;
}

export interface IConfiguratorCarShortInfo {
    energy: string;
    gearbox: string;
    power: string;
    mileage: string;
    emmisions: string;
}

export interface IConfiguratorOption {
    id?: string;
    title: string;
    description: string;
    status?: number;
    price?: number;
    prices?: IPrice[];
    pricesV2?: IPriceV2;
    formattedPrice?: string;
    leadTime?: number;
    selected: boolean;
    type?: string;
    includedOptionIds?: string[];
    images: { type?: string; url: string }[];
    category?: { category: string; order: number };
    tires?: any;
    upsell?: boolean;
    trimUpsell?: boolean;
    upsellOrder?: number;
    upsellSelectedByCustomer?: boolean;
}

export interface IConfiguratorMotorisation {
    id: number;
    parentId: number[];
    title: string;
    gear: string;
    description: string;
    price: number;
}

export interface IFeatureNewMonthlyPrice {
    code: string;
    monthlyPrice: IMonthlyPrice;
}
