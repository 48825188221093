import { useSelector } from 'react-redux';
import GlobalDuck from 'src/redux/global/global.duck';
import { IGlobalState, Redux } from 'src/redux/redux.interface';
import { CarJourneyType } from '../services';

export const useGlobalDuck = () => {
    const promoCodeError = useSelector<IGlobalState, boolean>((state: Redux) => GlobalDuck.getPromoCodeError(state));
    const carteGriseError = useSelector<IGlobalState, boolean>((state: Redux) => GlobalDuck.getCarteGriseError(state));
    const carJourney = useSelector<IGlobalState, CarJourneyType>((state: Redux) => GlobalDuck.getCarJourney(state));
    const isWithScrappageDone = useSelector<IGlobalState, boolean>((state: Redux) =>
        GlobalDuck.getIsWithScrappageDone(state)
    );
    const isTracked = useSelector<IGlobalState, boolean>((state: Redux) => GlobalDuck.getIsTracked(state));

    return {
        promoCodeError,
        carteGriseError,
        carJourney,
        isWithScrappageDone,
        isTracked,
    };
};
