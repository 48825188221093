import coreApiService from '../core/coreApi.service';
import { BRAND, LANGUAGE, MARKET } from '../../constants/main';

const ENDPOINT_BASE: string = `spc-api/api/v1/${MARKET}/${LANGUAGE}/${BRAND}`;

class DisclaimerService {
    public static getDisclaimer: () => Promise<any> = () => {
        const headers = {};
        return coreApiService.get(`${ENDPOINT_BASE}/get-disclaimer`, {}, headers).then((response) => response?.data);
    };
}

export default DisclaimerService;
