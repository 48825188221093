// fe-app/src/input/carInput.ts
import { createInput } from '../core/sdk/baseInput';
import {
    JOURNEY,
    SPECK_PACK_SLUG,
    ENGINE,
    EXTERIOR_COLOUR,
    PRICES,
    BASE_PRICE,
    TYPE,
} from '../core/sdk/constants/sdkFields';
import { carDetailProvider } from '../core/sdk/providers';
import { IFilter } from '../carList/carList.types';
import { addFilters, addEmployeeTypeFilter, applyHasMonthlyPriceFilter, addWithoutOptionsFilter } from '../core/sdk/sdk.service';
import { ICarTrimsParameters } from './trimList.types';
import { CASH, ENGINE_GEARBOX_FUEL_SLUG } from '../filters/filters';
import { GEARBOX } from '../../constants/characteristics';

export const getCarTrimsInput = ({
    paymentJourneyType,
    nameplateBodyStyleSlug,
    filters,
    sort,
}: ICarTrimsParameters) => {
    const input = createInput(carDetailProvider)
        .addLevelAggregation('specPack.id', null, 'specPack.id')
        .addExtra(JOURNEY, paymentJourneyType);

    addWithoutOptionsFilter(input);

    if (paymentJourneyType === CASH) {
        input
            .addRelevancyAggregation(`${PRICES}.${BASE_PRICE}`, { size: 1, sort: sort }, 'specPack.id', ['*'])
            .addRelevancyAggregation(`${PRICES}.${BASE_PRICE}`, { size: 1, sort: sort }, 'specPack', ['*']);
        addEmployeeTypeFilter(input, `${PRICES}.${TYPE}nested`, 'specPack.id');
        addEmployeeTypeFilter(input, `${PRICES}.${TYPE}`);
    } else {
        input
            .addRelevancyAggregation('prices.monthlyPrices.amount', { size: 1, sort: 'asc' }, 'specPack.id', ['*'])
            .addRelevancyAggregation('prices.monthlyPrices.amount', { size: 1, sort: 'asc' }, 'specPack', ['*']);
        applyHasMonthlyPriceFilter(filters, paymentJourneyType);
    }

    const nameplateStyleFilter: IFilter = {
        name: 'nameplateBodyStyleSlug',
        operation: 'EQUALS',
        value: nameplateBodyStyleSlug,
        nesting: ['nameplateBodyStyleSlug'],
        parent: null,
    };
    addFilters([nameplateStyleFilter, ...filters], input);

    return input;
};

export const getCarTrimConfigurationsInput = ({
    paymentJourneyType,
    nameplateBodyStyleSlug,
    filters,
    sort,
}: ICarTrimsParameters) => {
    const input = createInput(carDetailProvider)
        .addLevelAggregation('detailsAggregated', null, 'detailsAggregated')
        .addExtra(JOURNEY, paymentJourneyType)
        .addRelevancyAggregation('prices.basePrice', { size: 1, sort: sort }, 'detailsAggregated', [
            EXTERIOR_COLOUR,
            ENGINE,
            SPECK_PACK_SLUG,
            ENGINE_GEARBOX_FUEL_SLUG,
            GEARBOX,
            PRICES,
        ]);
    addWithoutOptionsFilter(input);
    applyHasMonthlyPriceFilter(filters, paymentJourneyType);
    const nameplateStyleFilter: IFilter = {
        name: 'nameplateBodyStyleSlug',
        operation: 'EQUALS',
        value: nameplateBodyStyleSlug,
        nesting: ['nameplateBodyStyleSlug'],
        parent: null,
    };
    addFilters([nameplateStyleFilter, ...filters], input);
    addEmployeeTypeFilter(input);

    return input;
};
