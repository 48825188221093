import { STOCK } from './constants/sdkFields';
import { OperatorTypes } from '../../filters/filters';
import { IProvider } from './providers';
import { getSessionIdCookie } from '@utils/Session.utils';
import { getDefaultHeaders } from '../../apiLayer/apiLayer.utils';
import { config } from '../../../constants/main';

// TODO: Replace this for real ones
const carJourneyType = 'configurable';

export const createInput = (provider: IProvider) => {
    const input: any = provider.createInput(false);
    input.reset();
    input
        .setMarket(config.MARKET)
        .setLanguage(config.LANGUAGE)
        .addBrand(config.BRAND);

    if (process.browser) {
        const headers: Record<string, string> = {
            ...getDefaultHeaders(),
            'X-Auth-Token': getSessionIdCookie(),
        };
        input.setHeaders(headers);
    } else {
        const headers: Record<string, string> = {
            ...getDefaultHeaders(),
        };
        input.setHeaders(headers);
    }

    input.addFilter(STOCK, OperatorTypes.EQUALS, carJourneyType === STOCK ? 'true' : 'false');

    return input;
};
