const NextI18 = require('next-i18next').default;
const routePrefix = process.env.NEXT_PUBLIC_APP_PREFIX || '';
const pathPrefix = process.browser ? '.' + routePrefix : './assets';
const defaultLng = process.env.NEXT_PUBLIC_LANGUAGE;
const BRAND = process.env.NEXT_PUBLIC_BRAND || '';
const MARKET = process.env.NEXT_PUBLIC_MARKET ? process.env.NEXT_PUBLIC_MARKET.toUpperCase() : '';

const backend = {
    loadPath: ``,
};

const config = {
    fallbackLng: defaultLng,
    initImmediate: false,
    defaultNS: defaultLng,
    defaultLanguage: defaultLng,
    ns: [defaultLng],
    lng: defaultLng,
    otherLanguages: ['de', 'en'],
    browserLanguageDetection: false,
    serverLanguageDetection: false,
    localePath: pathPrefix + `/public/i18n/${BRAND}/${MARKET}`,
    backend: backend,
    saveMissing: false,
    nsSeparator: false,
    shallowRender: true,
    keySeparator: false,
    interpolation: {
        escapeValue: false,
        formatSeparator: ',',
        format: (value, format, lng) => {
            if (format === 'uppercase') return value.toUpperCase();
            return value;
        },
    },
    // debug: true
};

const NextI18Next = new NextI18(config);
module.exports = NextI18Next;
