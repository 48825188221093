import { css } from '../theme';
import fonts from './fonts';

const main = css`
    ${fonts}

    body {
        background-color: #f7f8f8;
        a,
        button {
            &:focus-visible {
                outline: 3px solid ${({ theme }) => theme.colors.focus} !important;
            }
        }

        .buorg {
            font-family: 'Citroen', 'Arial', 'sans-serif' !important;
        }

        .buorg::before {
            background-color: #999999;
        }

        .buorg-pad {
            font-size: 18px !important;
        }

        #buorgul,
        #buorgpermanent {
            background-color: #eb6428;
        }

        #buorgul:hover,
        #buorgpermanent:hover {
            background-color: #e1412d;
        }

        #buorgig {
            border: 1px solid #e1412d;
            background: #fff;
            color: #e1412d;
        }

        #buorgig:hover {
            border: 1px solid #eb6428;
            color: #eb6428;
        }
    }

    *[role='button'],
    *[role='slider'],
    a[href],
    button,
    input,
    .Select__control {
        &:not([tabindex='-1']) {
            &:focus-visible {
                outline: 3px solid ${({ theme }) => theme.colors.focus};
            }
        }
    }

    .sectionTitle {
        font-size: ${({ theme }) => theme.fontSizes.h2};
    }
`;

export default main;
